import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getAiCredits, getSelectedProducts, setSelectedProducts } from "../../../app/slices/app";
import { FormattedMessage } from "react-intl";
import { closeModal, isModalOpened, ModalTypes } from "../../../app/slices/modal";
import { LoadingButton } from "../../App/Blocks/Misc";
import Urls from "../../../assets/js/Urls";
import { GetCurrentFeedId } from "../../../app/utils";
import productsApi from "../../../app/services/secured/products";
import { AutoFixHigh, BatchPrediction } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const ModalBatchAi = () => {

   const dispatch = useAppDispatch();
   const selected = useAppSelector(getSelectedProducts);
   const feed = GetCurrentFeedId();
   const navigate = useNavigate();

   const show = useAppSelector(isModalOpened(ModalTypes.AiBatch));
   const hide = () => dispatch(closeModal());

   const nbToEnhance = selected.filter(s => !s.enhanced_by_ai).length;
   const nbCredits = useAppSelector(getAiCredits);
   const hasEnoughCredits = nbCredits === null || (nbCredits - nbToEnhance >= 0);

   const [batch, batchRes] = productsApi.useBatchAiMutation();

   useEffect(() => {
      if (batchRes.isSuccess) {
         dispatch(setSelectedProducts([]));
         hide();
      }
   }, [batchRes.isSuccess]);

   const handleAction = () => {
      if (!hasEnoughCredits) {
         navigate(Urls.settings.subEdit(feed));
      } else {
         batch({ feed, products: selected.map(s => s.id) });
      }
   };

   const isStep1 = batchRes.isUninitialized;

   return (
      <Dialog open={show} onClose={hide} maxWidth={"xs"} fullWidth>
         <DialogTitle>
            <FormattedMessage id={"ai-batching"} />
         </DialogTitle>
         {
            isStep1 ? (
               <>
                  <DialogContent>
                     <Typography gutterBottom>
                        <FormattedMessage
                           id="ai-batch-selection-count"
                           values={{ count: <b>{nbToEnhance}</b> }}
                        />
                     </Typography>

                     <Box sx={{
                        p: 2.5,
                        borderRadius: 2,
                        border: '1px solid',
                        borderColor: 'divider',
                        maxWidth: 280,
                        mx: 'auto',
                        my: 4
                     }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, alignItems: 'center' }}>
                           <Typography variant="body2" color="text.secondary">
                              <FormattedMessage id="available-credits" />
                           </Typography>
                           <Typography variant="h4" fontWeight={500}>
                              {nbCredits === null ? '∞' : nbCredits}
                           </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, alignItems: 'center' }}>
                           <Typography variant="body2" color="text.secondary">
                              <FormattedMessage id="required-credits" />
                           </Typography>
                           <Typography variant="h4" fontWeight={500} color="error.main">
                              -{nbToEnhance}
                           </Typography>
                        </Box>
                        <Box sx={{
                           display: 'flex',
                           justifyContent: 'space-between',
                           pt: 1,
                           borderTop: '1px solid',
                           borderColor: 'divider',
                           alignItems: 'center'
                        }}>
                           <Typography variant="body2" color="text.secondary">
                              <FormattedMessage id="remaining-credits" />
                           </Typography>
                           <Typography
                              variant="h4"
                              fontWeight={600}
                              color={hasEnoughCredits ? 'success.main' : 'error.main'}
                           >
                              {nbCredits === null ? '∞' : nbCredits - nbToEnhance}
                           </Typography>
                        </Box>
                     </Box>

                     {!hasEnoughCredits && (
                        <Typography
                           color="error.main"
                           variant="body2"
                           sx={{
                              textAlign: 'center',
                              mt: 2,
                              fontWeight: 500
                           }}
                        >
                           <FormattedMessage id="not-enough-credits-warning" />
                        </Typography>
                     )}
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={hide} sx={{ color: 'text.secondary' }}>
                        <FormattedMessage id={"cancel"} />
                     </Button>
                     <LoadingButton
                        variant="contained"
                        disabled={nbToEnhance === 0}
                        onClick={handleAction}
                        data={batchRes}
                        startIcon={<AutoFixHigh />}
                     >
                        <FormattedMessage id={hasEnoughCredits ? "ai-batch" : "go-to-subscription"} />
                     </LoadingButton>
                  </DialogActions>
               </>
            ) : (
               <>
                  <DialogContent>
                     <Typography>
                        <FormattedMessage id={"ai-batch-in-progress"} />
                     </Typography>
                  </DialogContent>
                  <DialogActions>
                     <Button onClick={hide}>
                        <FormattedMessage id={"close"} />
                     </Button>
                  </DialogActions>
               </>
            )
         }

      </Dialog>
   )
}

export default ModalBatchAi;
import { SearchCriteria } from "../../../types";

import { ChannelsIds, FullReportingRow, ReportingRow } from "./feed";
import { AdsProvider } from "./campaign";

/** Types */
export type Product = {
  id: number,
  product_id: string,

  data: ProductData,
  custom_data: Partial<ProductData> | null,
  ai_data: Partial<ProductData> | null,

  enhanced_by_ai: boolean,

  status_google: AllProductStatus,
  status_microsoft: AllProductStatus,
  status_meta: AllProductStatus,
  free_listing_status: AllProductStatus,

  price_report: null | PriceReport;

  //@deprecated
  warnings: string[],

  product_errors: ProductErrors[],
}

export type PriceReport = {
  benchmark_price: number,
  benchmark_last_check: number,
}

export type ProductData = {
  id_k: string,
  price: string, // "2.90 EUR"
  title: string,
  link: string,
  image_link: string,
  identifier_exists: "yes" | "no" | "",
  google_product_category_k: number,
  gender_k: PGender,
  description: string,
  condition_k: PCondition,
  color_k: string,
  brand_k: string,
  availability_k: PAvailability,
  age_group_k: PAge,
  shipping: string,
  gtin?: string,
  mpn?: string,
  availability_date_k?: string,
  sale_price?: string,
  sale_price_effective_date?: string,
  size_k?: string,
  product_weight?: string,
  shipping_weight?: string,
  material_k?: string,
  excluded_destinations?: string,
  adult_k?: boolean,
  is_bundle?: boolean,

  title_ai?: string;
  description_ai?: string;
}

export type PGender = "male" | "female" | "unisex";
export const PGenderObject: PGender[] = ["male", "female", "unisex"];
export type PAvailability = "in_stock" | "out_of_stock" | "backorder";
export const PAvailabilityObject = ["in_stock", "out_of_stock", "backorder"]
export type PAge = "newborn" | "infant" | "toddler" | "kids" | "adult";
export const PAgeObject: PAge[] = ["newborn", "infant", "toddler", "kids", "adult"];
export type PCondition = "new" | "refurbished" | "used";
export const PConditionObject = ["new", "refurbished", "used"]
export type PSize = "XXS" | "XS" | "S" | "M" | "L" | "XL" | "2XL" | "3XL" | "4XL" | "5XL" | "6XL";
export const PSizeObject: PSize[] = ["XXS", "XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"];

export enum ProductStatus {
  FLAG_PENDING,
  FLAG_WARNING = 1,
  FLAG_ERROR = 2,
  FLAG_ONLINE = 4,
  FLAG_PENDING_CORRECTION = 8,
}

export type AllProductStatus =
  ProductStatus.FLAG_PENDING |
  ProductStatus.FLAG_WARNING |
  ProductStatus.FLAG_ERROR |
  ProductStatus.FLAG_ONLINE |
  ProductStatus.FLAG_PENDING_CORRECTION |
  (ProductStatus.FLAG_WARNING | ProductStatus.FLAG_ERROR) |
  (ProductStatus.FLAG_ONLINE | ProductStatus.FLAG_WARNING) |
  (ProductStatus.FLAG_PENDING_CORRECTION | ProductStatus.FLAG_WARNING) |
  (ProductStatus.FLAG_PENDING_CORRECTION | ProductStatus.FLAG_ERROR) |
  (ProductStatus.FLAG_PENDING_CORRECTION | ProductStatus.FLAG_ONLINE) |
  (ProductStatus.FLAG_PENDING_CORRECTION | ProductStatus.FLAG_WARNING | ProductStatus.FLAG_ERROR) |
  (ProductStatus.FLAG_PENDING_CORRECTION | ProductStatus.FLAG_ONLINE | ProductStatus.FLAG_WARNING)
  ;

export type ProductParams = {
  feed: number,
} & Partial<Pick<SearchCriteria, 'q' | 'page' | 'page_per' | 'status' | 'error_code'>>


export type CategoryMap = {
  category: null | {
    id: number,
  },
  id: number,
  is_auto: boolean,
  original_value: string,
}

export type ProductReporting = {
  errors: ProductErrors[],
  platforms: ProductReportingPlatforms | null,
}

export type ProductProvidersAvailable = Extract<AdsProvider, "go" | "bg" | "fb" | "fl">;

export type ProductReportingPlatforms = Partial<Record<ProductProvidersAvailable, ProductReportingChannel>>;

export type ProductReportingChannel = {
  current: CurrentCatalogState,
  history: ProductHistory[],
}

export type CurrentCatalogState = {
  errors: number,
  success: number,
  total: number,
  warnings: number,
  pendings: number,
  last_import: number,
}

export type ProductHistory = {
  date?: string,
  imported: number,
  errors: number,
  warnings: number,
  pendings: number,
}

export type ProductErrors = {
  source: "Bing" | "Google",
  source_id: ChannelsIds,
  errors: ProductError[] | ProductErrorEnhanced[]
}

export type GoogleAttribute =
  "age group"
  | "availability"
  | "availability date"
  | "brand"
  | "color"
  | "gender"
  | "google product category"
  | "gtin"
  | "identifier exists"
  | "image link"
  | "link"
  | "mpn"
  | "sale price"
  | "price"
  | "shipping"
  | "shipping weight"
  | "size"
  | "title"
  | "NO_CODE"
  | "description"
  ;

export type GoogleCode =
  "missing_item_attribute_for_product_type"
  | "item_missing_required_attribute"
  | "missing_related_attribute"
  | "apparel_missing_brand"
  | "dataquality_invalid_attribute_value"
  | "item_group_by_gtin_with_conflicting_attributes"
  | "google_category_unrecognized"
  | "coupon_gtin"
  | "incorrect_identifier"
  | "invalid_upc"
  | "item_duplicate_by_gtin"
  | "media_missing_gtin"
  | "mismatching_brand_gtin_prefix"
  | "missing_required_gtin"
  | "reserved_gtin"
  | "strong_id_inaccurate"
  | "identifier_exists_false_misuse"
  | "image_decoding_error"
  | "image_link_broken"
  | "image_link_internal_error"
  | "image_link_pending_crawl"
  | "image_single_color"
  | "image_too_small"
  | "image_unwanted_overlays"
  | "landing_page_crawling_not_allowed"
  | "landing_page_error"
  | "mobile_landing_page_crawling_not_allowed"
  | "mobile_landing_page_error"
  | "url_does_not_match_homepage"
  | "account_disapproval"
  | "css_merchant_opt_out"
  | "hard_goods_missing_2_out_of_3_identifiers"
  | "pending_initial_policy_review_shopping_ads"
  | "policy_enforcement_account_disapproval"
  | "policy_violation"
  | "preemptive_item_disapproval_price_untrusted"
  | "recalled_product"
  | "price_mismatch"
  | "price_out_of_range"
  | "missing_shipping"
  | "missing_shipping_weight"
  | "shipping_weight_too_high"
  | "non_product_data"
  | "image_link_slow_crawl"
  | "image_too_generic"
  | "image_link_crawling_not_allowed"
  | "pending_address_and_phone_details"
  | "homepage_not_claimed"
  ;

export type ProductError = {
  id: number,
  attribute: GoogleAttribute | null,
  code: GoogleCode | null,
  description: string,
  is_blocking: boolean,
  nb?: number,
}

export type ProductErrorEnhanced = ProductError & Partial<{
  field: string | GoogleCode | null,
  help: null | { EN: string, FR: string },
  header: string,
  body: string,
}>
export type PReporting = FullReportingRow & {
  image: string | null,
  title: string | null,
  id: number | null,
  price: number | null,
  currency: string | null,
};
export type ProductReportingList = Array<PReporting>;

export type ProductReportingBase = ReportingRow & { image: string, title: string };

import React from "react";
import { ListSubheader } from "@mui/material";
import dayjs from "dayjs";
import calendar from 'dayjs/plugin/calendar';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/fr';
import { AppNotification, AppNotificationType, LogType } from "../../../../app/slices/app_notifications";
import { useAppSelector } from "../../../../app/hooks";
import { getLanguage } from "../../../../app/slices/app"; // Import the French locale
import { Notification } from "./components/Notification";

dayjs.extend(calendar);
dayjs.extend(updateLocale);

type NotificationPerDate = Record<string, AppNotification[]>;

export const showInBadgeMapping: Record<LogType, boolean> = {
  [LogType.LOGIN]: false,
  [LogType.SUBSCRIPTION_CREATION]: true,
  [LogType.SUBSCRIPTION_UPDATE]: true,
  [LogType.SUBSCRIPTION_CANCEL]: true,
  [LogType.FEED_UPDATE]: false,
  [LogType.CAMPAIGN_CREATE]: false,
  [LogType.CAMPAIGN_UPDATE]: false,
  [LogType.FEED_SYNC_END]: false,
  [LogType.FEED_IMPORT_END]: true,
  [LogType.FEED_IMPORT_DROP]: true,
  [LogType.ACCOUNT_PAYMENT_MISSING]: true,
  [LogType.NO_ACTIVE_CAMPAIGNS]: true,
  [LogType.LIMITED_CAMPAIGNS]: true,
  [LogType.FEED_DISABLE_PLATFORM]: true,
  [LogType.FEED_ENABLE_PLATFORM]: true,
  [LogType.FEED_MISSING_URL]: true,
  [LogType.FEED_DOWNLOAD_ERROR]: true,
  [LogType.MERCHANT_CENTER]: true,
  [LogType.CAMPAIGN_NO_CONVERSIONS]: true,
  [LogType.CAMPAIGN_LOW_ROI]: true,
  [LogType.NO_TRACKING]: true,
  [LogType.PLATFORM_RESET]: true,
  [LogType.AI_BATCH]: true,
};

export const linkedToFeed: Record<LogType, boolean> = {
  [LogType.LOGIN]: false,
  [LogType.SUBSCRIPTION_CREATION]: true,
  [LogType.SUBSCRIPTION_UPDATE]: true,
  [LogType.FEED_UPDATE]: true,
  [LogType.SUBSCRIPTION_CANCEL]: true,
  [LogType.CAMPAIGN_CREATE]: true,
  [LogType.CAMPAIGN_UPDATE]: true,
  [LogType.FEED_SYNC_END]: true,
  [LogType.FEED_IMPORT_END]: true,
  [LogType.FEED_IMPORT_DROP]: true,
  [LogType.ACCOUNT_PAYMENT_MISSING]: false,
  [LogType.NO_ACTIVE_CAMPAIGNS]: false,
  [LogType.LIMITED_CAMPAIGNS]: true,
  [LogType.FEED_DISABLE_PLATFORM]: true,
  [LogType.FEED_ENABLE_PLATFORM]: true,
  [LogType.FEED_MISSING_URL]: true,
  [LogType.FEED_DOWNLOAD_ERROR]: true,
  [LogType.MERCHANT_CENTER]: false,
  [LogType.CAMPAIGN_NO_CONVERSIONS]: true,
  [LogType.CAMPAIGN_LOW_ROI]: true,
  [LogType.NO_TRACKING]: true,
  [LogType.PLATFORM_RESET]: true,
  [LogType.AI_BATCH]: true,
}

export const realtimeTrackedLogs: LogType[] = [
  LogType.FEED_IMPORT_END,
  LogType.FEED_SYNC_END,
  LogType.FEED_IMPORT_DROP,
  LogType.ACCOUNT_PAYMENT_MISSING,
  LogType.NO_ACTIVE_CAMPAIGNS,
  LogType.LIMITED_CAMPAIGNS,
  LogType.FEED_DOWNLOAD_ERROR,
  LogType.FEED_MISSING_URL,
  LogType.MERCHANT_CENTER,
  LogType.CAMPAIGN_NO_CONVERSIONS,
  LogType.CAMPAIGN_LOW_ROI,
  LogType.NO_TRACKING,
  LogType.AI_BATCH,
];

// TODO - Works only for current tab because IDs comes from EventStream
export const filterUniqueNotificationsType = (n: AppNotification[]) => n.filter((value, index, array) => {
  return array.findIndex(n => n.type !== LogType.MERCHANT_CENTER && n.type === value.type && n.id > value.id) >= 0;
});

export function regroupNotificationsByDate(notifications: AppNotification[]): NotificationPerDate {
  const groupedByDate: Record<string, AppNotification[]> = {};
  notifications.forEach(notification => {
    const date = new Date(notification.date * 1000).toDateString();
    if (!groupedByDate[date]) {
      groupedByDate[date] = [];
    }
    groupedByDate[date].push(notification);
  });

  return groupedByDate;
}

const filterNotifications = (notifications: NotificationPerDate): NotificationPerDate => {
  const filtered: NotificationPerDate = {};

  Object.entries(notifications).forEach(([date, items]) => {
    const typeMap: Map<number, AppNotification[]> = new Map();

    items.forEach(item => {
      if (item.type === LogType.MERCHANT_CENTER) {
        if (!typeMap.has(item.type)) {
          typeMap.set(item.type, []);
        }
        typeMap.get(item.type)?.push(item);
      } else {
        // For other types, keep the most recent item
        const existingItems = typeMap.get(item.type) || [];
        if (existingItems.length === 0 || existingItems[0].date < item.date) {
          typeMap.set(item.type, [item]);
        }
      }
    });

    // Flatten the array of arrays for each type into a single array of notifications
    filtered[date] = Array.from(typeMap.values()).flat();
  });

  return filtered;
};


export const useRenderNotifications = (notifications: AppNotification[]): JSX.Element[] => {
  const locale = useAppSelector(getLanguage);
  const record = regroupNotificationsByDate(notifications);
  const filteredRecord = filterNotifications(record);
  dayjs.locale(locale);

  dayjs.updateLocale('fr', {
    calendar: {
      sameDay: '[Aujourd\'hui]',
      nextDay: '[Demain]',
      nextWeek: 'dddd',
      lastDay: '[Hier]',
      lastWeek: function () {
        //@ts-ignore
        return this.format('dddd').charAt(0).toUpperCase() + this.format('dddd').slice(1) + ' dernier';
      },
      sameElse: 'DD/MM/YYYY'
    }
  });

  return (
    Object.keys(filteredRecord).map(date => (
      <React.Fragment>
        <ListSubheader>
          {dayjs(date).calendar()}
        </ListSubheader>
        {filteredRecord[date].map((r, i) => (
          <Notification key={`history-${date}-${i}`} {...r} />
        ))}
      </React.Fragment>
    ))
  )
}

export const getSeverityByType = (type: AppNotificationType) => {
  switch (type) {
    case LogType.FEED_IMPORT_END:
    case LogType.FEED_SYNC_END:
    case LogType.AI_BATCH:
      return "info";
    case LogType.FEED_IMPORT_DROP:
    case LogType.FEED_DOWNLOAD_ERROR:
    case LogType.MERCHANT_CENTER:
    case LogType.ACCOUNT_PAYMENT_MISSING:
    case LogType.NO_TRACKING:
      return "error";
    case LogType.NO_ACTIVE_CAMPAIGNS:
    case LogType.LIMITED_CAMPAIGNS:
    case LogType.FEED_MISSING_URL:
    case LogType.CAMPAIGN_NO_CONVERSIONS:
    case LogType.CAMPAIGN_LOW_ROI:
      return "warning";
    default:
      return "info";
  }
};

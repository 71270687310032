import { AutoFixHigh, CloudOff, Edit, LabelImportant, Launch, Preview } from '@mui/icons-material';
import { Box, ButtonGroup, Chip, IconButton, List, ListItem, ListItemText, Stack, Tooltip, Typography } from '@mui/material';
import { GridColDef, GridPaginationModel, GridToolbarContainer } from '@mui/x-data-grid';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import useLabels from '../../../app/hooks/useLabels';
import useProductUtils from '../../../app/hooks/useProductUtils';
import { getAppMode } from '../../../app/slices/app';
import { modalShowLabel } from '../../../app/slices/modal';
import { setProductPreview } from '../../../app/slices/product';
import { getProductsParams, PRODUCTS_PER_PAGE, setProductsParams } from '../../../app/slices/products';
import { DynamicLabel } from '../../../app/types/feed';
import { Product } from '../../../app/types/products';
import { FeedDataFeedcast, GetCurrentFeedId } from '../../../app/utils';
import Urls from '../../../assets/js/Urls';
import { MakeCategoryName } from '../../App/Blocks/Misc';
import { HelpLink } from '../../App/Helpdesk/HelpLink';
import { ProductSearchParameters } from './_ProductList';
import ProductListFilters from './Filters/ProductListFilters';
import { ProductListToolbar } from './Filters/ProductListToolbar';
import NoProductsList from './NoProductsList';
import ModalBatchAi from './ModalBatchAi';

type ProductsTableProps = {
  found: number,
  products: Product[],
  loading: boolean,
  serverPage: number,
  displayHelper: boolean,
  setSearch: (s: ProductSearchParameters) => void,
  hasProducts: boolean,
  hasParams: boolean,
}


type ProductTableCellProps = {
  title: string;
  productId: string;
  labels: {
    productLabels: Array<DynamicLabel>
  };
  enhancedByAi: boolean;
};

export const ProductTableCell = ({ title, productId, labels, enhancedByAi }: ProductTableCellProps) => {
  const dispatch = useAppDispatch();
  const labelCount = labels?.productLabels?.length || 0;

  return (
    <Stack spacing={0.5} sx={{ width: '100%', p: 1 }}>
      <Typography variant="body2" noWrap>
        {title}
      </Typography>

      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        {labelCount > 0 && (
          <Tooltip
            arrow
            placement="right"
            title={
              <List
                disablePadding
                sx={{
                  minWidth: 100
                }}
              >
                {labels.productLabels.map((label) => (
                  <ListItem
                    key={`l-${label.uuid}`}
                    secondaryAction={
                      <IconButton
                        onClick={() => dispatch(modalShowLabel({ label }))}
                        edge="end"
                      >
                        <Edit fontSize="small" sx={{ color: "primary.contrastText" }} />
                      </IconButton>
                    }
                  >
                    <ListItemText>{label.label_value}</ListItemText>
                  </ListItem>
                ))}
              </List>
            }
          >
            <Chip
              size="small"
              icon={<LabelImportant fontSize="small" />}
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <span>Labels</span>
                  <Box
                    sx={{
                      color: 'primary.contrastText',
                      backgroundColor: 'secondary.dark',
                      borderRadius: '50%',
                      minWidth: '17px',
                      height: '17px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '0.65rem',
                    }}
                  >
                    {labelCount}
                  </Box>
                </Box>
              }
              sx={{
                padding: 0.5,
                paddingRight: 0,
                backgroundColor: 'secondary.main',
              }}
            />
          </Tooltip>
        )
        }
        {
          enhancedByAi && (
            <Chip
              size="small"
              icon={<AutoFixHigh sx={{ fontSize: '1rem' }} />}
              label={<FormattedMessage id="ai-enhanced" />}
              sx={{
                padding: 0.5,
                borderRadius: '12px',
                backgroundColor: 'primary.main',
                color: 'primary.contrastText',
                '& .MuiChip-icon': {
                  color: 'inherit'
                }
              }}
            />
          )
        }
      </Box >
    </Stack >
  );
};

export const ProductsTable = ({
  found, products, loading, serverPage,
  setSearch, hasProducts, hasParams,
}: ProductsTableProps) => {

  const dispatch = useAppDispatch();
  const intl = useIntl();
  const feed = GetCurrentFeedId();
  const mode = useAppSelector(getAppMode);
  const params = useAppSelector(getProductsParams);

  const [paginationModel, setPaginationModel] = useState({
    page: (serverPage - 1) ?? 0,
    pageSize: PRODUCTS_PER_PAGE,
  });

  const handlePage = (model: GridPaginationModel) => {
    setPaginationModel(model);
    dispatch(setProductsParams({
      ...params,
      page: model.page + 1,
      page_per: model.pageSize,
    }));
  }

  const sPageMemo = useMemo(() => serverPage, [serverPage]);

  useEffect(() => {
    if (sPageMemo > 0) {
      setPaginationModel(model => ({ ...model, page: (sPageMemo - 1) }));
    }
  }, [sPageMemo]);

  // Custom hooks
  // useLoading(loading);
  const { findProductLabels } = useLabels();
  const { getChannelStatus } = useProductUtils();

  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "",
      flex: 0.5,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Box sx={{ height: '50px' }}>
            <img src={params.value} alt={""} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </Box>
        )
      }
    },
    {
      field: "id",
      headerName: intl.formatMessage({ id: "reference" }),
      flex: 0.5,
      sortable: false,
      filterable: false,

    },
    {
      field: "product",
      headerName: intl.formatMessage({ id: "product" }),
      flex: 5,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <ProductTableCell
          title={params.row.productTitle}
          productId={params.row.product?.product_id}
          labels={params.row.labels}
          enhancedByAi={params.row.product.enhanced_by_ai}
        />
      )
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        return <Box sx={{ display: "flex", gap: 1, width: '100%' }}>
          {getChannelStatus(row.product, "google")}
          {getChannelStatus(row.product, "meta")}
          {getChannelStatus(row.product, "microsoft")}
        </Box>
      },
    },
    {
      field: "category",
      sortable: false,
      filterable: false,
      headerName: intl.formatMessage({ id: "category" }),
      flex: 1.5,
      renderCell: (params) => MakeCategoryName(params.value)
    },
    {
      field: "brand",
      headerName: intl.formatMessage({ id: "brand" }),
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => params.value ?? <CloudOff fontSize={"small"} color={"disabled"} />,
    },
    {
      field: "actions",
      headerName: "",
      flex: 1.5,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return <Box display={"flex"} justifyContent={"end"} width={'100%'}>
          <Tooltip arrow placement={"top"} title={<FormattedMessage id={'quick-edit'} />}>
            <IconButton
              onClick={() => dispatch(setProductPreview(params.value.id))}
            >
              <Preview />
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement={"top"} title={<FormattedMessage id={'product-edit'} />}>
            <IconButton
              className={"product-edit-button"}
              component={Link}
              to={Urls.products.edit(feed, params.value.id)}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement={"top"} title={<FormattedMessage id={'see-product'} />}>
            <IconButton
              component={'a'}
              href={params.value.link}
              target={'_blank'}
              onClick={(e) => e.stopPropagation()}
            >
              <Launch />
            </IconButton>
          </Tooltip>
        </Box>
      }
    }
  ];

  const rows = products.map(p => {
    const productData = FeedDataFeedcast(p);
    return {
      id: p.product_id,
      f_id: p.id,
      image: productData.image_link,
      product: p,
      productTitle: productData.title,
      category: productData.google_product_category_k,
      brand: productData.brand_k,
      labels: findProductLabels(productData.id_k),
      actions: {
        id: p.id,
        link: productData.link,
      },
      enhanced_by_ai: p.enhanced_by_ai,
    }
  });

  // Add this new state to track selected rows
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  return (
    <Box sx={{ ".MuiDataGrid-virtualScroller": { minHeight: '40vh' } }}>
      <DataGridPremium
        loading={loading}
        unstable_cellSelection
        disableRowSelectionOnClick
        columns={columns}
        rows={rows}
        rowCount={found}
        pagination
        paginationMode={"server"}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePage}
        keepNonExistentRowsSelected
        pageSizeOptions={[5, 10, 20, 50, 100, 500]}
        checkboxSelection
        getRowHeight={() => 'auto'}
        onRowSelectionModelChange={(newSelection) => {
          setSelectedRows(newSelection as string[]);
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        slots={{
          // loadingOverlay: LinearProgress,
          toolbar: () => (
            <GridToolbarContainer sx={{ p: 2, flexDirection: "column", alignItems: "start" }}>
              <ProductListFilters
                search={params}
                onSearch={setSearch}
                hasProducts={hasProducts}
                hasParams={hasParams}
              />
              <ProductListToolbar />
            </GridToolbarContainer>
          ),
          noRowsOverlay: NoProductsList,
        }}
        sx={{
          backgroundColor: mode === "light" ? "#fff" : "",
          "& .MuiDataGrid-main>div:last-child": {
            display: "none"
          },
          "& .MuiDataGrid-virtualScroller": {
            overflow: "hidden",
          }
        }}
      />
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={0.5}
        justifyContent={"end"}
        color={"text.secondary"}
        mt={1}
      >
        <Typography variant={"caption"}>
          <FormattedMessage id={"find-how"} />
        </Typography>
        <HelpLink title={"edit-products"} help={"edit-products"} />
        <Typography variant={"caption"}>
          <FormattedMessage id={"and-our"} />
        </Typography>
        <HelpLink title={"feed-advices"} help={"feed-advices"} />
      </Stack>
      <ModalBatchAi />

    </Box>
  )
}


import { FormattedMessage } from "react-intl";
import AppSecured from "../../App/AppSecured/AppSecured";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import React, { useCallback, useEffect } from "react";
import projectsApi from "../../../app/services/secured/projects";
import { LoadingAnimation } from "../../App/Blocks/Misc";
import Urls from "../../../assets/js/Urls";
import { modalShowProject } from "../../../app/slices/modal";
import feedApi from "../../../app/services/secured/feed";
import { StorageManager } from "../../../app/storage";
import { getAppMode, redirect, resetCurrentFeed, setAppMode, setCurrentPage } from "../../../app/slices/app";
import { Avatar, Backdrop, Box, Button, Paper, Typography } from "@mui/material";
import { Project } from "./Project";
import { FeedBlock } from "./FeedBlock";
import { ModalProject } from "./ModalProject";
import { Brightness4, Brightness7, DarkMode, ReportOutlined, Settings, Support, WbSunny } from "@mui/icons-material";
import { resetAppNotif } from "../../../app/slices/app_notifications";
import { useDrop } from "react-dnd";
import Grid2 from "@mui/material/Unstable_Grid2";
import FCard from "../../App/Blocks/Card";
import { motion } from "framer-motion";
import { BigDataCard } from "../../Pages/Dashboard/Cards/BigDataCard";

const Projects = () => {

  const dispatch = useAppDispatch();
  const { projects, feeds } = useFetchData();
  const feedsWithoutProjects = feeds.data?.filter(f => f.project === null);
  const setShowNewProject = () => dispatch(modalShowProject());

  return (
    <AppSecured lockNav={true}>
      <ProjectsHero />
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"end"}
        mb={2}
        mt={2}
      >
        <Typography variant={"h6"}>
          <FormattedMessage id={'projects-manage'} />
        </Typography>
        <Button
          size={'large'}
          variant={"contained"}
          onClick={() => setShowNewProject()}
        >
          <FormattedMessage id={'new-project'} />
        </Button>
      </Box>

      <LoadingAnimation data={feeds}>
        <Box>
          {projects?.map(p => (
            <Project key={`project-${p.name}`}{...p} />
          ))}
        </Box>

        {(feedsWithoutProjects?.length || 0 > 0)
          ? (
            <Box>
              <Box display={"flex"} alignItems={"center"} mb={2}>
                <ReportOutlined fontSize={'large'} />
                <Typography variant={"h4"} ml={0.5}>
                  <FormattedMessage id={"without-project"} />
                </Typography>
              </Box>
              <Box>
                <FCard>
                  <Grid2 container spacing={2}>
                    {feeds.data?.filter(f => f.project === null).map(f => (
                      <FeedBlock {...f} key={`f-${f.id}`} />
                    ))}
                  </Grid2>
                </FCard>
              </Box>
            </Box>
          ) : <Box />}
      </LoadingAnimation>
      <ModalProject />
    </AppSecured>
  )
}

const useFetchData = () => {

  const getProjects = projectsApi.useGetProjectsQuery();
  const projects = getProjects.data ?? [];
  const feeds = feedApi.useGetFeedsQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentPage("projects"));
    dispatch(resetCurrentFeed());
    dispatch(resetAppNotif());
  }, []);

  useEffect(() => {
    if (getProjects.isError) {
      StorageManager.clear();
      dispatch(redirect(Urls.auth.login));
    }
  }, [getProjects.isError]);

  return { projects, feeds };
};

const ProjectsHero = () => {
  const dispatch = useAppDispatch();
  const mode = useAppSelector(getAppMode);
  const toggleMode = useCallback(() => dispatch(setAppMode(mode === 'light' ? 'dark' : 'light')), [dispatch, mode]);
  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={4}>
        <motion.div
          initial={{
            transform: "scale(1)",
          }}
          whileHover={{
            transform: "scale(1.05)",
          }}
          style={{ height: '100%' }}
        >
          <Paper
            sx={{
              background: "rgba(112,75,247,.1)",
              p: 3,
              width: '100%',
              justifyContent: "space-between",
              display: 'flex', alignItems: "center", textAlign: "left",
              height: "100%"
            }}
            component={Button}
            onClick={() => window.open("https://help.feedcast.io", "_blank")}
          >
            <Box>
              <Typography variant={"h4"}>
                Helpdesk
              </Typography>
              <Typography>
                <FormattedMessage id={'helpdesk-desc'} />
              </Typography>
            </Box>
            <Box>
              <Avatar variant={"rounded"} sx={{ background: "transparent" }}>
                <Support fontSize={"large"} color={"secondary"} />
              </Avatar>
            </Box>
          </Paper>
        </motion.div>
      </Grid2>
      <Grid2 xs={4}>
        <motion.div
          initial={{
            transform: "scale(1)",
          }}
          whileHover={{
            transform: "scale(1.05)",
          }}
          style={{ height: '100%' }}
        >
          <Paper
            sx={{
              background: "rgba(112,75,247,.1)",
              p: 3,
              width: '100%',
              justifyContent: "space-between",
              display: 'flex', alignItems: "center", textAlign: "left",
              height: '100%',
            }}
            component={Button}
            onClick={() => dispatch(redirect(Urls.settings.me))}
          >
            <Box>
              <Typography variant={"h4"}>
                <FormattedMessage id={'settings'} />
              </Typography>
              <Typography>
                <FormattedMessage id={'go-to-settings'} />
              </Typography>
            </Box>
            <Box>
              <Avatar variant={"rounded"} sx={{ background: "transparent" }}>
                <Settings fontSize={"large"} color={"secondary"} />
              </Avatar>
            </Box>
          </Paper>
        </motion.div>
      </Grid2>
      <Grid2 xs={4}>
        <motion.div
          initial={{
            transform: "scale(1)",
          }}
          whileHover={{
            transform: "scale(1.05)",
          }}
          style={{ height: '100%' }}
        >
          <Paper
            sx={{
              background: "rgba(112,75,247,.1)",
              p: 3,
              width: '100%',
              justifyContent: "space-between",
              display: 'flex', alignItems: "center", textAlign: "left",
              height: '100%',
            }}
            component={Button}
            onClick={toggleMode}
          >
            <Box>
              <Typography variant={"h4"}>
                <FormattedMessage id={'app-mode'} />
              </Typography>
              <Typography>
                <FormattedMessage id={'app-mode-desc'} />
              </Typography>
            </Box>
            <Box>
              <Avatar variant={"rounded"} sx={{ background: "transparent" }}>
                {mode === 'dark' ? <WbSunny fontSize={"large"} color={"secondary"} /> : <DarkMode fontSize={"large"} color={"secondary"} />}
              </Avatar>
            </Box>
          </Paper>
        </motion.div>
      </Grid2>
    </Grid2>
  )
}

export default Projects;

import React, { useMemo } from "react";
import { ReactChildren } from "../../../../types";
import { Navigate } from "react-router-dom";
import Urls from "../../../assets/js/Urls";
import { useAppSelector } from "../../../app/hooks";
import { getAppMode, } from "../../../app/slices/app";
import ChatBot from "../Chatbot";
import { Backdrop, Box, Typography } from "@mui/material";
import { ModalContainer } from "./ModalContainer";
import { FeedcastApp } from "./FeedcastApp";
import { FormattedMessage } from "react-intl";
import logo_2 from "../../../assets/img/logo/fc_light.svg";
import logo_2_alt from "../../../assets/img/logo/fc_dark.svg";
import { useAppInit } from "../../../app/hooks/useAppInit";

export interface AppSecuredProps extends ReactChildren {
  lockNav?: boolean,
}

const AppSecured = (p: AppSecuredProps) => {

  const {
    wasUserLogged,
    isUserIdentified,
    isUserMerchant,
    isPendingValidation
  } = useAppInit();

  const condition = useMemo(() => (!wasUserLogged || !isUserMerchant), [wasUserLogged, isUserMerchant]);

  return (
    <Box>
      {(condition) && (
        <Navigate to={Urls.auth.login} />
      )}
      {!isPendingValidation ? (
        <React.Fragment>
          <Box id={"feedcast-app"}>
            {isUserIdentified && (
              <Box>
                <FeedcastApp lockNav={p.lockNav}>
                  {p.children}
                </FeedcastApp>
                <ModalContainer />
              </Box>)}
          </Box>
          <MobileBlock />
          <ChatBot loadWithoutData={false} />
        </React.Fragment>
      ) : (
        <Backdrop
          open={true}
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Box>
            <Typography variant={"h3"}>
              <FormattedMessage id={'pending-user-validation'} />
            </Typography>
            <Typography>
              <FormattedMessage id={'pending-user-validation-desc'} />
            </Typography>
          </Box>
        </Backdrop>
      )}
    </Box>
  )
}

const MobileBlock = () => {
  const mode = useAppSelector(getAppMode);
  return (
    <Box id={'mobile-warning'}>
      <Box>
        <img src={mode === "light" ? logo_2 : logo_2_alt} width={'120px'} />
        <p><FormattedMessage id={'feedcast-mobile'} /></p>
      </Box>
    </Box>
  )
}

export default AppSecured;

import { AutoAwesome, Refresh } from '@mui/icons-material';
import { Box, Button, CircularProgress, Alert } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { FormattedMessage } from 'react-intl';

import { useAppSelector } from '../../../../../app/hooks';
import { getProduct, getProductAi, getProductDataSource } from '../../../../../app/slices/product';
import { useProductAi } from './AI/useProductAi';
import { Description } from './Description';
import { Title } from './Title';

import { useState } from "react";

export interface AiFieldProps {
  onChange: (value: string) => void;
  value: string;
  disabled: boolean;
}

export const TitleDesc = () => {

  const [showOriginal, setShowOriginal] = useState(false); // New state to track toggle

  const disableInputs = useAppSelector(getProductDataSource) === "origin";

  const product = useAppSelector(getProduct);
  const versions = useAppSelector(getProductAi);
  const v = versions.title.versions;
  const hasVersions = v.length > 0;

  const {
    texts,
    manualEdit,
    productAiRes,
    generateAiText,
  } = useProductAi();

  const isLoading = productAiRes.isLoading;

  const buttonText = hasVersions
    ? (showOriginal ? <FormattedMessage id="show_ai_version" /> : <FormattedMessage id="show_original_version" />)
    : <FormattedMessage id="generate_ai" />;

  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Alert
            // variant="outlined"
            severity={product?.enhanced_by_ai ? "info" : "warning"}
            icon={<AutoAwesome fontSize="small" />}
            sx={{
            }}
          >
            <FormattedMessage
              id={product?.enhanced_by_ai ? "product_enhanced_by_ai" : "product_not_enhanced_by_ai"}
              defaultMessage={product?.enhanced_by_ai
                ? "This product has been enhanced by AI"
                : "This product has not been enhanced by AI yet"
              }
            />
          </Alert>
          <Button
            variant={product?.enhanced_by_ai ? "outlined" : "contained"}
            size={product?.enhanced_by_ai ? "small" : "medium"}
            onClick={() => {
              if (hasVersions) {
                setShowOriginal(!showOriginal);
              } else {
                generateAiText();
              }
            }}
            startIcon={hasVersions ? (showOriginal ? <Refresh /> : <AutoAwesome />) : <AutoAwesome />}
          >
            {!isLoading ? buttonText : <CircularProgress size={"1rem"} sx={{ color: '#fff' }} />}
          </Button>

        </Box>

      </Grid2>
      <Grid2 xs={12}>
        <Title
          value={showOriginal ? versions.title.original : texts.title} // Conditionally render text
          disabled={disableInputs}
          onChange={(val) => manualEdit(val, "title")}
        />
      </Grid2>
      <Grid2 xs={12}>
        <Description
          value={showOriginal ? versions.description.original : texts.description} // Conditionally render text
          disabled={disableInputs}
          onChange={(val) => manualEdit(val, "description")}
        />
      </Grid2>
    </Grid2>
  )
}

import { FormControl, TextField } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { AiFieldProps } from './TitleDesc';

export const Description = ({ value, disabled, onChange }: AiFieldProps) => {
  return (
    <React.Fragment>
      <FormControl fullWidth>
        <TextField
          multiline
          rows={16}
          label={<FormattedMessage id={"description"} />}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
        />
      </FormControl>
    </React.Fragment>
  )
}

import {
  GoogleAttribute,
  GoogleCode,
  PAgeObject,
  PAvailabilityObject,
  PConditionObject, PGenderObject,
  ProductData, PSizeObject
} from "../../app/types/products";

const googleErrors: Record<string, string> = {
  "Missing value [description]": "missing-value-description",
  "Low image quality [image link]": "low-image-quality",
  "Invalid value [availability]": "invalid-value-availability",
  "Text too short [description]": "text-short-description",
  "Desktop page not crawlable due to robots.txt": "desktop-page-robots",
  "Mobile page not crawlable due to robots.txt": "mobile-page-robots",
  "Invalid value for category [price]": "invalid-value-category-price",
  "Automatic item updates active [price]": "item-updates-price",
  "Invalid value [gtin]": "invalid-value-gtin",
  "Incorrect language": "incorrect-language",
  "Missing value [image link]": "missing-value-image",
  "Incorrect value [identifier exists]": "incorrect-value-identifier-exists",
  "Violation of Google policies": "violation-google-policies",
  "Missing potentially required value [unit pricing measure]": "missing-pricing-measure",
  "Limited performance due to missing value [gtin]": "limited-performance-gtin",
  "Automatic item updates active [availability]": "item-updates-availability",
  "Unavailable desktop landing page": "unavailable-desktop",
  "Promotional overlay on image [image link]": "promotional-overlay-image",
  "Excessive capitalisation [title]": "excessive-capitalisation-title",
  "Limited performance due to missing identifiers [gtin, mpn, brand]": "limited-performance-identifier-gtin-mpn-brand",
  "Missing value [size]": "missing-value-size",
  "Missing value [color]": "missing-value-color",
  "Missing value [gender]": "missing-value-gender",
  "Missing value [age_group]": "missing-value-age-group",
  "Missing potentially required value [energy efficiency class]": "missing-energy-efficiency",
  "Invalid characters [description]": "invalid-characters-description",
  "Ambiguous value [gtin]": "ambiguous-value-gtin",
  "Unsupported value due to restricted prefix [gtin]": "unsupported-value-gtin",
  "Invalid value [ads redirect]": "invalid-value-ads-redirect",
  "Missing category [category]": "missing-category-category",
  "Excessive capitalization [title]": "excessive-capitalization-title"
}

export const googleDoc: Record<GoogleCode, { EN: string, FR: string }> = {

  account_disapproval: {
    EN: "https://support.google.com/merchants/answer/1678274?hl=en",
    FR: "https://support.google.com/merchants/answer/1678274?hl=fr"
  },
  css_merchant_opt_out: {
    EN: "https://support.google.com/merchants/answer/9857332?hl=en",
    FR: "https://support.google.com/merchants/answer/9857332?hl=fr"
  },
  hard_goods_missing_2_out_of_3_identifiers: {
    EN: "",
    FR: ""
  },
  identifier_exists_false_misuse: {
    EN: "https://support.google.com/merchants/answer/9464748?hl=en",
    FR: "https://support.google.com/merchants/answer/9464748?hl=fr"
  },
  image_decoding_error: {
    EN: "https://support.google.com/merchants/answer/6183365?hl=en",
    FR: "https://support.google.com/merchants/answer/6183365?hl=fr"
  },
  image_link_broken: {
    EN: "https://support.google.com/merchants/answer/160038?hl=en",
    FR: "https://support.google.com/merchants/answer/160038?hl=fr"
  },
  image_link_internal_error: {
    EN: "https://support.google.com/merchants/answer/6240184?hl=en",
    FR: "https://support.google.com/merchants/answer/6240184?hl=fr"
  },
  image_link_pending_crawl: {
    EN: "https://support.google.com/merchants/answer/160640?hl=en",
    FR: "https://support.google.com/merchants/answer/160640?hl=fr"
  },
  image_single_color: {
    EN: "https://support.google.com/merchants/answer/10607160?hl=en",
    FR: "https://support.google.com/merchants/answer/10607160?hl=fr"
  },
  image_too_small: {
    EN: "https://support.google.com/merchants/answer/6205396?hl=en",
    FR: "https://support.google.com/merchants/answer/6205396?hl=fr"
  },
  image_unwanted_overlays: {
    EN: "https://support.google.com/merchants/answer/6101131?hl=en",
    FR: "https://support.google.com/merchants/answer/6101131?hl=fr"
  },
  incorrect_identifier: {
    EN: "https://support.google.com/merchants/answer/9240349?hl=en",
    FR: "https://support.google.com/merchants/answer/9240349?hl=fr"
  },
  invalid_upc: {
    EN: "https://support.google.com/merchants/answer/9240349?hl=en",
    FR: "https://support.google.com/merchants/answer/9240349?hl=fr"
  },
  item_duplicate_by_gtin: {
    EN: "https://support.google.com/merchants/answer/10023489?hl=en",
    FR: "https://support.google.com/merchants/answer/10023489?hl=fr"
  },
  landing_page_crawling_not_allowed: {
    EN: "https://support.google.com/merchants/answer/6098185?hl=en",
    FR: "https://support.google.com/merchants/answer/6098185?hl=fr"
  },
  landing_page_error: {
    EN: "https://support.google.com/merchants/answer/6098155?hl=en",
    FR: "https://support.google.com/merchants/answer/6098155?hl=fr"
  },
  media_missing_gtin: {
    EN: "https://support.google.com/merchants/answer/6098295?hl=en",
    FR: "https://support.google.com/merchants/answer/6098295?hl=fr"
  },
  mismatching_brand_gtin_prefix: {
    EN: "https://support.google.com/merchants/answer/6318513?hl=en",
    FR: "https://support.google.com/merchants/answer/6318513?hl=fr"
  },
  missing_required_gtin: {
    EN: "https://support.google.com/merchants/answer/9545238?hl=en",
    FR: "https://support.google.com/merchants/answer/9545238?hl=fr"
  },
  missing_shipping: {
    EN: "https://support.google.com/merchants/answer/6239383?hl=en",
    FR: "https://support.google.com/merchants/answer/6239383?hl=fr"
  },
  missing_shipping_weight: {
    EN: "https://support.google.com/merchants/answer/6239383?hl=en",
    FR: "https://support.google.com/merchants/answer/6239383?hl=fr"
  },
  mobile_landing_page_crawling_not_allowed: {
    EN: "https://support.google.com/merchants/answer/6101165?hl=en",
    FR: "https://support.google.com/merchants/answer/6101165?hl=fr"
  },
  mobile_landing_page_error: {
    EN: "https://support.google.com/merchants/answer/6098296?hl=en",
    FR: "https://support.google.com/merchants/answer/6098296?hl=fr"
  },
  non_product_data: {
    EN: "",
    FR: ""
  },
  pending_initial_policy_review_shopping_ads: {
    EN: "",
    FR: ""
  },
  policy_enforcement_account_disapproval: {
    EN: "",
    FR: ""
  },
  policy_violation: {
    EN: "https://support.google.com/merchants/answer/6109185?hl=en",
    FR: "https://support.google.com/merchants/answer/6109185?hl=fr"
  },
  preemptive_item_disapproval_price_untrusted: {
    EN: "https://support.google.com/merchants/answer/9773429?hl=en",
    FR: "https://support.google.com/merchants/answer/9773429?hl=fr"
  },
  price_mismatch: {
    EN: "https://support.google.com/merchants/answer/6098334?hl=en",
    FR: "https://support.google.com/merchants/answer/6098334?hl=fr"
  },
  price_out_of_range: {
    EN: "",
    FR: ""
  },
  recalled_product: {
    EN: "https://support.google.com/merchants/answer/9870351?hl=en",
    FR: "https://support.google.com/merchants/answer/9870351?hl=fr"
  },
  reserved_gtin: {
    EN: "",
    FR: ""
  },
  shipping_weight_too_high: {
    EN: "",
    FR: ""
  },
  strong_id_inaccurate: {
    EN: "",
    FR: ""
  },
  url_does_not_match_homepage: {
    EN: "https://support.google.com/merchants/answer/160050?hl=en",
    FR: "https://support.google.com/merchants/answer/160050?hl=fr"
  },
  missing_item_attribute_for_product_type: {
    EN: "https://support.google.com/merchants/answer/9762223?hl=en",
    FR: "https://support.google.com/merchants/answer/9762223?hl=fr",
  },
  item_missing_required_attribute: {
    EN: "https://support.google.com/merchants/answer/10538362?hl=en",
    FR: "https://support.google.com/merchants/answer/10538362?hl=fr",
  },
  missing_related_attribute: {
    EN: "https://support.google.com/merchants/answer/10538362?hl=en",
    FR: "https://support.google.com/merchants/answer/10538362?hl=fr",
  },
  apparel_missing_brand: {
    EN: "",
    FR: "",
  },
  dataquality_invalid_attribute_value: {
    EN: "",
    FR: "",
  },
  item_group_by_gtin_with_conflicting_attributes: {
    EN: "https://support.google.com/merchants/answer/6318513?hl=en",
    FR: "https://support.google.com/merchants/answer/6318513?hl=fr"
  },
  google_category_unrecognized: {
    EN: "https://support.google.com/merchants/answer/6239371?hl=en",
    FR: "https://support.google.com/merchants/answer/6239371?hl=fr"
  },
  coupon_gtin: {
    EN: "https://support.google.com/merchants/answer/6286302?hl=en",
    FR: "https://support.google.com/merchants/answer/6286302?hl=fr"
  },
  homepage_not_claimed: { EN: "", FR: "" },
  image_link_crawling_not_allowed: { EN: "", FR: "" },
  image_link_slow_crawl: { EN: "", FR: "" },
  image_too_generic: { EN: "", FR: "" },
  pending_address_and_phone_details: { EN: "", FR: "" },
}

export const googleAttributesToProductData: Record<GoogleAttribute, keyof ProductData> = {
  "description": "description",
  "availability date": "availability_date_k",
  "google product category": "google_product_category_k",
  "identifier exists": "gtin",
  "image link": "image_link",
  "sale price": "sale_price",
  "shipping weight": "shipping_weight",
  NO_CODE: "gtin",
  availability: "availability_k",
  brand: "brand_k",
  color: "color_k",
  gender: "gender_k",
  gtin: "gtin",
  link: "link",
  mpn: "mpn",
  price: "price",
  shipping: "shipping_weight", // TODO
  size: "size_k",
  title: "title",
  "age group": "age_group_k"
}

export const productDataTypes: Record<keyof ProductData, "text" | "number" | "long-text" | 'text-unit' | "select" | "select-multi" | "date" | "boolean"> = {
  age_group_k: "select",
  availability_date_k: "date",
  availability_k: "select",
  brand_k: "text",
  color_k: "text",
  condition_k: "select",
  description: "long-text",
  excluded_destinations: "text",
  gender_k: "select",
  google_product_category_k: "number",
  gtin: "text",
  id_k: "text",
  identifier_exists: "boolean",
  image_link: "text",
  is_bundle: "boolean",
  link: "text",
  material_k: "text",
  mpn: "text",
  price: "text",
  product_weight: "text",
  sale_price: "text",
  sale_price_effective_date: "text",
  shipping: "text",
  shipping_weight: "text-unit",
  size_k: "select-multi",
  title: "text",
  adult_k: "boolean",
  title_ai: "text",
  description_ai: "long-text",
}

export const attributeOptions: Record<Extract<keyof ProductData, "age_group_k" | "availability_k" | "condition_k" | "gender_k" | "size_k">, string[]> = {
  "age_group_k": PAgeObject,
  "availability_k": PAvailabilityObject,
  "condition_k": PConditionObject,
  "gender_k": PGenderObject,
  "size_k": PSizeObject,
}

export default googleErrors;

import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  useGridApiContext
} from "@mui/x-data-grid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getSelectedProducts, SelectedProduct, setSelectedProducts } from "../../../../app/slices/app";
import { modalShowAiBatch, modalShowLabel, modalShowProductMultipleEdit } from "../../../../app/slices/modal";
import { Box, Button, Fade } from "@mui/material";
import { AutoAwesome, AutoFixHigh, Edit, LabelImportant, TagSharp } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";

export const ProductListToolbar = () => {

  const dispatch = useAppDispatch();
  const apiRef = useGridApiContext();
  const [showLabelButton, setShowLabelButton] = useState(false);
  const selected = apiRef.current.getSelectedRows();
  const currentRows = apiRef.current.getAllRowIds();
  const selectedMemo = useMemo(() => selected, [selected]);

  const alreadySelectedProducts = useAppSelector(getSelectedProducts);

  const makeSelectedProducts = useCallback(() => {
    const selectedProducts: SelectedProduct[] = [...alreadySelectedProducts].filter(s => currentRows.findIndex(c => c === s.ref) < 0);
    if (selectedMemo.size) {
      selectedMemo.forEach((s) => s && selectedProducts.push({ id: s.f_id, ref: s.id, enhanced_by_ai: s.enhanced_by_ai }));
    }
    return selectedProducts;
  }, [selectedMemo.size]);

  useEffect(() => {
    setShowLabelButton(selectedMemo.size > 0);
    const selectedProducts = makeSelectedProducts();
    dispatch(setSelectedProducts(selectedProducts));
  }, [selectedMemo.size]);

  const showModalLabel = () => dispatch(modalShowLabel({}));
  const showModalEdit = () => dispatch(modalShowProductMultipleEdit());
  const showModalAi = () => dispatch(modalShowAiBatch());

  return (
    <Box width='100%'>
      <Box sx={{ display: "flex", gap: 1 }}>
        <GridToolbarColumnsButton />
        <Fade in={showLabelButton}>
          <Button
            size={"small"}
            variant="contained"
            startIcon={<LabelImportant />}
            onClick={showModalLabel}
          >
            <FormattedMessage id={"handle-dynamic-label"} />
          </Button>
        </Fade>
        <Fade in={showLabelButton} timeout={300}>
          <Button
            size={"small"}
            startIcon={<Edit />}
            onClick={showModalEdit}
            variant="contained"
          >
            <FormattedMessage id={"edit-multiple-products"} />
          </Button>
        </Fade>
        <Fade in={showLabelButton} timeout={300}>
          <Button
            size={"small"}
            startIcon={<AutoFixHigh />}
            onClick={showModalAi}
            variant="contained"
          >
            <FormattedMessage id={"ai-batch"} />
          </Button>
        </Fade>
      </Box>
    </Box>
  );
}

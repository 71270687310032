import { StorageManager } from "../../app/storage";
import { AdsProvider } from "../../app/types/campaign";

export type CustomHeaders = Record<string, string>;

export const baseHeaders: CustomHeaders = {
  "X-Requested-With": "XMLHttpRequest",
  "Feedcast-Client": "customer",
};

export const getCustomHeaders = (headers: Headers, withToken: boolean): Headers => {
  Object.keys(baseHeaders).forEach((key) => {
    headers.set(key, baseHeaders[key]);
  });

  if (withToken) {
    headers.set("Feedcast-Token", StorageManager.get() as string);
  }

  return headers;
}

export const ApiUrls = {
  auth: {
    login: '/',
    loginWithGoogle: '/google-auth',
    logout: '/api/logout',
    signup: '/signup',
    validate: (token: string) => `/validate-account/${token}.html`,
    refresh: '/forgot-password',
    refreshPassword: (token: string) => `/refresh-password/${token}.html`,
    resellerCookie: (code: string) => `/${code}/init`,
    incrementCookie: (hash: string) => `/${hash}/view`,
  },
  user: {
    me: `/api/me`,
    profile: '/api/me/profile',
    onboarding: '/api/me/onboarding',
    newPassword: '/api/me/password',
    invoices: '/api/invoices',
    logs: '/api/logs',
    messages: '/api/messages/live',
    clearMessage: (id: string | number) => `/api/logs/${id}/read`,
  },
  projects: {
    list: '/api/projects',
    edit: (id: string | number) => `/api/projects/${id}`
  },
  feeds: {
    list: `/api/feeds`,
    info: (id: string | number) => `/api/feeds/${id}`,
    reporting: (id: string | number) => `/api/feeds/${id}/reporting`,
    assets: (feed: string | number) => `/api/feeds/${feed}/accounts/assets`,
    preview: `https://europe-west9-feedcast-2023.cloudfunctions.net/feed-preview`,
    //preview: `https://feed-preview-300493580098.europe-west9.run.app`,
    channels: {
      link: (feed: string | number, provider: string, account_code: string) => `/api/feeds/${feed}/accounts/${provider}/link/${account_code}`,
      create: (feed: string | number, provider: string) => `/api/feeds/${feed}/accounts/${provider}/create`,
      status: (feed: string | number) => `/api/feeds/${feed}/accounts/status`,
      merchant: (feed: string | number) => `/api/feeds/${feed}/accounts/status/merchant`,
      phone: (feed: string | number) => `/api/feeds/${feed}/accounts/validate-phone`,
      phoneConfirm: (feed: string | number) => `/api/feeds/${feed}/accounts/validate-phone/confirm`,
      microsoftCenters: (feed: string | number) => `/api/feeds/${feed}/accounts/bg/stores`,
      linkMicrosoftCenter: (feed: string | number, account: string | number) => `/api/feeds/${feed}/accounts/bg/stores/${account}`,
      enableChannel: (feed: string | number, provider: string | number) => `/api/feeds/${feed}/platforms/${provider}`,
      getCredentials: `/api/credentials`,
      revokeCredential: (id: number) => `/api/credentials/${id}`,
      syncImportFeed: (feed: string | number) => `/api/feeds/${feed}/merchant-center/import-feed`,
      resetChannel: (feed: string | number, provider: AdsProvider) => `/api/feeds/${feed}/platforms/${provider}/reset`,
      tracking: (feed: string | number) => `/api/feeds/${feed}/pixels`,
    },
    campaigns: {
      list: (feed: number, provider: string) => `/api/feeds/${feed}/campaigns/${provider}`,
      edit: (feed: number, provider: string, campaign: string | number) => `/api/feeds/${feed}/campaigns/${provider}/${campaign}`,
      metaData: (feed: number) => `/api/feeds/${feed}/campaigns/fb/catalog-summary`,
      metaPages: (feed: number) => `/api/feeds/${feed}/campaigns/fb/pages`,
      metaBehaviours: (feed: number) => `/api/feeds/${feed}/accounts/fb/behaviours`,
      metaInterests: (feed: number) => `/api/feeds/${feed}/accounts/fb/interests`,
    },
    labels: {
      list: (feed: number) => `/api/feeds/${feed}/labels`,
      aggs: (feed: number) => `/api/feeds/${feed}/labels/aggregations`,
    }
  },
  subscription: {
    get: (id: string | number) => `/api/feeds/${id}/subscription`,
    init: (id: string | number) => `/api/feeds/${id}/subscription/init`,
    cancel: (id: string | number) => `/api/feeds/${id}/subscription/cancel`,
    sync: (id: string | number) => `/api/feeds/${id}/session/check`,
    clear: (id: string | number) => `/api/feeds/${id}/subscription/clear`,
  },
  global: {
    countries: '/api/global-lists/countries',
    locales: '/api/global-lists/locales',
    categories: (language: string) => `/api/global-lists/categories/${language}.json`,
  },
  products: {
    list: (feed: string | number) => `/api/feeds/${feed}/products`,
    edit: (feed: string | number, product: string | number) => `/api/feeds/${feed}/products/${product}`,
    category: (feed: string | number, cat: string | number) => `/api/feeds/${feed}/categories/${cat}`,
    categories: (feed: string | number) => `/api/feeds/${feed}/categories`,
    batch: (feed: string | number) => `/api/feeds/${feed}/products/batch`,
    batchAi: (feed: string | number) => `/api/feeds/${feed}/products/batch/ai`,
  },
  reporting: {
    products: (feed: string | number) => `/api/feeds/${feed}/reporting/products`,
    productList: (feed: string | number) => `/api/feeds/${feed}/reporting/products/list`,
    feed: (id: string | number) => `/api/feeds/${id}/reporting`,
    competitors: (feed: string | number) => `/api/feeds/${feed}/reporting/competitors`,
    popularProducts: (feed: string | number) => `/api/feeds/${feed}/reporting/popular-products`,
  },
  oauth: {
    google: '/api/oauth/accounts/go',
    meta: '/api/oauth/accounts/fb',
    microsoft: '/api/oauth/accounts/bg',
  },
  ai: {
    credits: (feed: string | number) => `/api/feeds/${feed}/quotas`,
    productTitle: (feed: string | number, feedProduct: string | number) => `/api/feeds/${feed}/products/${feedProduct}/ia/product-title`,
    productDescription: (feed: string | number, feedProduct: string | number) => `/api/feeds/${feed}/products/${feedProduct}/ia/product-description`,
  }
}

const all: Record<string, string> = {
  "pleaseWait": "Veuillez patienter...",
  "dashboard": "Tableau de bord",
  "dashboard-desc": "C'est ici que tout se passe",
  "performances": "Performances",
  "performances-desc": "Suivez vos chiffres",
  "products": "Produits",
  "products-desc": "Gérez vos produits",
  "advertisement": "Publicité",
  "advertisement-desc": "Gérez vos campagnes",
  "academy": "Académie",
  "academy-desc": "Apprenez des meilleurs",
  "nearly-there": "On y est presque ! ",
  "validate-email": "Cliquez sur le lien que vous venez de recevoir par e-mail pour finaliser votre inscription.",
  "token-verification": "Vérification en cours...",
  "account-validate-success": "Votre compte a bien été vérifié ! Vous pouvez maintenant vous connecter.",
  "logout": "Déconnexion",
  "active": "Actif",
  "inactive": "Inactif",
  "performances-page-title": "Performances",
  "performances-page-desc": "Voici la vue d'ensemble de vos performances publicitaires.",
  "datepicker-today": "Aujourd'hui",
  "datepicker-yesterday": "Hier",
  "datepicker-this-week": "Les 7 derniers jours",
  "datepicker-this-month": "Les 30 derniers jours",
  "datepicker-previous-month": "Mois précédent",
  "datepicker-this-year": "Cette année",
  "datepicker-period-selected": "Du {from} au {to}",
  "datepicker-last-year": "Année dernière",
  "impressions": "Impressions",
  "clicks": "Clics",
  "spent": "Dépenses",
  "conversions": "Ventes",
  "conversionValue": "CA",
  "dashboard-page-title": "Tableau de bord",
  "expertise": "Niveau d'expertise",
  "expertise-desc": "Complétez les missions de la checklist pour progresser",
  "amateur": "Amateur",
  "recommandations": "Recommandations",
  "recommandations-desc": "Suivez les recommandations pour augmenter votre indice de performance",
  "recommandations-available": "Recommandations disponibles",
  "recommandations-explanation": "Les recommandations vous aident à améliorer le score d'optimisation.",
  "see-recommandations": "Voir les recommandations",
  "performance-score": "Score d'optimisation des performances",
  "performance-score-explanation": "Le score d'optimisation est déterminé selon plusieurs facteurs liés à la qualité de votre flux produit, ainsi que la configuration générale de vos comptes publicitaires. \n \n Plus votre score est élevé, plus vous avez de chances de lancer des campagnes rentables !",
  "more": "En savoir plus",
  "how-to-account": "Comment créer un compte ?",
  "products-page-title": "Produits",
  "products-page-desc": "C'est ici qu'on configure tout ce qui est relatif à vos produits et catalogues importés",
  "global-view": "Vue d'ensemble",
  "product-list": "Liste des produits",
  "categories": "Catégories",
  "feed-handle": "Gestion du flux",
  "active-products": "Produits actifs",
  "warnings": "Avertissements",
  "warnings-errors-detail": "Détail du statut",
  "waiting-validation": "En attente",
  "refused-products": "Produits bloqués",
  "catalog-state": "État général du catalogue produit",
  "catalog-errors-recap": "Erreurs flux produit",
  "error-desc": "Descriptif de l'erreur",
  "error-products": "Produits affectés",
  "valid": "Valide",
  "stock": "En stock",
  "invalid": "Bloqué",
  "out-of-stock": "Épuisé",
  "search-by": "Rechercher parmi les {by}",
  "feed-product-state": "État des produits par leviers publicitaires",
  "feed-product-state-desc": "Suivez l'état de validation de vos produits selon vos leviers publicitaires actifs",
  "p-refused": "Bloqués",
  "p-waiting": "En attente",
  "p-warning": "Avertissement",
  "p-valid": "Validés",
  "feed-info": "Informations du flux",
  "feed-info-desc": "Modifier les informations du flux",
  "name": "Nom",
  "feed-reload": "Actualiser le flux",
  "save-edits": "Enregistrer les modifications",
  "reference": "Ref.",
  "state": "État",
  "state-google": "Statut",
  "category": "Catégorie",
  "brand": "Marque",
  "status": "Statut",
  "no-products": "Pas de produits !",
  "no-products-desc": "Importer vos produits facilement depuis votre boutique en ligne",
  "import-products": "Importer mes produits",
  "no-feed": "Pas de flux !",
  "no-feed-desc": "Importez un flux de données pour récupérer vos produits",
  "import-feed": "Importer mon flux",
  "new-feed-import": "Importer un flux produit",
  "edit-feed-success": "Succès édition du flux. Import des produits en cours, cela peut prendre plusieurs minutes...",
  "feed-from-shop": "Choisissez une méthode d'import",
  // "feed-from-others": "Autres méthodes d'import",
  "import-feed-desc": "Suivez les instructions pour importer votre flux produits.",
  "import-feed-by": "Importer un nouveau flux via {by}",
  "api-key": "Clé API",
  "token": "Token",
  "campaigns": "Campagnes",
  "optimisation": "Optimisation",
  "ads-account": "Comptes publicitaires",
  "conversion-follow": "Suivi de conversion",
  "no-campaign": "Aucune campagne !",
  "no-campaign-desc": "Aucune campagne n'a été trouvée avec ces filtres.",
  "new-campaign": "Nouvelle campagne",
  "projects": "Projets",
  "projects-manage": "Gestion des projets",
  "welcome": "Bienvenue !",
  "welcome-desc": "On aimerait en savoir un peu plus pour personnaliser au mieux votre expérience 🥳",
  "firstName": "Prénom",
  "lastName": "Nom de famille",
  "who-are-you": "Qui êtes-vous ?",
  "what-situation": "Quelle est votre situation ?",
  "how-do-you-know-us": "Comment nous avez-vous connu ?",
  "select": "Sélectionner...",
  "for-myself": "J'utilise Feedcast pour ma propre boutique",
  "for-customers": "J'utilise Feedcast pour le compte de mes clients",
  "on-google": "Sur Google",
  "on-facebook": "Sur Facebook / Instagram",
  "on-linkedin": "Sur Linkedin",
  "on-blog": "Via le blog / académie",
  "on-people": "Par le bouche-à-oreilles",
  "on-other": "Autres...",
  "thanks": "Merci",
  "thanks-desc": "Nous allons maintenant créer ensemble votre 1er projet, et y ajouter l'URL de votre boutique.",
  "confirm-creation": "Confirmer la création de mon projet",
  "ads-accounts": "Comptes publicitaires",
  "no-accounts": "Aucun compte !",
  "no-accounts-desc": "Connectez ou créez un compte publicitaire pour commencer à diffuser sur Feedcast",
  "begin": "Commencer",
  "perf-max": "Performance Max",
  "prospection": 'Prospection',
  "retargeting": "Retargeting",
  "smart-shopping": "Smart Shopping",
  "settings": "Paramètres",
  "my-account": "Mon compte",
  "subscriptions": "Abonnements",
  "staff": "Équipe",
  "affiliation": "Affiliation",
  "notifications": "Notifications",
  "new-password": "Nouveau mot de passe",
  "change-password": "Modifier mon mot de passe",
  "success-new-password": "Votre mot de passe a bien été modifié. Vous pouvez maintenant vous connecter !",
  "profile-edit-success": "Succès modification de vos informations",
  "security": "Sécurité",
  "project-name": "Nom du projet",
  "confirm-new-project": "Créer",
  "new-project-success": "Succès création du nouveau projet",
  "add-feed": "Ajouter un nouveau flux",
  "new-feed-success": "Succès création du flux",
  "new-feed": "Connecter un nouveau flux produit",
  "new-project": "Nouveau projet",
  "select-source": "Sélectionnez la source",
  "get-feed": "Récupération du flux",
  "preview": "Prévisualisation",
  "tuto-steps": "Étapes à suivre pour connecter votre boutique",
  "new-feed-info": "Vos informations de flux",
  "new-feed-info-desc": "Suivez le tutoriel pour lier votre boutique et connecter votre catalogue produits",
  "new-feed-url": "URL de votre flux produit {source}",
  "continue-with": "Continuer avec ",
  "store": "URL boutique",
  "api_key": "Clé API",
  "access_token": "Token d'accès",
  "success-onboarding": "Votre projet a bien été créé. Vous pouvez maintenant y ajou",
  "add-new-feed": "Créer ma nouvelle boutique",
  "new-feed-name": "Nom de domaine de votre boutique",
  "new-feed-country": "Pays/Langue de diffusion",
  "optional": "Facultatif",
  "fictif": "Facultatif",
  "feed-without-url": "Flux fictif",
  "feed-without-url-desc": "Vous pourrez configurer l'import des produits plus tard.",
  "how-to-multiple-locales": "Comment diffuser sur plusieurs pays ?",
  "how-to-multiple-locales-desc": "Pour diffuser ce même flux sur un autre pays, créez un nouveau flux.",
  "found-results": "résultat(s)",
  "new": "Neuf",
  "refurbished": "Remis à neuf",
  "used": "Usé",
  "condition": "État",
  "availability": "Disponibilité",
  "in_stock": "En stock",
  "out_of_stock": "Épuisé",
  "backorder": "En commande",
  "product-edit-success": "Succès  édition du produit",
  "profile-info": "Informations du profil",
  "invoice-info": "Informations de facturation",
  "auth-email": "Votre email de connexion (identifiant)",
  "your-first-name": "Votre prénom",
  "your-last-name": "Votre nom",
  "your-phone": "Votre téléphone",
  "business-name": "Raison sociale",
  "business-phone": "Téléphone du siège",
  "invoice-email": "Adresse email de facturation",
  "business-address": "Adresse du siège social",
  "business-address-2": "Adresse (complément)",
  "zip": "Code postal",
  "city": "Ville",
  "country": "Pays",
  "current-password": "Mot de passe actuel",
  "confirm-new-password": "Changer mon mot de passe",
  "new-password-success": "Succès modification de votre mot de passe.",
  "found-products": "produits trouvés",
  "missing-gtin": "GTIN manquant(s)",
  "missing-categories": "catégories manquantes",
  "missing-images": "Images manquantes",
  "missing-ids": "IDs manquants",
  "feed-edit-success": "Succès édition du flux",
  "feed-max-import": "En plan gratuit, le nombre de produits importés dans Feedcast est limité à 100.",
  "category-google": "Catégorie correspondante",
  "category-auto-link": "Confirmer liaison automatique",
  "category-map-success": "Succès édition de la catégorie",
  "products-imported": "Import Feedcast",
  "products-errors": "Refusés",
  "projects-handle": "Gérer mes projets",
  "google-desc": "Diffusez vos campagnes produits \n" +
    "à travers tout le réseau Google : Shopping, Search, Display, \n" +
    "Discover, Gmail, YouTube...",
  "meta-desc": "Diffusez vos campagnes produits \n" +
    "sur Facebook & Instagram : campagnes d’acquisition, DSA, retargeting...",
  "amazon-desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam gravida vestibulum fermentum. Nunc eu risus .",
  // "microsoft-desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam gravida vestibulum fermentum. Nunc eu risus .",
  // "pinterest-desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam gravida vestibulum fermentum. Nunc eu risus .",
  "FEED_DOWNLOAD_ERROR": "Erreur téléchargement de flux",
  "add-ads-account": "Ajouter un compte publicitaire",
  "add-ads-account-desc": "Sélectionner le levier sur lequel vous souhaitez connecter un compte publicitaire",
  "success-ads-account-link": "Succès liaison compte publicitaire",
  "add-campaign": "Créer une campagne",
  "success-ads-account-create": "Succès création compte publicitaire",
  "link-ads-account": "Lier votre compte publicitaire",
  "connect-with": "Se connecter avec",
  "create-ads-account": "Créer mon compte publicitaire",
  "create-ads-account-google": "Créer un nouveau compte Google Ads",
  "without-project": "Sans projet",
  "success-edit-campaign": "Succès édition de la campagne",
  "yes": "Oui",
  "no": "Non",
  "campaign-enable": "Activer ma campagne",
  "campaign-enable-confirm": "Êtes-vous certain de vouloir activer votre campagne ?",
  "campaign-disable": "Désactiver ma campagne",
  "campaign-disable-confirm": "Êtes-vous certain de vouloir désactiver votre campagne ?",
  "go": "Google",
  "fb": "Meta",
  "conversion": "Ventes",
  "campaigns-desc": "Suivez l'évolution de vos campagnes publicitaires en temps réel.",
  "subscription": "Abonnement",
  "GOOGLE_LISTING": "Google Listing SEO Ads",
  "GOOGLE_LISTING_DESC": "Afficher vos produits dans l'onglet Google Shoping",
  "INSTA_SHOPPING": "Instagram Ads",
  "INSTA_SHOPPING22": "Instagram Ads",
  "INSTA_SHOPPING_DESC": "Faites la promotion de votre catalogue produits sur Instagram",
  "INSTA_SHOPPING22_DESC": "Faites la promotion de votre catalogue produits sur Instagram",
  "google-shopping-ads": "Google Shopping Ads",
  "google-shopping-ads-desc": "Diffusez vos produits sur le Réseau de Recherche de Google, le Réseau Display, YouTube et Gmail",
  "meta-ads": "Meta Ads",
  "meta-ads-desc": "Faites la promotion de votre catalogue produits sur Facebook",
  "LIM2500": "2500 produits",
  "LIM5000": "5000 produits",
  "LIM10000": "10 000 produits",
  "LIM20000": "20 000 produits",
  "subscription-success": "Succès abonnement",
  "missing-invoice-info": "Informations de facturation manquantes",
  "missing-invoice-info-desc": "Renseigner mes informations de facturation",
  "create-first-feed": "Ajouter un nouveau flux",
  "no-data-yet": "Aucune donnée !",
  "no-data-yet-desc": "Vos performances publicitaires s'afficheront ici lorsque l'une de vos campagnes sera active.",
  "add-account": "Ajouter un compte",
  "connected-leverage": "Dépenses publicitaires",
  "canal": "Canal",
  "options": "Options",
  "cancel-subscription": "Annuler mon abonnement",
  "no-campaign-2-desc": "Nous n'avons trouvé aucune campagne publicitaire selon vos filtres.",
  "refresh-products": "Rafraîchir la liste des produits",
  "autopilot-desc": "L’abonnement Autopilot permet de diffuser sur 2 leviers (Google shopping Ads & Facebook Ads) et 1000 produits maximum.",
  "ht-month": "HT/mois",
  "already-in-subscription": "Offert dans votre abonnement",
  "included": "Inclus",
  "per-month": "/mois",
  "add": "Sélectionner",
  "remove": "Retirer",
  "feedcast-plan": "Plan Feedcast",
  "autopilot-plan": "Plan autopilot",
  "max-products": "Jusqu'à {p} produits",
  "update-subscription": "Modifier mon abonnement",
  "subscription-desc": "Gérez l'abonnement de votre flux.",
  "trial": "Essai",
  "canceled": "Annulé",
  "save": "Enregistrer",
  "update": "Mettre à jour",
  "alerts": "Alertes",
  "contact": "Contact",
  "email": "Email",
  "phone": "Téléphone",
  "address": "Adresse",
  "address-full": "Adresse complète",
  "shipping-cost": "Frais de livraison",
  "phone-validation": "Vérification de votre compte Merchant Center",
  "free": "Gratuit",
  "fixed": "Fixé",
  "ranged": "Variant (montant commande)",
  "ranged-weight": "Variant (poids)",
  "delivery-min": "Minimum de livraison",
  "delivery-max": "Maximum de livraison",
  "handling-min": "Jours min. prise en charge",
  "handling-max": "Jours max. prise en charge",
  "max-hour": "Heure max",
  "merchant-update-success": "Succès mise à jour des informations du Merchant Center",
  "fixed-price": "Prix fixe",
  "my-ads-accounts": "Mes comptes publicitaires",
  "no-project": "Sans titre",
  "soon": "Bientôt disponible !",
  "valid-code": "Valider mon code",
  "begin-phone-verification": "Recevoir un SMS de vérification",
  "received-code": "Code reçu par SMS",
  "budget": "Budget",
  "assets-manager": "Gestionnaire d'assets",
  "assets-manager-desc": "Gérez vos ressources créatives pour les associer à vos campagnes : images, vidéos...",
  "upload-asset-success": "Succès upload asset",

  "added": "sélectionné",
  "valid-selection": "Valider ma sélection",
  "previous": "Précédent",
  "next": "Suivant",
  "GLOBAL": "Général",
  "TARGETING": "Ciblage",
  "element-group-name": "Nom du groupe d'élément",
  "first-element-group": "Mon premier groupe d'élément",
  "company-name": "Nom de la société",
  "url-final": "URL finale",
  "long-titles": "Titres longs",
  "short-titles": "Titres courts",
  "descriptions": "Descriptions",
  "add-pictures": "Ajouter des images",
  "import-pictures": "Importer des images",
  "create-campaign": "Créer ma campagne",
  "edit-campaign": "Éditer campagne",
  "diffusion-country": "Dans quelle(s) langue(s) et dans quel(s) pays ?",
  "maximise-conversion": "Maximiser la valeur de conversion",
  "define-roas": "Définir un objectif de retour sur les dépenses publicitaires (facultatif)",
  "roas-target": "ROAS Cible",
  "auctions": "Enchères",
  "add-logo": "Ajouter des logos",
  "add-marketing-image-square": "Ajouter des images marketing (carré)",
  "add-marketing-image": "Ajouter des images (paysage)",
  "fetching-campaign": "Chargement des données de campagne...",
  "success-edition-campaign": "Vos modifications ont bien été enregistrées.",
  "PRODUCTS": "Produits",
  "products-labels": "Quels produits doit-on diffuser ?",
  "products-labels-desc": "Sélectionnez l'ensemble de produits sur lequel vous souhaitez diffuser votre annonce.",
  "small-description": "Description courte (60 caractères max)",
  "references": "Références",
  "brands": "Marques",
  "custom-labels": "Labels personnalisés",
  "create-custom-label": "Nouveau label",
  "eq": "Est égal à",
  "in": "Parmi",
  "custom_label_0_k": "Labels 1",
  "custom_label_1_k": "Labels 2",
  "custom_label_2_k": "Labels 3",
  "custom_label_3_k": "Labels 4",
  "custom_label_4_k": "Labels 5",
  "label0": "Labels 1",
  "label1": "Labels 2",
  "label2": "Labels 3",
  "label3": "Labels 4",
  "label4": "Labels 5",
  "success-dynamic-label": "Succès édition label personnalisés",
  "custom-group": "Groupe de labels",
  "label-value": "Nom du label",
  "apply-label-if": "Appliquer le label si",
  "all-conditions-met": "Toutes les conditions validées",
  "one-condition-met": "Au moins une condition validée",
  "overwrite-value": "Écraser la valeur du label si déjà existant",
  "attribute": "Attribut",
  "value": "Valeur",
  "add-filter": "Ajouter un filtre",
  "delete": "Supprimer",
  "edit-custom-label": "Édition label dynamique",
  "very-short-title": "Titre court (15 caractères max)",
  "mandatory": "Obligatoire",
  "youtube-ids": "Vidéos Youtube (facultatif)",
  "strategy": "Quelle est votre stratégie d'enchères ?",
  "strategy-desc": "Par défaut, une campagne Feedcast Warm-up diffuse des annonces sur tout votre catalogue, mais vous pouvez spécifier votre sélection au besoin.",
  "all-countries-territories": "Tous les pays et territoires",
  "choose-location-filters": "Choisir une autre zone géographique",
  "country-from-feed": "{country} (selon le pays du flux produit)",
  "select-countries": "Indiquez la/les pays à cibler",
  "select-languages": "Indiquez la/les langue(s) à cibler",
  "preselected-language": "Langue de diffusion préselectionnée depuis votre flux",
  "select-your-strategy": "Sélectionnez votre stratégie d'enchères",
  "manual_cpc": "Enchères au CPC manuelles",
  "target_roas": "ROAS Cible",
  "maximise-clicks": "Maximiser les clics",
  "activate-manual-cpc": "Activer l'optimisation du CPC (peut améliorer le volume de conversions)",
  "optimise-for-conversion": "Optimiser pour les conversions",
  "optimise-for-value": "Optimiser pour une valeur des conversions maximales",
  "roas-value": "Valeur du ROAS",
  "maximal-cpc": "Définir une limite d'enchère au CPC maximale",
  "bid-limit": "Limite d'enchère",
  "obselete-campaign-type": "Attention ! Ce type de campagne devient de plus en plus obselète. ",
  "rather-create": "Créez plutôt une ",
  "perf-max-campaign": "campagne Performance Max",
  "import-videos": "Importer des vidéos",
  "success-creation-campaign": "Votre nouvelle campagne a bien été créé ! ",
  "cpc": "CPC",
  "clickRate": "Taux clics",
  "conversionCost": "Coût/Conv",
  "roi": "R.O.I",
  "campaign": "Campagne",
  "toast-success": "Succès !",
  "toast-danger": "Erreur",
  "toast-warning": "Attention !",
  "toast-info": "Info :",
  "feedcast-campaign": "Campagne Feedcast",
  "copied-to-clipboard": "Copié dans le presse papier !",
  "period-recap": "Récapitulatif de la période",
  "target-roas": "Valeur du ROAS cible",
  "diffusion-country-desc": "Vous pouvez spécifier les pays dans lesquels vous voulez diffuser, ainsi que la/les langue(s) des personnes ciblées.",
  "classic-shopping-outdated": "Ce type de campagne devient obsolète ! Lancez plutôt une Performance Max ⬇️",
  "classic-shopping-outdated-desc": "Les campagnes Performance Max utilisent vos objectifs de conversion et le meilleur de l'automatisation Google Ads pour diffuser des annonces sur toutes les propriétés Google. Vous pouvez ainsi toucher vos clients au bon moment avec la bonne annonce.",
  "switch-all-countries": "Cochez pour cibler automatiquement tous les pays",
  "all-products": "Tous/toutes mes {type}",
  "all-products-desc": "(Recommandé, surtout si c'est votre 1ère campagne)",
  "specific-products": "Sélection de produits",
  "specific-products-desc": "(Si vous savez ce que vous faites)",
  "migrate-to-perf-max": "Migrer vers Performance Max",
  "google-perf-max-alert": "Avec Google Performance Max, c'est encore plus simple !",
  "perf-by-products": "Performances produits Google",
  "product": "Produit",
  "product-not-found": "Ce produit n'existe pas dans votre flux",
  "projects-title": "Comment utiliser mes projets Feedcast ?",
  "projects-desc": "Vos projets vous permettent de regrouper vos flux sous une seule entité. Si vous souhaitez changer le projet d'un flux, il vous suffit de le maintenir et le déposer dans le projet souhaité !",
  "devices-repartition": "Répartition des appareils",
  "desktop": "Ordinateur",
  "mobile": "Mobile",
  "tablet": "Tablette",
  "catalog-status": "État du catalogue",
  "accepted-filetypes": "Formats supportés : JPEG, PNG, MP4, PDF",
  "drag-drop-or": "Glisser/déposer ou ",
  "browse-files": "parcourir vos fichiers",
  "one-picture-required": "Vous devez ajouter au moins une image",
  "valid-products": "Produits validés",
  "new-campaign-desc": "Sélectionnez la façon dont vous souhaitez diffuser vos produits !",
  "choose-format": "Choisir ce format",
  "perf-max-desc": "Le format Performance Max permet de diffuser une seule et même campagne sur l’ensemble du réseau Google Ads : le Search, le réseau Display, Discover, Gmail, Google Maps et YouTube en utilisant le machine learning et l’automatisation.",
  "shopping": "Shopping Classique",
  "shopping-desc": "Mettez en avant vos produits sur tout le réseau Google Shopping pour générer des clics vers votre boutique et augmenter vos ventes.",
  "recommended": "Recommandé",
  "advanced": "Avancé",
  "required-activation": "Activation",
  "required-activation-desc": "Vérifiez votre compte avant de pouvoir créer des campagnes !",
  "google-campaigns-loading": "Chargement des campagnes Google...",
  "meta-campaigns-loading": "Chargement des campagnes Meta...",
  "loading-campaigns": "Chargement des campagnes...",
  "coming-soon": "Bientôt disponible !",
  "new-feed-desc": "Cliquez sur l'une des sources disponibles pour configurer l'import automatique de tous vos produits dans Feedcast !",
  "previous-step": "Étape précédente",
  "no-tutorial": "Pas de tutoriel",
  "no-tutorial-desc": "Il n'y a pas encore de tutoriel pour cette source. N'hésitez pas à demander de l'aide sur le chat !",
  "download-csv": "Télécharger au format CSV",
  "create-dynamic-label": "Créer un label dynamique",
  "product-state": "État du produit",
  "all-states": "Tous les états",
  "edit-project": "Éditer projet",
  "no-accounts-linked": "Aucun compte publicitaire connecté",
  "edit-project-success": "Le projet a bien été modifié!",
  "create-ads-account-company": "Créer un compte {company} Ads",
  "identifier": "Identifiant",
  "currency": "Devise",
  "feed-original": "Flux produit original",
  "feed-feedcast": "Flux produit Feedcast",
  "link": "Lien",
  "gtin": "GTIN",
  "description": "Description",
  "title": "Titre",
  "no-product-data": "Aucune donnée(s) !",
  "no-product-data-desc": "Nous n'avons aucune donnée(s) concernant l'état de votre catalogue Google.",
  "pending": "En attente",
  "FLAG_PENDING": "Validation",
  "FLAG_ERROR": "Erreur(s)",
  "FLAG_ONLINE": "Valide",
  "FLAG_WARNING": "Avertissement(s)",
  "FLAG_ERROR_WARNING": "Erreur(s) & Avertissement(s)",
  "FLAG_ONLINE_WARNING": "En ligne & Avertissement(s)",
  "fill-with-infos": "Préremplir avec mes informations de facturation",
  "edit-multiple-products": "Édition multiple",
  "edit-multiple-products-desc": "Vous pouvez éditer des propriétés communes simultanément ! ⤵️",
  "close": "Fermer",
  "select-google-category": "Choisissez une catégorie Google pour les produits sélectionnés",
  "success-edit-multiple-products": "Vos produits ont bien été modifiés.",
  "chars-left": "car. restants",
  "validating": "En cours de validation",
  "google-invoice-info": "Informations de facturation",
  "next-step": "Étape suivante",
  "assets-group-name": "Nom du Groupe d'assets",
  "go-to-v2": "Ancienne version",
  "trial-period": "Période d'essai",
  "active-subscription": "Abonnement actif",
  "canceled-subscription": "Abonnement annulé",
  "sub-status": "État de l'abonnement",
  "next-invoice": "Prochaine facturation",
  "current-price": "Montant actuel",
  "date-end-sub": "Date fin d'abonnement",
  "sub-canceled-desc": "Vous avez annulé le renouvellement de votre abonnement. Pour l'instant, il n'est pas possible de relancer un abonnement annulé.",
  "disabled-campaign-creation-google": "Vous devez d'abord activer votre Merchant Center avant de pouvoir créer une campagne Google.",
  "paid": "Payé",
  "open": "Ouvert",
  "void": "Inconnu",
  "invoices": "Factures",
  "date-creation": "Date de création",
  "amount": "Montant",
  "website": "Site",
  "start-subscription": "Début abonnement",
  "auto-renew": "Auto-renouvellement",
  "catalog": "Votre catalogue produit Meta",
  "catalog-desc": "Configurez ici votre catalogue produit associé à Meta. Si le catalogue n'existe pas encore, il vous suffit de choisir un Pixel à lier avec votre catalogue produit !",
  "select-pixel": "Sélectionnez un Pixel Meta dans la liste",
  "search-for-pixel": "Rechercher un pixel...",
  "catalog-name": "Nom de votre catalogue produit Meta",
  "config-pixel-meta": "Configurez votre catalogue produit !",
  "config-pixel-meta-desc": "Une fois configuré, votre flux produit Feedcast sera lié à votre Pixel et vous pourrez diffuser des campagnes Meta ⤵️",
  "config-merchant": "Configurez votre nouveau compte Merchant Center !",
  "config-merchant-desc": "Nous vous avons créé un tout nouveau compte. Utilisez la vérification par sms pour pouvoir diffuser des campagnes Google ⤵️",
  "create-catalog": "Configuration",
  "connected-accounts": "Comptes connectés",
  "success-meta-catalog": "Succès de la création de votre catalogue produit Meta!",
  "building-view": "Vue en construction !",
  "building-view-desc": "Cette vue est actuellement en construction.",
  "meta-classic": "Meta Classique",
  "meta-classic-desc": "Diffusion sur l'ensemble des plateformes Meta. Choisissez votre canal, évènements et cible pour vos campagnes.",
  "meta-campaign-type": "Type de campagne Meta",
  "meta-campaign-type-desc": "Retargeting ou Prospection ?",
  "number-of-days": "Nombre de jour depuis",
  "meta-page": "Page Meta",
  "meta-page-desc": "Liez votre page Meta avec votre site web!",
  "select-a-page": "Sélectionnez l'une de vos pages...",
  "meta-global": "Paramètres généraux",
  "meta-global-desc": "Indiquez ici les paramètres généraux ",
  "campaign-name": "Nom campagne",
  "adset-group-name": "Nom du groupe d'adset",
  "age-min": "Âge min.",
  "age-mix": "Âge max.",
  "AddToCart": "Ajouté au panier",
  "ViewContent": "Vu mon contenu",
  "Purchase": "Acheté",
  "begin-feedcast-diffusion": "Pour commencer à diffuser vos produits via Feedcast, choisissez les canaux sur lesquels vous voulez être visible !",
  "products-google": "Détail produits",
  "active-with-warnings": "Actif (avertissements)",
  "all-campaigns": "Toutes mes campagnes",
  "imported-products": "{nb} produits importés",
  "fr_FR": "France / FR",
  "en_GB": "Royaume Uni / GB",
  "fr_BE": "France & Belgique / FR + BE",
  "fr_LU": "France & Luxembourg / FR + LU",
  "fr_CA": "France & Canada / FR + CA",
  "fr_CH": "France & Suisse / FR + CH",
  "it_CH": "Italie & Suisse / FR + IT",
  "de_CH": "Allemagne & Suisse / DE + CH",
  "it_IT": "Italie / IT",
  "de_DE": "Allemagne / DE",
  "es_ES": "Espagne / ES",
  "en_US": "UK & USA / US",
  "nl_BE": "Pays-bas & Belgique / NL + BE",
  "nl_NL": "Pays-bas . NL",
  "se_SE": "Suède / SE",
  "pt_PT": "Portugal / PT",
  "es_MX": "Espagne & Mexique / ES + MX",
  "no_NO": "Norvège / NO",
  "en_AU": "Royaume Uni & Australie / EN + AU",
  "pl_PL": "Pologne / PL",
  "ae_AE": "Émirats Arabes Unis / AR",
  "sa_SA": "Arabie Saoudite / AR",
  "custom-labels-desc": "Les labels personnalisés vous permettent de créer des groupes de produits et segmenter vos campagnes.",
  "custom-labels-desc-2": "Vous pouvez choisir de grouper vos produits par marque, par état, par référence, ou une combinaison de ces 3 derniers.",
  "custom-labels-desc-3": "Vous pourrez choisir de restreindre une campagne sur le(s) groupe(s) de produits choisis, ou au contraire de les en exclure.",
  "my-custom-labels": "Mes labels personnalisés",
  "project": "Projet",
  "products-pending": "En validation",
  "no-campaigns": "Vous n'avez aucune campagne !",
  // "create-campaign":"Créer une campagne",
  "no-oauth": "Aucun compte connecté !",
  "add-oauth": "Lier mes comptes",
  "no-create-campaigns": "Configurez vos services !",
  "configure-services": "Configurer",
  "create-campaigns": "Créer une campagne",
  "active-campaigns": "Campagnes actives!",
  "diffuse-from": "Diffusez vos campagnes produits à travers tout le réseau {company}",
  "disabled-campaign-creation-meta": "Configurez votre Catalogue Meta avant de pouvoir créer une campagne.",
  "UNSPECIFIED": "Non spécifié",
  "UNKOWN": "Non connu",
  "CAMPAIGN_REMOVED": "La campagne a été supprimée,",
  "CAMPAIGN_PAUSED": "La campagne est en pause.",
  "CAMPAIGN_PENDING": "La campagne est prévue se lancer plus tard.",
  "CAMPAIGN_ENDED": "La campagne est finie.",
  "CAMPAIGN_DRAFT": "La campagne est en brouillon.",
  "BIDDING_STRATEGY_MISCONFIGURED": "Mauvaise configuration de la stratégie d'enchères.",
  "BIDDING_STRATEGY_LIMITED": "La stratégie d'enchères est limitée par un manque de données.",
  "BIDDING_STRATEGY_LEARNING": "La stratégie d'enchère automatique est en train de s'ajuster.",
  "BIDDING_STRATEGY_CONSTRAINED": "La campagne pourrait convertir plus en ajustant le CPA/ROAS cible",
  "BUDGET_CONSTRAINED": "Le budget limite la capacité de la campagne",
  "BUDGET_MISCONFIGURED": "Le budget n'est pas bien configuré",
  "SEARCH_VOLUME_LIMITED": "La campagne ne cible pas toutes les requêtes.",
  "AD_GROUPS_PAUSED": "Le groupe d'annonces est en pause.",
  "NO_AD_GROUPS": "Aucun groupe d'annonces.",
  "KEYWORDS_PAUSED": "Les mots clés sont en pause.",
  "NO_KEYWORDS": "Aucun mot clé.",
  "AD_GROUP_ADS_PAUSED": "Le groupe d'annonces et status sont en pause.",
  "NO_AD_GROUP_ADS": "Aucun groupe d'annonces.",
  "HAS_ADS_LIMITED_BY_POLICY": "Au moins une ad dans cette campagne est limitée par policy.",
  "HAS_ADS_DISAPPROVED": "Au moins une ad dans cette camapgne est désaprouvée.",
  "MOST_ADS_UNDER_REVIEW": "La plupart des ads sont en review.",
  "MISSING_LEAD_FORM_EXTENSION": "The campaign has a lead form goal, and the lead form extension is missing.",
  "MISSING_CALL_EXTENSION": "The campaign has a call goal, and the call extension is missing.",
  "LEAD_FORM_EXTENSION_UNDER_REVIEW": "The lead form extension is under review.",
  "LEAD_FORM_EXTENSION_DISAPPROVED": "The lead extension is disapproved.",
  "CALL_EXTENSION_UNDER_REVIEW": "The call extension is under review.",
  "CALL_EXTENSION_DISAPPROVED": "The call extension is disapproved.",
  "NO_MOBILE_APPLICATION_AD_GROUP_CRITERIA": "No eligible mobile application ad group criteria exist in this campaign.",
  "CAMPAIGN_GROUP_PAUSED": "The user-specified campaign group status is paused.",
  "CAMPAIGN_GROUP_ALL_GROUP_BUDGETS_ENDED": "The user-specified times of all group budgets associated with the parent campaign group has passed.",
  "APP_NOT_RELEASED": "The app associated with this ACi campaign is not released in the target countries of the campaign.",
  "APP_PARTIALLY_RELEASED": "The app associated with this ACi campaign is partially released in the target countries of the campaign.",
  "need-subscription": "Feedcast Autopilot",
  "need-subscription-desc": "Vous devez vous abonner pour activer votre campagne ! ",
  "products-success": "Diffusés",
  "products-warning": "Avertissements",
  "global-view-data": "Vue d'ensemble de vos campagnes",
  "global-view-data-desc": "Connectez vos comptes et diffusez des campagnes sur vos produits !",
  "assets": "Assets",
  "product_type": "Type de produit",
  "add-marketing-images-portrait": "Ajouter des images marketing (portrait)",
  "es_PA": "Espagne & Panama / ES + PA",
  "da_DK": "Danemark / DK",
  "nb-products-selected": "{nb} produit(s) sélectionné(s)",
  "config-merchant-address": "Configurez vos informations Merchant Center !",
  "config-merchant-address-desc": "Plus qu'une étape ! Renseignez les informations de votre entreprise afin d'activer votre tout nouveau Merchant Center ⤵️",
  "add-shipping": "Ajouter un frais de livraison",
  "know-more-about-projects": "En savoir plus sur la création de projets",
  "discover-performances-global-view": "Découvrez notre article sur la présentation de la vue d'ensemble des performances",
  "more-about-products-performances": "En savoir plus sur l'analyse des performances de ses produits sur Google Shopping",
  "edit-feed": "Éditer ma boutique",
  "how-to-connect-feed": "Découvrez notre article pour connecter un catalogue produits",
  "help-ads-account": "Découvrez comment créer ou connecter un compte",
  "help-csv": "Indiquez le lien de votre catalogue produit hébergé au format XML, CSV ou TXT",
  "need-help": "Besoin d'aide ?",
  "help-link-csv": "Découvrez notre tutoriel pour vous aider à connecter votre catalogue produit",
  "help-sheets": "Indiquez le lien de votre catalogue produit hébergé sur Google Sheet",
  "starter": "Autopilot",
  "premium": "Premium",
  "subscription-edit-success": "Succès de l'édition de votre abonnement !",
  "access-platform": "Accès à la plateforme",
  "product-handle": "Gestion des produits",
  "optimize-feed": "Amélioration de titres & descriptions",
  "follow-catalog-status": "Suivi de l'état du catalogue en temps réel",
  "categorisation": "Catégorisation assistée des produits",
  "handle-custom-labels": "Création de labels personnalisés",
  "feed-errors": "Détection des erreurs de flux",
  "feed-correction": "Correction automatique des erreurs de flux",
  "personalized-reco": "Recommandations personnalisées",
  "global-view-performances": "Vue d'ensemble des performances publicitaires",
  "reports-csv": "Rapports détaillés & exports CSV",
  "dashboard-checklist": "Tableau de bord et checklist personnelle",
  "performances-score": "Score de performances",
  "google-listing-free": "Affichez gratuitement vos produits sur Google Shopping",
  "free-feedcast-shopping": "Affichez gratuitement vos produits sur Feedcast.shopping",
  "google-campaigns": "Lancez des campagnes payantes sur Google",
  "meta-campaigns": "Lancez des campagnes payantes sur Meta",
  "amazon-campaigns": "Lancez des campagnes payantes sur Amazon",
  "microsoft-campaigns": "Lancez des campagnes payantes sur Microsoft",
  "chatbot": "Chatbot",
  "access-academy": "Accès à la base de connaissances (Académie)",
  "chat-support": "Support par chat en semaine de 10h à 18h",
  "phone-support": "Support téléphonique sur RDV",
  "campaign-setup": "Setup de vos campagnes sur-mesure",
  "new-functions": "Accès prioritaire aux nouvelles fonctionnalités",
  "subscription-cancel-success": "Votre abonnement a bien été annulé.",
  "fill-invoice-info": "Renseignez vos informations de facturation !",
  "fill-invoice-info-desc": "Afin de pouvoir activer un abonnement sur l'un de vos flux, nous avons besoin de vos informations de facturation.",
  "selection": "Sélection",
  "missing-value-description": "Description du produit manquante",
  "missing-value-description-todo": "Ajoutez une description afin d'améliorer la qualité des données de votre produit",
  "low-image-quality": "Image de faible qualité",
  "low-image-quality-todo": "Ajoutez une image de meilleure qualité",
  "invalid-value-availability": "Valeur incorrecte : Disponibilité",
  "invalid-value-availability-todo": "invalid-value-availability-todo",
  "text-short-description": "Description trop courte",
  "text-short-description-todo": "text-short-description-todo",
  "desktop-page-robots": "Impossible d'explorer la page pour ordinateur en raison du fichier robots.txt",
  "desktop-page-robots-todo": "Mettez à jour votre fichier robots.txt pour autoriser les user-agents \"Googlebot\" et \"Googlebot-Image\" à explorer votre site",
  "mobile-page-robots": "Impossible d'explorer la page pour mobile en raison du fichier robots.txt",
  "mobile-page-robots-todo": "Mettez à jour votre fichier robots.txt pour autoriser les user-agents \"Googlebot\" et \"Googlebot-Image\" à explorer votre site",
  "invalid-value-category-price": "Valeur non valide : prix catégorie",
  "invalid-value-category-price-todo": "invalid-value-category-price-todo",
  "item-updates-price": "Mise à jour automatique des prix actuels.",
  "item-updates-price-todo": "Aucune action n’est requise de votre part",
  "invalid-value-gtin": "Valeur non valide : code GTIN. ",
  "invalid-value-gtin-todo": "Ajoutez le code GTIN valide dans le monde entier correspondant à votre produit",
  "incorrect-language": "Language incorrect",
  "incorrect-language-todo": "Indiquez une langue de contenu acceptée dans le pays cible.",
  "missing-value-image": "Image du produit manquant.",
  "missing-value-image-todo": "Ajoutez une image afin d'améliorer la qualité des données de votre produit.",
  "incorrect-value-identifier-exists": "Valeur incorrecte : identifiant existe.",
  "incorrect-value-identifier-exists-todo": "Cet article dispose d'un identifiant international unique. Envoyez un code GTIN valide",
  "violation-google-policies": "Refus d'articles pour non-respect du règlement",
  "violation-google-policies-todo": "Corrigez vos articles pour respecter le règlement Google",
  "missing-pricing-measure": "Mesure de prix unitaire manquante",
  "missing-pricing-measure-todo": "Ajoutez l'attribut mesure de prix unitaire [unit_pricing_measure] afin de respecter les réglementations en vigueur dans ce pays.",
  "limited-performance-gtin": "Performances limitées car GTIN manquant.",
  "limited-performances-gtin-todo": "Ajoutez votre code GTIN",
  "item-updates-availability": "Mise à jour automatique par Google des articles disponibles.",
  "item-updates-availability-todo": "Aucune action n’est requise de votre part",
  "unavailable-desktop": "Page de destination pour ordinateur non disponible",
  "unavailable-desktop-todo": "Ajoutez une page de redirection pour ordinateur valide",
  "promotional-overlay-image": "Superposition d'image promotionnelle (lien de l’image)",
  "promotional-overlay-image-todo": "Ajoutez une image valide sans élément de superposition",
  "excessive-capitalisation-title": "Utilisation abusive des majuscules dans vos titres",
  "excessive-capitalisation-title-todo": "Suppriez les majuscules excessives",
  "limited-performance-identifier-gtin-mpn-brand": "Performances limitées en raison d'identifiants manquants : code GTIN [gtin], référence fabricant [mpn], marque [brand]",
  "limited-performance-identifier-gtin-mpn-brand-todo": "Ajoutez une marque, et un code GTIN ou une référence fabricant",
  "missing-value-size": "Valeur manquante : taille",
  "missing-value-size-todo": "Ajoutez la taille à vos produits si cela est adapté à celui-ci",
  "missing-value-color": "Valeur manquante : couleur",
  "missing-value-color-todo": "Ajoutez la couleur à vos produits si cela est adapté à celui-ci",
  "missing-value-gender": "Valeur manquante: genre",
  "missing-value-gender-todo": "Ajoutez le genre à vos produits si cela est adapté à celui-ci",
  "missing-value-age-group": "Valeur manquante : tranche d’âge",
  "missing-value-age-group-todo": "Ajoutez la tranche d’âge à vos produits si cela est adapté à celui-ci",
  "missing-energy-efficiency": "Attribut de classe énergétique manquant. Cet attribut est requis pour certains produits dans les pays où la réglementation le demande.",
  "missing-energy-efficiency-todo": "Ajoutez l'attribut “classe énergétique” de vos appareils.",
  "invalid-characters-description": "Caractères invalides dans votre description",
  "invalid-characters-description-todo": "Veuillez corriger les descriptions des produits concernés",
  "ambiguous-value-gtin": "Code GTIN avec valeur ambiguë",
  "ambiguous-value-gtin-todo": "Indiquez le code GTIN en entier, y compris les zéros au début, ainsi que les codes UPC, EAN, JAN, ISBN-13 ou ITF-14 dans leur intégralité.",
  "unsupported-value-gtin": "Code GTIN : Valeur non acceptée en raison d'un préfixe interdit",
  "unsupported-value-gtin-todo": "Indiquez un code GTIN non compris dans la plage restreinte (préfixes : 02, 04 ou 2).",
  "invalid-value-ads-redirect": "Redirection des annonces : valeur incorrecte",
  "invalid-value-ads-redirect-todo": "Utilisez le bon attribut de redirection des annonces [ads_redirect] pour spécifier des paramètres de page de destination supplémentaires sur la page du produit que vous souhaitez utiliser pour vos annonces Shopping",
  "missing-category-category": "Description du produit manquante.",
  "missing-category-category-todo": "Ajoutez une description afin d’améliorer la qualité des données de votre produit.",
  "audit-feed": "Audit du flux produits",
  "first-call": "Premier appel de configuration offert",
  "dashboard-page-title-desc": "C'est ici que tout se passe !",
  "feed-score": "FeedScore",
  "no-data": "Aucune donnée(s) !",
  "follow-personalized": "Suivi personnalisé",
  "male": "Homme",
  "female": "Femme",
  "unisex": "Unisexe",
  "newborn": "Nouveau-né",
  "infant": "Nourrisson",
  "toddler": "Tout-petit",
  "kids": "Enfant",
  "adult": "Adulte",
  "no-feed-errors": "Aucune erreur de flux.",
  "no-feed-errors-desc": "Feedcast n'a détecté aucune erreur dans votre flux produit.",
  "no-feed-errors-desc-2": "Lorsque des erreurs apparaîtront, vous pourrez les corriger en masse directement depuis cet espace, sans même avoir besoin de passer sur chacune de vos fiches produits !",
  "success-free-listing": "Succès ! Votre flux produit va être diffusé gratuitement sur les fiches Google Shopping.",
  "success-feedcast-comparator": "Succès ! Votre flux produit va être diffusé gratuitement sur nos fiches Feedcast.shopping.",
  "config-merchant-info": "Votre compte est vérifié ! Plus qu'une étape...",
  "config-merchant-info-desc": "Renseignez les informations de facuration de votre nouveau compte Merchant Center afin de pouvoir finaliser l'activation de votre compte. Après ça, vous recevrez une notification de la part de Google dans vos emails ! ",
  "config-merchant-phone": "Activez votre nouveau Merchant Center !",
  "config-merchant-phone-desc": "Nous vous avons créé un tout nouveau compte Merchant Center. Utilisez la vérification par sms pour pouvoir diffuser des campagnes Google ⤵️",
  "edit-merchant-info": "Édition de vos informations Merchant Center",
  "edit-merchant-info-desc": "Éditez vos coordonnées et renseignez vos frais de livraison, on s'occupe du reste !",
  "link-ads-account-desc": "Choisissez un compte publicitaire dans la liste ci-dessous pour y lier votre boutique Feedcast.",
  "isUser": "Créer un compte Feedcast",
  "isUser-desc": "Spoiler alert : c’était le plus facile.",
  "hasShop": "Créer votre 1ère boutique",
  "hasShop-desc": "Spoiler alert : c’était pas très compliqué non plus.",
  "hasAccount": "Ajouter un compte publicitaire",
  "hasAccount-desc": "Connectez le votre ou créez un nouveau compte avec nous.",
  "hasProducts": "Importer vos produits",
  "hasProducts-desc": "Via un flux classique, ou depuis votre boutique.",
  "hasValidFeed": "Obtenir un flux produits validé",
  "hasValidFeed-desc": "Indispensable pour commencer à diffuser vos campagnes.",
  "hasFreeDiffusion": "Activer la diffusion gratuite",
  "hasFreeDiffusion-desc": "Le début de l'aventure ! On diffuse gratuitement vos produits !",
  "hasCampaign": "Lancer votre 1ère campagne",
  "hasCampaign-desc": "Testons maintenant la vraie diffusion de vos produits.",
  "hasDiffused": "Diffuser pendant 14 jours",
  "hasDiffused-desc": "Accumulez de la data, et allons chercher vos 1ères ventes.",
  "beginner": "Débutant",
  "confirmed": "Confirmé",
  "LOGIN": "Connexion",
  "feed_update": "Mise à jour de la boutique",
  "campaign_update": "Mise à jour campagne",
  "subscription_update": "Mise à jour abonnement",
  "campaign_create": "Création campagne",
  "square": "Carré",
  "portrait": "Portrait",
  "landscape": "Paysage",
  "unknown": "Inconnu",

  "error-oauth": "Un problème est survenu lors de la liaison de votre compte. Veuillez ré-essayer ultérieurement.",
  "subscription-restricted": "Lancez votre plan Feedcast !",
  "subscription-restricted-desc": "Démarrez votre abonnement Feedcast pour pouvoir créer des campagnes.",
  "configuration-call": "Appel de configuration",
  "sub-trial-unlocked": "30 jours d'essai Autopilot",
  "hasConfig": "Configurer un canal de diffusion",
  "hasConfig-desc": "Terminez la configuration d'au moins un canal (Google ou Meta)",

  // --------
  // "missing-age-group": "Ajoutez cet attribut à vos données produit.",
  // "missing-availability": "Ajoutez cet attribut à vos données produit.",
  // "missing-availability-date": "Ajoutez une valeur conforme aux spécifications des données produit pour cet attribut.",


  "missing_item_attribute_for_product_type": "Attribut manquant: {attribute}",
  "missing_item_attribute_for_product_type-desc": "Renseignez l'attribut {attribute} dans vos fiches produits.",

  "item_missing_required_attribute": "Attribut requis manquant: {attribute}",
  "missing_related_attribute": "Attribut manquant: {attribute}",
  "apparel_missing_brand": "Performances limitées en raison de la marque manquante",
  "apparel_missing_brand-desc": "Vous devez indiquer une marque valide pour tous les produits de la catégorie Vêtements et accessoires.",
  "dataquality_invalid_attribute_value": "Qualité: valeur invalide",
  "item_group_by_gtin_with_conflicting_attributes": "Valeurs en conflit (GTIN, marque)",

  "google_category_unrecognized": "Catégorie Google non reconnue",
  "google_category_unrecognized-desc": "La catégorie Google n'a pas été reconnue. Choisissez une catégorie dans la liste à l'aide de l'autocomplétion.",

  "coupon_gtin": "",

  "css_merchant_opt_out": "CSS non sélectionné pour cette destination",
  "image_link_broken": "Lien de l'image invalide",

  "url_does_not_match_homepage": "Les domaines ne correspondent pas",
  "url_does_not_match_homepage-desc": "Utilisez des URL de pages de destination ayant le même domaine que le site Web défini dans votre compte Merchant Center",

  "policy_violation": "Non respect du règlement",
  "policy_violation-desc": "Votre compte a été suspendu pour non respect du règlement.",

  "recalled_product": "Produit rappelé",
  "recalled-product-desc": "Suivez le processus de rappel mis en place par le fabricant ou l'autorité administrative.",

  "preemptive_item_disapproval_price_untrusted": "Prix inexact en raison d'incohérences entre le flux et la page de destination",
  "preemptive_item_disapproval_price_untrusted-desc": "Votre compte a reçu un avertissement, ou vos produits ont été refusés par mesure de précaution en raison de cette erreur.",

  "pending_initial_policy_review_shopping_ads": "En attente de la validation initiale",
  "pending_initial_policy_review_shopping_ads-desc": "Veuillez attendre jusqu'à 3 jours ouvrés pour que votre catalogue soit inspecté.",

  "shipping_weight": "Poids du produit",

  "missing_shipping_weight": "Valeur manquante : poids de livraison",
  "missing_shipping_weight-desc": "Pour pouvoir calculer le prix de la livraison, renseignez cette valeur à votre produit.",

  "missing_shipping": "Valeur manquante : frais de ports",
  "missing_shipping-desc": "Renseignez les frais de ports de vos produits.",

  "missing_required_gtin": "Performances limitées par GTIN manquant",
  "missing_required_gtin-desc": "Ajoutez le code GTIN valide dans le monde entier correspondant à vos produits.",

  "image_too_small": "Image trop petite",
  "image_too_small-desc": "Fournissez une image d'au moins 100 x 100 pixels (produits non vestimentaires) ou 250 x 250 pixels (produits vestimentaires).",

  "image_single_color": "Image d'une seule couleur",
  "image_single_color-desc": "Les images monochromes ne peuvent être utilisées que pour certains types de produits.",

  "image_link_pending_crawl": "Image non récupérée (vitesse d'exploration trop faible / exploration en attente)",
  "image_link_pending_crawl-desc": "Assurez-vous que Google peut accéder à vos images, et attendez que l'image du produit soit explorée (cela peut prendre jusqu'à trois jours).",

  "incorrect_identifier": "Identifiant GTIN incorrect",
  "incorrect_identifier-desc": "Utilisez l'identifiant correct pour vos produits",

  "invalid_upc": "UPC non valide",
  "invalid_upc-desc": "Ajoutez le code GTIN valide dans le monde entier correspondant à votre produit.",

  "item_duplicate_by_gtin": "Code produit en double",
  "item_duplicate_by_gtin-desc": "Fournissez un code produit unique pour chaque produit.",

  "image_unwanted_overlays": "Superposition d'image promotionnelle",
  "image_unwanted_overlays-desc": "Modifiez l'image de vos produits",

  "landing_page_crawling_not_allowed": "Impossible d'explorer la page pour ordinateur en raison du fichier robots.txt",
  "landing_page_crawling_not_allowed-desc": "Mettez à jour votre fichier robots.txt pour autoriser les user-agents \"Googlebot\" et \"Googlebot-Image\" à explorer votre site",

  "mobile_landing_page_crawling_not_allowed": "Impossible d'explorer la page mobile en raison du fichier robots.txt",
  "mobile_landing_page_crawling_not_allowed-desc": "Mettez à jour votre fichier robots.txt pour autoriser les user-agents \"Googlebot\" et \"Googlebot-Image\" à explorer votre site",

  "landing_page_error": "Page de destination pour ordinateur non disponible",
  "landing_page_error-desc": "Mettez à jour votre boutique ou l'URL de la landing page pour autoriser l'accès aux ordinateurs.",

  "mobile_landing_page_error": "Page de destination pour mobile non disponible",
  "mobile_landing_page_error-desc": "Mettez à jour votre boutique ou l'URL de la landing page pour autoriser l'accès aux ordinateurs.",

  "mismatching_brand_gtin_prefix": "Code GTIN et marque en conflit",
  "mismatching_brand_gtin_prefix-desc": "Fournissez un code GTIN valide dans le monde entier et associé à la marque que vous soumettez.",

  "price_mismatch": "Valeur incohérente (exploration de page) : prix [price]",
  "price_mismatch-desc": "Modifiez le prix du produit dans vos données produit afin qu'il corresponde à celui affiché sur votre page de destination",

  "reserved_gtin": "GTIN réservé",
  "reserved_gtin-desc": "Modifiez vos codes uniques GTIN.",

  "shipping_weight_too_high": "Poids de l'article trop élevé",
  "shipping_weight_too_high-desc": "Le poids de l'article doit être compris dans les limites imposées par Google.",

  "account_disapproval": "Compte suspendu par soucis de qualité",
  "account_disapproval-desc": "Corrigez les problèmes liés à la qualité de votre compte.",

  "identifier_exists_false_misuse": "L'identifiant GTIN existe déjà",
  "identifier_exists_false_misuse-desc": " Cet article dispose d'un identifiant international unique. Envoyez un code GTIN valide, puis définissez sa valeur sur \"vrai\" ou supprimez cette valeur.",

  "image_decoding_error": "Encodage incorrect d'image",
  "image_decoding_error-desc": "Vérifiez la validité du format d'image et l'exactitude des paramètres du serveur.",

  "image_link_internal_error": "Échec du traitement de l'image",
  "image_link_internal_error-desc": "Attendez que l'image du produit soit de nouveau explorée (trois jours maximum)",

  "media_missing_gtin": "Performances limitées en raison d'identifiants manquants : code GTIN, référence fabricant ou marque",
  "media_missing_gtin-desc": "Ajoutez une marque, et un code GTIN ou une référence fabricant. Si ce produit est unique en son genre ou vintage, vous n'avez pas besoin d'ajouter d'identifiant.",

  "color": "Couleur",


  "create-custom-label-desc": "Créez un label personnalisé sur vos articles pour pouvoir les inclure ou les exclure de vos campagnes Feedcast !",

  "no-categories-to-map": "Aucune description à lier.",
  "no-categories-to-map-desc": "Feedcast n'a détecté aucune de vos catégories personnelles ayant besoin d'être associée une catégorie Google.",

  "excessive-capitalization-title": "Usage excessif de majuscules dans le titre",
  "excessive-capitalization-title-todo": "Utilisez moins de majuscules dans les titres de vos produits.",

  "edit-custom-label-desc": "Modifiez votre label produit.",
  "image_link": "URL",
  "age group": "Tranche d'âge",
  "gender": "Genre(s)",
  "price_out_of_range": "Le prix du produit dépasse la limite",
  "price_out_of_range-desc": "Conformez le prix de vos produits dans la limite.",
  "product-has-identifier": "Ce produit dispose d'un identifiant unique",
  "success-multiple-edit": "Succès de l'édition de vos produits !",
  "size": "Taille",
  "size_k": "Taille",
  "age_group_k": "Tranche d'âge",
  "color_k": "Couleur",
  "gender_k": "Genre",
  "image_link_broken-desc": "Le lien de l'image semble corrompu. Veuillez indiquer une nouvelle image pour vos produits.",
  "campaign-not-feedcast": "Cette campagne est liée à votre compte Ads, mais n'a pas été créé depuis votre boutique Feedcast. Vous pouvez toujours éditer son budget, ou l'activer/désactiver!",
  "campaigns-feedcast": "Campagnes Feedcast",
  "your-shop": "Votre boutique",
  "create-label": "Créer un label dynamique",
  "cancel-selection": "Annuler ma sélection",
  "clear": "Réinitialiser",
  "identifier_exists": "Identifiant unique existe",
  "brand_k": "Marque",
  "google_product_category_k": "Catégorie Google",
  "availability_k": "Disponibilité",
  "condition_k": "État",
  "invalid-password-confirm": "Les mots de passe ne correspondent pas.",
  "error": "Erreur",
  "warning": "Avertissement",
  "step-source": "Sélectionnez votre source",
  "step-credentials": "",
  // "update-subscription": "Mettre à niveau ma boutique",
  "pay-today": "À régler aujourd'hui",
  "amount-next-invoice": "Montant de la prochaine facture",
  "downgrade": "Downgrade",
  "downgrade-desc": "Vous avez choisi un abonnement plus bas que celui actuel. Votre abonnement actuel se poursuivra jusqu'à la fin de la période déjà payée, et vous passerez ensuite sur votre nouvel abonnement.",
  "cancel-sub": "Annulation de votre abonnement",
  "cancel-sub-desc": "Vous allez annuler votre abonnement. Vous conserverez l'abonnement actuel jusqu'à la fin de la période déjà payée, puis vous passerez en plan gratuit.",
  "your-selection": "Votre sélection",
  "trial-30-days": "Tester gratuitement pendant 7 jours",
  "start-sub": "Lancer mon abonnement",
  "edit-sub": "Modifier mon abonnement",
  "cancel-sub-btn": "Annuler mon abonnement",
  "accept-the": "Je certifie avoir lu et accepter les",
  "sales-condition": "conditions générales de ventes",
  "recap": "Récapitulatif",
  "import-method": "Méthode d'import",
  "language-country": "Pays / Langue",
  "shop": "Boutique",
  "create-new-label": "Créer un nouveau label",
  "filters": "Filtres",
  "manual-list": "Ajouter une liste manuelle",
  "copy-past-list": "Copiez-collez votre liste ici...",
  "select-field-to-edit": "Sélectionnez le champ à modifier",
  "aggregation-id": "Références",
  "aggregation-brand": "Marques",
  "aggregation-product_type": "Type de produits",
  "aggregation-label0": "Labels 1",
  "aggregation-label1": "Labels 2",
  "aggregation-label2": "Labels 3",
  "aggregation-label3": "Labels 4",
  "aggregation-label4": "Labels 5",
  "exclude": "Exclure",
  "include": "Inclure",
  "budget-quotidien": "Budget quotidien",
  "asset-group-name": "Nom du groupe d'asset",
  "campaign-draft-delete": "Succès suppression du brouillon",
  "meta-advantage": "Meta Shopping Advantage+",
  "meta-advantage-desc": "Révolutionnez vos campagnes shopping grâce à l'IA ! Meta dévoile une innovation majeure avec Advantage+, des campagnes publicitaires ultra-ciblées pour le e-commerce et le retail. Automatisation, pertinence et efficacité sont au rendez-vous. Profitez de cette technologie avant-gardiste pour booster vos conversions et atteindre vos clients comme jamais auparavant. ",
  "ap-global": "Campagne",
  "facebook-page": "Page Facebook",
  "website-url": "URL boutique ",
  "data": "Données",
  "genre": "Sexe",
  "behaviours": "Comportements",
  "interests": "Centres d'intérêts",
  "last-days": "dernier(s) jour(s)",
  "from-the": "depuis les",
  "facebook": "Facebook",
  "audience_network": "Audience Network",
  "messenger": "Messenger",
  "instagram": "Instagram",
  "unpublished-draft-warning": "Cette campagne a un brouillon non publié. Si votre campagne est active, la configuration utilisée n'est pas celle de votre brouillon.",
  "draft-campaign": "Nouvelle campagne en brouillon",
  "draft-campaign-desc": "Vous avez une nouvelle campagne <b>campaign_type</b> en brouillon. Si vous créez et publiez une nouvelle campagne, ce brouillon sera écrasé.",
  "edit-campaign-draft": "Continuer l'édition de mon brouillon",
  "delete-campaign-draft": "Supprimer mon brouillon",
  "discover": "Découvrez",
  "how-to-campaign": "comment créer une campagne",
  "different-campaign-types": "les différents types de campagne",
  "and": "et",
  "fc-warmup-1": "La campagne Feedcast Warm-up, c’est un tout nouveau concept : elle vous permet de diffuser en quelques clics des annonces intelligentes sur plusieurs canaux (Google & Meta pour l’instant).",
  "fc-warmup-2": "C’est quasi-automatisé ! Feedcast Warm-up est le meilleur type de campagne lorsque vous débutez la promotion d’un shop : plus vous injectez de données (catalogue riche, éléments créatifs de votre marque..) plus l’algorithme aura de marge de manoeuvre pour tester vos annonces sur tous les réseaux, et vous apporter de précieux apprentissages pour optimiser !",
  "available-soon": "Bientôt disponible !",

  "ip-address": "Adresse IP",
  "subscriptions-by-shops": "Vos abonnements par boutique",
  "ap-global-desc": "Configurez le nom, et budget quotidien de votre campagne.",
  "save-draft": "Sauvegarder brouillon",
  "delete-draft": "Supprimer brouillon",
  "no-user": "Vous n'avez pas de compte ?",
  "linking": "Liaison en cours...",
  "canals": "Canaux publicitaires",
  "whats-next": "Que faire ensuite ?",
  "no-custom-label": "Aucun label personnalisé.",
  "filter": "Filtrer",
  "show-columns": "Colonnes affichées",
  "warmup-google-desc": "Diffusez vos campagnes produits à travers tout le réseau Google (Shopping, Search, Display, Discover, YouTube, Google Maps, Gmail...) et les partenaires du réseau.",
  "warmup-meta-desc": "Diffusez vos campagnes produits à travers tout le réseau Meta (Facebook, Instagram) et les partenaires du réseau.",
  "warmup-amazon-desc": "Diffusez vos campagnes produits à travers tout le réseau Amazon & partenaires.",
  "warmup-microsoft-desc": "Diffusez vos campagnes produits à travers tout le réseau Microsoft & partenaires.",
  "diffuse-where": "Sur quel(s) réseau(x) voulez-vous diffuser ?",
  "budget-google": "Budget Google",
  "budget-meta": "Budget Meta",
  "feed_import_end": "Import du catalogue",
  "see-all-notifications": "Voir toutes les notifications",
  "warmup_campaign_status": "Campagne Warmup",
  "success-warmup": "Votre Warmup est active sur le canal {canal} ! Vous pouvez maintenant éditer cette campagne.",
  "feed-import-sync": "Succès syncro de votre catalogue produit",
  "feedcast-trial": "Abonner ma boutique",
  "logo_square": "Carré (logo)",
  "marketing_landscape": "Paysage (marketing)",
  "marketing_square": "Carré (marketing)",
  "notif-imported-products": "Feedcast Daily Import : {nb} produits détectés.",
  "feed-sync-loading": "Synchronisation du catalogue produit aux canaux publicitaires#.",
  "no-notifications": "Aucune nouvelle notification !",
  "global-campaigns": "État général des campagnes",
  "diffuse-google-shopping": "Diffusez gratuitement vos produits sur les fiches gratuites Google Shopping. {br} Le service Google Free Listing <b>est obligatoire pour envoyer votre catalogue en validation</b> auprès de Google.",
  "feedcast-shopping-desc": "Diffusez gratuitement vos produits sur la Marketplace Feedcast.shopping.",
  "diffuse-free": "Diffusion gratuite",
  "success-clear-message": "La notification a bien été supprimée.",
  "account-linked": "Compte connecté",
  "catalog-linked": "Catalogue connecté",
  "link-catalog": "Configurer",
  "merchant-center-information": "Merchant Center",
  "configure-account": "Configurer",
  "verify-account": "Configurer",
  "reward": "Récompense : ",
  "begin-diffusion": "Lorsque vous aurez des campagnes actives, vous pourrez comparer les métriques de leurs performances.",
  "product-feed": "Flux produit",
  "confirm-categories": "Confirmez toutes les catégories automatiquement détectées par Feedcast",
  "affiliate-code": "Code partenaire",
  "feedscore-use": "À quoi correspond mon score ?",
  "feedscore-use-desc": "Votre score correspond à la qualité de votre flux produit. En suivant nos recommandations et nos propositions, améliorez cette note au maximum pour améliorer le rendement de vos campagnes Feedcast !",
  "read-more": "En savoir plus",
  "improve-score": "Améliorer mon score",
  "correct": "Corriger",
  "show-concerned-products": "Afficher les produits concernés",
  "edit": "Modifier",
  "product-waiting-validation": "correction(s) en attente de synchronisation",
  "pending-validation": "En attente de synchronisation",
  "title-from-feed": "Titre (de votre flux)",
  "success-batch-edit": "Correction enregistrée",
  "validate": "Valider",
  "link-my-google-account": "Lier mon compte Google",
  "configure-m-center": "Configurer Merchant Center",
  "link-existing-account": "Lier un compte existant",
  "configure-my-account": "Configurer mon compte",
  "configure-catalog": "Configurer mon catalogue",
  "link-meta": "Lier mon compte Meta",
  "create-new-account": "Créer un nouveau compte",
  "import-my-products": "Importer mes produits",
  "edit-filters": "Modifiez vos filtres de recherche.",
  "once-analyzed": "Une fois analysé par notre système de notation, une note sera attribuée à votre catalogue produit en vue d'améliorer celui-ci.",
  "all-your-projects": "Tous vos projets",
  "price": "Prix",
  "discount-price": "Prix soldé",
  "material": "Matière",
  "age-group": "Tranche d'âge",
  "sizes": "Taille(s)",
  "weight": "Poids",
  "custom-label": "Label personnalisé",
  "last-invoices": "Vos dernières factures",
  "history": "Votre historique",
  "pick-plan": "Choisissez votre formule",

  "free-1": "Un <b>tableau de bord tout en un</b>",
  "free-1-desc": "Suivez vos performances unifiées sur tous les canaux depuis votre tableau de bord : impressions, clics, ventes, chiffres d'affaires.",

  "free-2": "Importez jusqu'à <b>1000 produits</b>",
  "free-2-desc": "Connectez votre catalogue et enrichissez vos données de flux produits. Corrigez vos erreurs de flux, catégorisez les produits facilement.",

  "free-3": "Diffusez sur les <b>canaux gratuits</b>",
  "free-3-desc": "Diffusez jusqu'à 1 000 produits sur les fiches gratuites Google Shopping et sur notre comparateur feedcast.shopping",

  "free-4": "Liez tous vos <b>comptes pubs</b>",
  "free-4-desc": "Créez/connectez vos comptes publicitaires en quelques clics afin de gérer la visibilité de vos produits au même endroit.",

  "free-5": "<b>Centre d'aide</b> & <b>documentation</b>",
  "free-5-desc": "Accédez aux nombreux tutoriels disponibles sur help.feedcast.io pour vous renseigner sur le fonctionnement de Feedcast.",

  "starter-1": "Tout le contenu du <b>plan gratuit</b>",
  "starter-1-desc": "Gardez tous les avantages du plan Gratuit. Le plan Autopilot vous permet de commencer à diffuser des campagnes performance.",

  "starter-2": "Jusqu'à <b>1000 produits</b> + options",
  "starter-2-desc": "Même quantité que dans le plan gratuit, ces produits peuvent être diffusés dans les campagnes d'acquisition & de retargeting.",

  "starter-3": "Diffusez <b>sur 2 canaux</b> + options",
  "starter-3-desc": "Choisissez jusqu'à 2 canaux ou plus et lancez des campagnes pour générer du trafic & des ventes. +25€ par canal supplémentaire.",

  "starter-4": "Personnalisez <b>votre ciblage</b>",
  "starter-4-desc": "Avec les labels dynamiques, personnalisez le ciblage & la diffusion de vos produits pour optimiser vos performances.",

  "starter-5": "<b>Appel config</b> + support standard",
  "starter-5-desc": "Réservez votre appel avec notre équipe pour configurer ensemble votre compte. Support disponible en semaine, de 10h à 18h.",

  "premium-1": "Tout le contenu du <b>plan Autopilot</b>",
  "premium-1-desc": "Gardez tous les avantages du plan Autopilot. Le plan Premium vous donne accès à un expert dédié au suivi de vos performances.",

  "premium-2": "<b>Produits illlimités</b>",
  "premium-2-desc": "Importez tout votre catalogue produits sans vous soucier de la quantité de produits, en tant que Premium vous êtes no-limit !",

  "premium-3": "<b>Canaux publicitaires illimités</b>",
  "premium-3-desc": "Lancez des campagnes sur autant de canaux que vous le souhaitez : Google, Youtube, Facebook, Instagram, Microsoft...",

  "premium-4": "Votre <b>expert dédié</b>",
  "premium-4-desc": "Configuration de vos campagnes sur-mesure. Jusqu'à 3 appels de suivi de performances par mois avec votre expert dédié.",

  "premium-5": "Support <b>Premium prioritaire</b>",
  "premium-5-desc": "Vous obtenez un statut prioritaire sur toutes vos demandes de support, ainsi que l'accès à toutes nos fonctionnalités arrivantes.",

  "actual-plan": "Plan actuel",
  "free-trial": "Essai gratuit de 30 jours",
  "need-more-products": "Besoin de plus de produits ? (facultatif)",
  "optimise-products": "Optimisez vos données produits",
  "monitor-performances": "Monitorez vos performances",
  "diffuse-products": "Diffusez vos produits",
  "be-followed": "Soyez accompagnés",
  "noob": "Niveau débutant",
  "noob-expert": "Niveau débutant et expert",
  "success-edit-product": "Modifications enregistrées",
  "or": "ou",
  "pass-step": "Passer cette étape",
  "presentation": "Présentation de la ",
  "products-global-view": "vue d'ensemble du catalogue produit",
  "google-statuses": "le détail des états d'approbation",
  "find-how": "Découvrez comment",
  "edit-products": "modifier les produits de votre catalogue",
  "and-our": "et nos",
  "feed-advices": "recommandations d'optimisation",
  "connect-catalog-begin": "Connectez un catalogue pour commencer.",
  "no-campaign-filters": "Vous n'avez aucune campagne parmi ces filtres.",
  "french": "Français",
  "english": "Anglais",
  "errors": "Erreurs",
  "batch-attribute-not-handled": "Champ non géré par Feedcast",
  "batch-attribute-not-handled-desc": "La correction de ce champ n'a pas encore été implémentée dans Feedcast. N'hésitez pas à contacter le support via le chat !",
  "load-more": "Charger plus",
  "show-data": "Voir données",
  "pressing-yes-will": "Cliquer sur 'Oui' modifiera le ",
  "are-you-sure": "Êtes-vous sûr ?",
  "pendings": "Validation",
  "identifier-exists": "Identifiant unique",
  "link-existing-google-account": "Je veux lier un de mes comptes publicitaires",
  "dsa-beneficiary": "Bénéficiaire de l'annonce",
  "dsa-payor": "Payeur de l'annonce",
  "will-be-affected": "{products} produits seront affectés par votre modification.",
  "project-use": "Un projet vous permet de regrouper plusieurs boutiques sous une seule entité.",
  "vat_number": "Numéro TVA",
  "feed-score-tour": "Le Feedscore vous donne un aperçu rapide de la qualité de votre flux de produits.",
  "tasks-tour": "Votre liste de tâches vous montre ce que vous devez faire ensuite dans Feedcast.",
  "ads-accounts-dashboard": "Vous pouvez connecter directement vos comptes publicitaires en quelques clics à partir d'ici.",
  "app-nav-tour": "Il s'agit de la navigation de l'application. Vous pouvez l'utiliser pour accéder aux différentes pages de l'application.",
  "page-dashboard-tour": "La page Tableau de bord affiche l'état global de l'état de votre boutique et ce qu'il faut faire ensuite.",
  "page-performances-tour": "La page Performances vous permet de consulter les données de vos campagnes.",
  "page-products-tour": "La page Produits vous permet de modifier, corriger et améliorer votre flux de produits.",
  "page-campaigns-tour": "La page Campagnes vous permet de créer, modifier et surveiller vos campagnes.",
  "project-selector-tour": "Vous pouvez facilement naviguer d'une boutique à une autre en utilisant le sélecteur de boutique.",
  "app-notifications-tour": "Vérifiez facilement les informations importantes avec les notifications de l'application.",
  "app-settings-tour": "Consultez vos paramètres, déconnectez-vous ou changez votre langue.",
  "datepicker-tour": "Définissez une plage de dates pour vos données. Par défaut, les 7 derniers jours sont sélectionnés.",
  "perf-chart-tour": "Comparez facilement les données de vos campagnes avec le graphique de performances.",
  "catalog-status-tour": "Aperçu rapide des statuts de vos produits : validés, en attente, avertissements, erreurs.",
  "devices-distribution-tour": "Répartition des appareils utilisés par les personnes pour consulter vos produits.",
  "accounts-metrics-tour": "Données de campagne résumées de vos différents canaux.",
  "product-reporting-data-tour": "Rapport détaillé de vos produits sur le canal Google.",
  "product-reporting-filter-tour": "Vous pouvez filtrer les résultats en définissant des plages.",
  "campaigns-data-cards-tour": "Affichez rapidement les performances de vos campagnes.",
  "free-diffusion-tour": "Une fois que vous avez rempli les conditions requises, vous pouvez activer la diffusion gratuite de vos produits sur les canaux spécifiés.",
  "ads-accounts-tour": "Aperçu de vos canaux publicitaires et configuration.",
  "project-tour": "Vous pouvez regrouper plusieurs boutiques dans un seul projet.",
  "project-shop-tour": "Vous pouvez accéder à votre boutique en cliquant dessus ou la déplacer vers un autre projet par glisser-déposer.",
  "project-button-tour": "Ajoutez une nouvelle boutique à votre projet ou renommez le projet.",
  "select-products-reporting-tour": "Vous pouvez ensuite sélectionner vos produits et créer des étiquettes personnalisées comme \"Meilleures ventes\" ou \"Meilleurs produits d'été\".",
  "products-data-cards-tour": "Un aperçu rapide de vos produits synchronisés.",
  "no-products-tour": "Il semble que vous n'ayez pas encore de produits. Une fois importés, vous verrez l'historique de l'état de votre catalogue.",
  "products-history-tour": "L'historique de l'état de votre catalogue des 10 derniers jours.",
  "products-doughnut-tour": "Une autre vue de l'état de votre catalogue. Vous pouvez utiliser le navigateur de dates pour le consulter dans votre historique.",
  "product-filters-tour": "Filtrez vos produits avec des mots-clés ou avec leur statut : tous, actifs, en attente, avertissements ou erreurs.",
  "select-products-list": "Vous pouvez ensuite sélectionner vos produits pour modifier plusieurs produits en une seule fois ou les ajouter à une étiquette personnalisée.",
  "edit-product-tour": "Modifiez votre produit pour améliorer ses données ou corriger les avertissements et les erreurs.",
  "product-feed-tour": "Modifiez le nom de votre boutique, l'abonnement ou l'URL de votre flux à partir d'ici.",
  "feed-errors-tour": "Les avertissements et les erreurs de vos produits sont affichés ici et vous permettent soit de sélectionner rapidement lesdits produits, soit de les corriger directement.",
  "product-categories-map-tour": "Par défaut, Feedcast essaie d'associer vos catégories aux catégories Google. Vous pouvez soit confirmer les associations, soit les modifier.",
  "cancel-subscription-confirm": "Êtes-vous certain de vouloir annuler votre abonnement?",
  "confirm-cancel": "Confirmer la suppression",
  "account-name": "Nom du compte publicitaire",
  "new-code": "Nouveau code",
  "ship-value": "Frais #{i}",
  "leave-blank-for-max": "Laisser vide pour prix supérieur",
  "finish-config": "Terminer la configuration",
  "fetching-pixels": "Récupération de vos pixels...",
  "create-first-project": "Créez votre premier projet",
  "setup-credentials": "Configurez votre flux",
  "your-information": "Profil",
  "success-draft": "Votre brouillon a bien été sauvegardé.",
  "published": "Publié",
  "draft": "Brouillon",
  "chars-max": "caractères maximum",
  "publish": "Publier",
  "saved": "Enregistré",
  "inactive-campaign": "Campagne inactive",
  "active-campaign": "Campagne active",
  "inactive-campaign-tooltip": "Vous pouvez activer la diffusion de votre campagne depuis la liste des campagnes",
  "active-campaign-tooltip": "Campagne en cours de diffusion",
  "has-draft-tooltip": "Cette campagne est au format brouillon sur votre ordinateur. Cliquez sur \"Enregistrer\" pour sauvegarder vos modifications. ",
  "saved-tooltip": "Cette campagne est enregistrée sur le serveur.",
  "one-shipping-minimum": "Aucun frais de livraison lié à votre Merchant Center.",
  "m-center-desc": "Informations de Facturation et Frais de Livraison",
  "merchant-cache": "Les services de Google sont actuellement en révision, et vos informations mettront environ 5 minutes à se mettre à jour.",
  "missing-configuration": "Pour créer ce type de campagne, activez le canal publicitaire correspondant sur la page Configuration.",
  "subscribe": "Abonnez-vous !",
  "success-free-listing-inactive": "Votre flux produit a bien été retiré du Google Free Listing.",
  "success-feedcast-comparator-inactive": "Votre flux produit a bien été retiré du comparateur Feedcast Shopping. Attention, cela peut prendre jusqu'à 2 jours pour que vos produits soient entièrement retirés.",
  "google-listing-requirements": "Pour activer le Google Free Listing, vous devez terminer la configuration votre compte Google Ads, avoir un flux produit valide et avoir au moins un produit dans Feedcast.",
  "feedcast-shopping-requirements": "Pour activer la diffusion sur la Marketplace Feedcast Shopping, votre flux produit doit être valide et avoir au moins un produit dans Feedcast.",
  "image-format": "Format de l'image",
  "supported-formats": "Formats supportés : JPEG, PNG",
  "missing": "Il vous manque une image au format : ",
  "back": "Retour",
  "config-call": "Appel de configuration",
  "newfeed-csv-tuto-1": "Pour utiliser ce format, vous devez déjà disposer d’un fichier hébergé sur une URL.",
  "newfeed-csv-tuto-2": "Une fois que vous avez créé ou récupéré votre flux produits, vous n'avez plus qu'à l'ajouter à votre compte Feedcast.",
  "newfeed-csv-tuto-3": "Les formats acceptés sont les suivants : CSV, XML, TXT",
  "newfeed-csv-tuto-4": "Attention : Pensez également à mettre à jour votre catalogue en cas de changements (Prix. état du stock, ajout des nouveaux produits etc.). Cela peut avoir un impact sur la validation de votre catalogue produits et la performance des vos campagnes.",
  "more-info": "Retrouvez plus d'informations sur notre",
  "help-center": "centre d'aide",
  "newfeed-sheets-tuto-1": "Pour utiliser ce format, vous devez déjà disposer d’un fichier Google Sheet contenant les informations relatives à vos produits avec le bon formatage des données.",
  "newfeed-sheets-tuto-2": "Si vous n’en avez pas, voici un exemple de document Google Sheet duplicable: ",
  "google-support": "support Google",
  "newfeed-sheets-tuto-3": "Ce format de fichier est conseillé pour les catalogues avec peu de produits et sur lesquels les mises à jour (prix, stock, nouveaux produits..) sont peu fréquentes. ",
  "newfeed-sheets-tuto-4": "Pensez également à mettre à jour votre catalogue en cas de changements (Prix. état du stock, ajout des nouveaux produits etc.). Cela peut avoir un impact sur la validation de votre catalogue produits et la performance des vos campagnes.",
  "more-info-google": "Vous retrouverez également plus d’informations sur le",
  "dash": "Dashboard",
  "start-on-feedcast": "Bien débuter sur Feedcast",
  "feed-onboarding": "Connecte ton flux produit 🛍️",
  "feed-onboarding-desc": "Le flux produit, c'est la base. Une fois connecté, ta boutique sera synchronisée avec Feedcast et tu pourras commencer à utiliser les outils pour améliorer la qualité de ton flux !",
  "ads-onboarding": "Ajoute un compte Ads ⚡️",
  "ads-onboarding-desc": "Tu peux facilement relier un compte de publicité existant, ou même en créer un nouveau avec nous et profiter de 20% de réduction grâce à notre statut CSS Google ! ",
  "free-onboarding": "Active la diffusion gratuite 📈",
  "free-onboarding-desc": "Tu peux déjà diffuser gratuitement tes produits sur le Google Listing, et aussi sur notre comparateur en ligne Feedcast Shopping !",
  "campaign-onboarding": "Lance ta première campagne 🚀",
  "campaign-onboarding-desc": "Prêt à booster tes ventes ? Lance ta première campagne sur Feedcast !",
  "dialog-feed-onboarding": "Ajout de ton catalogue produit 🛍️",
  "import-products-pending": "Vos produits sont en cours d'import...",
  "ads-configured": "Canal configuré",
  "import-feed-loading": "Import de votre catalogue en cours...",
  "feed-parameters": "Paramètres de flux",
  "add-source": "Pour débloquer cette fonctionnalité, ajoutez un flux produit !",
  "delete-label": "Supprimer label",
  "edit-product-feed": "Modifier mon flux produit",
  "link-product-feed": "Connecter mon flux produit",
  "diffuse-my-products": "Diffuser mes produits",
  "launch-campaign": "Lancer ma campagne",
  "unlock-trial": "Version d'essai débloquée !",
  "unlock-configuration-call": "Appel de configuration débloqué !",
  "unlock-trial-todo": "Débloque ta version d'essai !",
  "unlock-configuration-call-todo": "Débloque ton appel de configuration !",
  "free-desc": "Tout ce qu'il vous faut pour diffuser gratuitement et améliorer votre flux.",
  "starter-desc": "Ça devient sérieux : des outils pour diffuser vos produits à grande échelle.",
  "premium-desc": "Soyez accompagnés à chaque moment de votre aventure Feedcast !",
  "new-plan": "Nouveau",
  "sorry-to-see-you-go": "Nous sommes désolés de vous voir partir 😟",
  "help-us-improve": "Avant de se dire au revoir, pourriez-vous nous dire pourquoi ?",
  "CANCEL-HARD_TO_USE": "Trop compliqué à utiliser",
  "CANCEL-NOT_ENOUGH_TIME": "Je n'ai pas le temps de m'en occuper",
  "CANCEL-INVALID_CATALOG": "Catalogue non validé",
  "CANCEL-PERF_ISSUES": "Par manque de performances / ROI",
  "CANCEL-UNKNOWN": "Autre chose (préciser)",
  "where-do-you-fail": "Où est-ce que ça coince ?",
  "consider-premium": "Avez-vous envisagé l'offre premium ?",
  "do-you-diffuse": "Est-ce que vous diffusez depuis + de 30 jours ?",
  "precise": "Préciser :",
  "asset-group-name-helper": "Le groupe d'asset est l'ensemble des titres, descriptions et images lié à votre campagne. ",
  "perfmax-pictures": "Images de votre campagne",
  "caution-cancel": "Attention !",
  "caution-cancel-desc": "Si vous annulez votre abonnement, toutes vos campagnes et les données qu'elles contiennent seront définitivement supprimées.",
  "caution-cancel-desc-2": "Êtes-vous certain de vouloir annuler votre abonnement ?",
  "x-campaigns": "En confirmant votre désabonnement, vos {nb} campagne(s) seront supprimées.",
  "please-explain": "Expliquez nous...",
  "yes-cancel": "Oui, annuler mon abonnement",
  "cancel-sub-1": "Attention : suspendre votre abonnement entrainera la suppression de toutes les campagnes sur l'ensemble des leviers utilisés via Feedcast. Vous perdrez l'historique, les données d'apprentissage, l'avancement et les données de vos campagnes sans possibilité de les récupérer.",
  "cancel-sub-2": "Si vous avez créé un compte Google Ads via Feedcast, ce compte reste votre propriété. En revanche, vous perdrez l'accès au compte Google Merchant Center, celui-ci étant rattaché au CSS Feedcast.",
  "talk-to-expert": "En parler à un expert",
  "about-to-cancel": "Vous êtes sur le point de vous désabonner",
  "scheduled-edits": "Modifications prévues",
  "next-edits": "Les modifications suivantes entreront en vigueur à partir de la prochaine facturation :",
  "cancel-edits": "Supprimer brouillon",
  "cancel-sub-will-do": "Annuler votre abonnement entraînera : ",
  "logout-feedcast-feed": "La déconnexion du flux optimisé par Feedcast",
  "campaigns-deleted": "La suppression de vos campagnes sur l'ensemble des leviers (Google, Facebook, Instagram, Microsoft...)",
  "campaigns-historic-deleted": "La perte de l'historique lié à vos campagnes",
  "css-advantages-loss": "La perte des avantages Google CSS Partner (20% sur les enchères Google Shopping, pays éligibles)",
  "coupon-code": "Code de réduction",
  "your-subscription": "Votre abonnement",
  "get-my-offer": "Profiter de mon offre",
  "coupon-code-desc": "Votre code de réduction s'appliquera depuis la page de paiement Stripe",
  "configure-canal": "Activer canal",
  "init-google-canal": "Activer votre canal Google",
  "select-account-to-link": "Sélectionnez le compte à lier",
  "create-google-ads": "Créer un compte Google Ads",
  "link-existing-account-desc": "Connectez l'un de vos comptes publicitaires pour le gérer depuis Feedcast !",
  "google-offer": "400€ de budget publicitaire offert !",
  "google-discount": "20% de réduction",
  "on-google-shopping": "sur vos enchères Google Shopping",
  "create-account-advantages": "Grâce au statut Google CSS Partner de Feedcast, vous pouvez bénéficier de 400€ de budget publicitaires offerts, ainsi que 20% de réduction sur vos enchères Google Shopping (pays éligibles).",
  "create-account-advantages-2": "Pour en savoir plus sur les conditions pour obtenir ces avantages, rendez-vous sur ",
  "helpdesk-article": "le centre d'aide",
  "get-most-of-fc": "Feedcast CSS Partner",
  "account-not-in-list": "Mon compte ne se trouve pas dans la liste",
  "promotion-desc": "Profitez de vos deux premiers mois à 29€ ht/mois !",
  "delivery-help": "Minimum / maximum de livraison (en jours)",
  "handling-help": "Minimum / maximum de prise en charge de la commande (en jours)",
  "rates": "Taux",
  "max-hour-help": "Heure max. de prise en charge de la commande",
  "edit-import-method": "Modifier import du flux",
  "cancel-import-method": "Annuler la modification de l'import",
  "subscribe-desc": "Cette fonctionnalité est réservée aux abonnés. Pour pouvoir y accéder, choisissez une formule pour votre boutique.",
  "begin-subscription": "Choisir un abonnement",
  "from": "de",
  "to": "jusqu'à",
  "no-microsoft-accounts": "Aucun compte(s) Merchant Center détecté",
  "products-catalog": "Catalogue Produit",
  "feed-configuration": "Paramètres de flux",
  "diffusion": "Diffusion publicitaire",
  "metrics": "Métriques",
  "product-performances": "Performances produits",
  "ads-account-access-issue": "Nous n'arrivons pas à accéder à votre compte publicitaire. Contactez le support Feedcast.",
  "edit-shop-name": "Modifier nom boutique",
  "welcome-feedcast": "Bienvenue chez Feedcast",
  "welcome-feedcast-desc": "Avant de commencer les choses sérieuses, on aimerait en savoir un peu plus sur vous !",
  "shop-url": "URL de boutique",
  "global-performances": "Vue d'ensemble",

  "delete-feed-confirm": "Si vous décidez de supprimer votre boutique, tout ce qui y est lié sera définitivement détruit. Pour continuer, veuillez écrire <b>url</b> dans le champ ci-dessous.",
  "confirm-delete": "Oui, supprimer ma boutique définitivement",
  "success-delete-feed": "Votre boutique a été correctement supprimée.",
  "microsoft-center-name": "Le nom de votre nouveau Merchant Center",
  "domain-name": "Nom de domaine",
  "create": "Créer",
  "your-domain-name": "maboutiqueverifiee.com",
  "link-existing-merchant-center": "Lier un Merchant Center existant",
  "create-microsoft-center": "Créer un nouveau Merchant Center",
  "microsoft-center-requirement": "Votre nom de domaine doit être vérifié auprès de Microsoft",
  "link-existing-merchant": "Lier un Merchant Center existant",
  "link-existing-merchant-desc": "Accédez à la liste de vos Merchant Center",
  "delete-rule": "Supprimer règle",
  "create-a-label": "Créer un label",
  "campaigns-handle": "Campagnes",
  "products-state": "État des produits",
  "feed-optimisation": "Optimisation du flux",
  "diffusion-configuration": "Diffusion",
  "source-origin": "Flux d'origine",
  "source-feedcast": "Flux Feedcast",
  "previous-product": "Produit précédent",
  "next-product": "Produit suivant",
  "visit-product": "Voir fiche produit",
  "to-delete-need-free": "Pour supprimer votre boutique, vous devez annuler votre abonnement et attendre que votre boutique repasse en plan Gratuit.",
  "success": "Succès",
  "bg": "Bing",
  "google-product-reporting": "Rapport produit Google",

  "cant-sub": "Informations de facturation manquantes",
  "cant-sub-desc": "Pour pouvoir vous abonner, vous devez premièrement remplir vos informations de facturation <a>sur cette page !</a> ",
  "success-channel-toggle-true": "Le canal de diffusion a bien été activé !",
  "success-channel-toggle-false": "Le canal de diffusion a bien été désactivé.",
  "toggle-channel-true": "Activation du canal {company}",
  "toggle-channel-true-desc": "Souhaitez-vous activer le canal {company} sur cette boutique ?",
  "toggle-channel-false": "Désactivation du canal {company}",
  "toggle-channel-false-desc": "Souhaitez-vous désactiver le canal {company} sur cette boutique ? Attention, désactiver un canal entraînera les conséquences suivantes : ",
  "channel-to-be-true": "Activer le canal",
  "channel-to-be-false": "Désactiver le canal",

  "additional-platforms": "Canaux de diffusion",
  "nb-platforms": "{nb} canaux",
  "too-many-platforms": "Pour pouvoir réaliser cette opération, il faut désactiver au moins l'un des canaux de diffusion suivant : ",
  "platform-remaining": "{nb} canal supplémentaire disponible",
  "platforms-remaining": "{nb} canaux supplémentaires disponibles",
  "product-catalog": "Catalogue produit",
  "no_active_campaigns": "Aucune campagne active",
  "account_payment_missing": "Aucune donnée(s) de facturation",
  "feed_sync_end": "Synchronisation du catalogue",
  "no-error": "Aucune erreur",
  "success-creation-microsoft-center": "Votre nouveau Merchant Center a bien été activé !",
  "next-removed-platforms": "Désactivation de canaux publicitaires",
  "next-removed-platforms-desc": "Les canaux publicitaires suivants seront <b>désactivés</b> : ",
  "cancel": "Annuler",
  "informations": "Informations",
  "search": "Rechercher",
  "search-products-placeholder": "Référence, titre, marque...",
  "product-filters-warning": "Filtre : Produit(s) avertissements/bloqués",
  "GOOGLE_PERFORMANCE_MAX": "Google Performance Max",
  "META_SHOPPING_ADVANTAGE": "Meta Shopping Advantage+",
  "GOOGLE_SHOPPING_CLASSIC": "Google Shopping Classique",
  "countries": "Pays",
  "languages": "Langues",
  "all-campaign-products": "Tous mes produits",
  "nb-products": "{nb} produits",
  "catalog-errors-recap-desc": "Examinez et résolvez ces problèmes de vos canaux de publicité connectés pour améliorer les performances",
  "free-listing": "Diffusion Gratuite",
  "categories-desc": "Améliorez votre visibilité en associant vos catégories personnelles aux classifications standards de Google",
  "categories-explanation": "Si des catégories sont détectées dans votre flux, Feedcast tente de les associer avec des catégories Google existantes. À vous de confirmer cette liaison automatique, ou d'en spécifier une autre ! ⬇️",
  "search-for-existing-label": "Recherche d'un label existant",
  "no-source": "Labels personnalisés désactivées",
  "no-source-desc": "Ajoutez un flux produits pour activer la création de labels personnalisés pour votre boutique.",
  "shipping-weight": "Poids de livraison",
  "target-roas-disabled-when-new": "Le ROAS cible est désactivé pour les nouvelles campagnes",
  "alerts-merchant-center": "Alertes Merchant Center",
  "more-details": "En savoir plus",
  "import-product-catalog": "Importer mon flux produit",
  "enable-free-listing": "Activer la diffusion shopping",
  "choose-plan": "Gestion de l'abonnement",
  "channels": "Canaux : {channels}",
  "enable-channel": "Activer un canal",
  "diffusion-shopping": "Diffusion Shopping",
  "all": "Tous",
  "feedcast-mobile": "Toutes nos excuses, Feedcast n'est pas encore disponible sur mobile. Connectez-vous sur un ordinateur pour accéder à l'interface.",
  "init-channel": "Réinitialiser canal de diffusion",
  "success-revoke-channel": "Votre compte a bien été ré-initialisé.",
  "sync-feed": "Synchroniser mon flux",
  "feature-not-available": "Fonctionnalité non disponible",
  "are-you-sure-sync-feed": "En activant cette option, vous synchroniserez votre flux produit avec le canal de diffusion sélectionné. {br}<b>Notez que cette action est limitée à une fois par jour.</b> {br} Nous recommandons de l'utiliser principalement pour mettre à jour votre catalogue produit après avoir corrigé d'éventuelles erreurs dans votre flux.",
  "confirm": "Confirmer",
  "success-sync-feed": "Votre flux produit a bien été envoyé en synchronisation !",
  "siret": "SIRET",
  "vat-exempted": "Exempté de TVA",
  "accounts": "Applications",
  "last-check": "Dernière vérification",
  "uninstall-app": "Désinstaller",
  "sure-revoke": "Désinstaller application",
  "sure-revoke-desc": "Êtes-vous certain de vouloir réinitialiser la liaison avec ce canal ?",
  "confirm-revoke": "Oui, désinstaller",
  "channel": "Canal",
  "feedback-desc": "Un problème, une suggestion? Donnez-nous votre avis! 🚀",
  "title-feedback-desc": "Un titre bref pour décrire votre problème / suggestion",
  "f-feedback-desc": "Une description brève de votre problème / suggestion",
  "severity": "Sévérité",
  "submit": "Envoyer",
  "thank-you-feedback": "Merci pour votre retour! ✨",
  "merge-duplicates": "Réunir règles",
  "invalid-request": "Requête invalide.",
  "error-auth": "Échec authentification",
  "unauthorized": "Accès à une ressource non autorisé",
  "unknown-resource": "Accès à une ressource inconnue",
  "limit-per-day": "Cette requête est limitée à une utilisation par jour.",
  "backend-error": "Erreur serveur",
  "unknown-backend-error": "Erreur serveur inconnue",
  "bad-credentials": "Identifiant invalide",
  "cant-subscribe": "Accès non autorisé",
  "cant-subscribe-desc": "Vous n'êtes pas autorisé à gérer l'abonnement de vos boutiques depuis Feedcast. {br} Si vous êtes un Partner Feedcast, la gestion de l'abonnement s'effectue directement depuis votre espace Partenaire. {br} Le cas échéant, merci de contacter le support Feedcast.",
  "init-channel-desc": "Cette action entraînera la réinitialisation complète de la configuration du canal sélectionné. Êtes-vous certain de vouloir continuer ?",
  "crop-image": "Redimension d'image",
  "crop": "Redimensionner",
  "columns": "Colonnes",
  "success-init-channel": "Le canal publicitaire a bien été réinitialisé.",
  "service-enabled": "Service activé",

  "no-access-to-ads-account": "Problème d'accès au compte. Veuillez contacter le support Feedcast.",
  "asset-manager": "Gestionnaire d'images",
  "no-assets": "Aucune image!",
  "no-assets-desc": "Importez une première image dans votre bibliothèque.",
  "assets-marketing-explained": "<b>Marketing</b>: Une image créative qui met en valeur votre marque ou vos produits. (min. 300x300).",
  "assets-logo-explained": "<b>Logo</b>: Le logo de votre marque. (min. 128x128).",

  "handle-dynamic-label": "Labels",
  "stripe-edit": "Abonnements",
  "stripe-edit-card": "Modifier mon moyen de paiement",
  "my-product-feed": "Flux produit",
  "catalog-state-nav": "État du catalogue",
  "handle-free-listing": "Gestion Free Listing",
  "free-listing-on": "Diffusion active",
  "free-listing-off": "Désactivé",
  "activate-channel-from-service": "Pour activer ce service, votre canal publicitaire doit également être activé ! 👨‍🏫",
  "setup-channel": "Configurer canal",
  "paid-feature": "Réservé aux abonnés",
  "feedcast-marketplace-desc": "Diffusion gratuite de vos produits sur les fiches Feedcast.shopping",
  // "free-listing-desc": "Diffusion gratuite de vos produits sur les fiches Google Free Listing",
  "visit-feedcast-shopping": "Visiter feedcast.shopping",
  "warning-issued": "Avertissement produit",
  "channels-limit": "Limite de canaux publicitaires atteinte 👨‍💻",
  "channels-limit-desc": "Vous avez atteint la limite de canaux publicitaires pour l'abonnement que vous avez choisi.",
  "channels-limit-desc-2": "Pour pouvoir activer ce canal supplémentaire, nous vous invitons à consulter les différentes options disponibles sur la page d'abonnements !",
  "configured-but-not-active": "Le canal est configuré mais n'est pas actif. <a>Activer mon canal</a>",
  "no-active-channels": "Aucun canal publicitaire actif",
  "no-active-channels-desc": "Vous avez au moins un canal publicitaire entièrement configuré, mais aucun n'est actif sur votre boutique. Cliquez sur l'un des switch ci-dessous pour en activer un ! ⬇️",
  "image-too-small": "L'image est trop petite pour au moins l'une des options choisies.",
  "adult_k": "Produit pour adultes",
  "is_bundle": "Le produit est un ensemble de produits",
  "campaign-behaviour": "Comportement campagne",
  "create-microsoft-center-alert": "Création de compte Merchant Center - Microsoft",
  "create-microsoft-center-alert-desc": "Pour pouvoir créer un compte Merchant Center depuis Feedcast, votre <b>nom de domaine doit être vérifié auprès de Microsoft.</b>",
  "create-microsoft-center-alert-desc-2": "Assurez-vous de bien avoir validé votre nom de domaine depuis la console Bing.",
  "already-have-m-merchant": "J'ai déjà un Merchant Center Microsoft",
  "need-to-check-domain": "J'ai besoin de vérifier mon nom de domaine",
  "have-checked-domain": "J'ai un nom de domaine vérifié",
  "be-sure-checked": "Assurez-vous de bien avoir vérifié votre nom de domaine depuis la console Bing.",
  "helper-microsoft-center-domain": "Le nom de domaine doit être exactement celui vérifié auprès de Microsoft.",
  "free-field": "À titre informatif, vous pouvez choisir le nom que vous voulez.",
  "all-genders": "Tous les genres",
  "HAS_ASSET_GROUPS_LIMITED_BY_POLICY": "Votre diffusion est limitée par certains problèmes dans votre groupe d'assets.",
  "headline": "Titre court ",
  "long-headline": "Titre long ",
  "description-short": "Description courte ",
  "description-long": "Description longue ",
  "linked-channels": "Applications connectées",
  "pending-user-validation": "En attente de validation",
  "pending-user-validation-desc": "Votre compte est en attente de validation.",
  "copy-integration-code": "Copier le code d'intégration",
  "integration-clipboard": "Code d'intégration copié dans le presse papier !",
  "no-conversions": "Aucune conversion détectée",
  "has-conversions": "Conversions détectées",
  "no-pixels-detected": "Aucun pixel de conversion détecté",
  "no-unique-identifier": "Pas d'identifiant unique",
  "assets-warnings": "Groupe d'assets limité",
  "assets-warnings-desc": "Il semblerait que votre diffusion soit limitée par votre groupe d'assets.",

  // Campaigns Adsets Feedback
  "blocking": "BLOQUANT",
  "non-blocking": "NON-BLOQUANT",
  "UNACCEPTABLE_SPACING": "Espacement(s) non acceptable(s)",
  "DISCOVERY_AD_REQUIREMENTS_UNCLEAR_IMAGE": "Cette image peut porter à confusion.",
  "RELIGIOUS_BELIEF_IN_PERSONALIZED_ADS": "Utilisation inappropriée de croyances religieuses dans les publicités personnalisées.",
  "images": "Images",
  "texts": "Textes",
  "add-video": "Ajouter une vidéo",
  "videos": "Vidéos",
  "assets-pictures-warnings": "Certaines de vos images ne sont pas optimisées.",
  "assets-pictures-warnings-desc": "Si l'image est indiquée comme NON-BLOQUANTE, rassurez-vous, ça ne vous empêche pas de diffuser votre campagne ! En revanche, celles identifiées comme bloquantes sont problématiques.",
  "assets-issues": "Avertissements campagne",
  "assets-texts-warnings": "Certains de vos textes ne sont pas optimisés.",
  "assets-texts-warnings-desc": "Veuillez corriger les textes concernés.",
  "edit-password": "Modifier mot de passe",

  "alert-stripe-link": "Informations de facturation manquantes",
  "alert-stripe-link-desc": "Il nous manque vos informations de facturation pour lier votre profil à notre partenaire de paiement Stripe. Remplissez les informations manquantes pour pouvoir abonner votre compte Feedcast !",
  "last-import-date": "Dernier import produit Feedcast: {date}",
  "available-options": `{nb} choix possible(s)`,
  "is-blocking": "Erreur bloquante",
  // "warnings-errors-detail": "Avertissement / Erreur",
  "then": "puis",
  "ht-first-month": "Le 1er mois",
  "lets-go": "C'est parti",
  "ads-spent": "Dépenses publicitaires",
  "payload-too-large": "Le contenu uploadé est trop volumineux.",
  "feedcast-issue": "Feedcast a rencontré un problème, et a cessé de fonctionner.",
  "woocommerce-limited-preview": "Woocommerce : Prévisualisation limitée à 500 produits",
  "woocommerce-limited-preview-desc": "Les services de Woocommerce sont plutôt lents ! Feedcast limite la prévisiualisation pour ne pas vous bloquer.",
  "renew-date": "Date de renouvellement",
  "end-sub-date": "Date de fin d'abonnement",
  "feedscore": "FeedScore",
  "ai-generation-error": "Une erreur est survenue pendant la génération.",
  "feed-limit-reached": "Limite de boutique atteinte. Contactez le support pour pouvoir en ajouter plus.",
  "email-language": "Langue Emails",
  "generate_title_and_description": "Améliorer le titre et la description",
  "show_ai_version": "Voir la version IA",
  "show_original_version": "Voir la version originale",
  "generate_ai": "Améliorer par IA",
  "already-existing-email": "Cette adresse email est déjà enregistrée.",
  "feed-domain-already-exists": "Ce nom de domaine est déjà enregistré.",
}

const projects = {
  "go-to-settings": "Accéder aux paramètres de votre compte",
  "helpdesk-desc": "Notre centre d'aide Feedcast !",
  "app-mode": "Mode",
  "app-mode-desc": "Plutôt lumineux, ou sombre?",
}

const notifications = {
  "no-history": "Pas d'historique!",
  "historic": "Historique",
  "click-for-more": "Cliquez pour corriger",
}

const pageInfo = {
  "your-product-reporting": "Vos performances produits",
  "page-info-product-reporting": " Ce rapport présente les résultats détaillés par produits des annonces affichées dans les emplacements Google Shopping <a>en savoir plus sur l’affichage des annonces sur Google</a>",
  "your-custom-labels": "Vos labels personnalisés",
  "page-info-custom-labels": "Segmentez vos produits grâce aux labels personnalisés et diffusez les produits de votre choix dans vos campagnes. <a>En savoir plus sur les labels personnalisés</a>",
  "your-optimisation": "Optimiser votre flux produit",
  "page-info-optimisation": "Optimisez votre catalogue pour de meilleures performances sur vos campagnes ! <a>Retrouvez nos conseils sur le centre d’aide</a>",
  "page-info-product-list": "Optimisez votre catalogue produits pour de meilleures performances sur vos campagnes. <a>Retrouvez nos conseils sur le centre d’aide</a>",
  "your-product-edit": "Optimiser votre fiche produit",
  "page-info-product-edit": "Optimisez votre fiche produit pour de meilleures performances sur vos campagnes ! <a>Retrouvez nos conseils sur le centre d'aide</a>",
}

const login = {
  "diffuse-easily": "Diffusez facilement vos produits en ligne",
  "optimise-catalog": "Optimisez votre catalogue produits par IA",
  "20-percent-cpc": "Économisez 20% sur vos CPC Google Shopping",
  "google-ads-credit": "400€ de crédit Google Ads offert",
  "free-trial-7days": "Testez gratuitement pendant 7 jours",
  "login": "Connexion",
  "comeback": "On est ravis de vous revoir, mais vous devez quand même vous connecter !",
  "continue": "Continuer",
  "no-account": "Vous n'avez pas de compte?",
  "register": "Inscription",
  "already-have-account": "J'ai déjà un compte !",
  "your-name": "Votre société",
  "your-email": "Votre email",
  "password": "Mot de passe",
  "confirmation": "Confirmation",
  "forgotten-password": "Mot de passe oublié ?",
  "password-requirements": "Votre mot de passe contient : ",
  "register-catch": "C'est rapide, c'est gratuit, et c'est indispensable pour aller plus loin",
  "password-minimum-length": "Au moins 6 caractères",
  "password-lowercase": "Lettres minuscules (a-z)",
  "password-uppercase": "Lettres majuscules (A-Z)",
  "password-numbers": "Chiffres (0-9)",
  "refresh": "Mot de passe oublié?",
  "login-desc": "Connectez-vous pour accéder à votre dashboard !",
  "register-desc": "Créez votre compte en quelques clics",
  "register-desc-2": "Ou inscrivez vous par email : ",
  "register-last-step": "Finalisez votre inscription en vous connectant avec Google, ou en renseignant votre email :",
  "back-to-website": "Retour au site",
  "handle-campaigns-everywhere": "Gérez vos campagnes e-commerce partout",
  "sign-in-google": "Connexion avec Google",
  "remember-password": "Je me souviens de mon mot de passe !",
  "refresh-desc": "Pas de panique, renseignez votre email pour le réinitialiser : ",
  "refresh-password": "Réinitaliser",
  "refresh-password-success": "Consultez votre boîte de réception !",
  "reset-email-sent": "Succès! ✅",
  "reset-instructions-sent": "Instructions de réinitialisation envoyées",
  "check-inbox-reset": "Veuillez vérifier votre boîte de réception pour réinitialiser votre mot de passe.",
  "register-conditions": "En continuant, vous acceptez les conditions d'utilisation de Feedcast. <a>Lire nos conditions générales</a>",
  "domain": "Domaine",
  "shop-autogeneration": "Génération automatique de boutique",
  "shop-autogeneration-desc": "Votre boutique sera créée automatiquement grâce à notre technologie AutoGen.",
  "continue-with-email": "Continuer avec email",
  "confirm-password": "Confirmer le mot de passe",
  "country": "Pays",
  "language": "Langue",
  "registration-success": "Tout est prêt ! 🎉",
  "verification-email-sent": "Nous avons envoyé un email de vérification à :",
  "check-inbox-verify": "Veuillez vérifier votre boîte de réception pour valider votre compte et commencer.",
  "products-found": "Produits trouvés",
  "selected-domain": "Domaine sélectionné",
  "new-here": "Nouveau ici ?",
  "or-provide-email": "Ou fournissez votre email :",
}

const logtypes = {
  "logtype-google-merchant": "Alerte Google Merchant Center",
  "logtype-1": "Erreur synchronisation du flux",
  "logtype-2": "Erreur de tracking",
  "feed-download-error": "Il semble y avoir un problème sur la synchronisation de votre flux avec notre service.",
  "logtype-MISSING_FEED": "Aucun produit détecté",
  "missing-import-method": "Votre boutique n'a aucune méthode d'import configurée. Ajoutez une méthode d'import de votre catalogue produit pour commencer à utiliser Feedcast !",
  "logtype-125": "Campagne(s) limitée(s)",
  "limited-campaigns-desc": "Une ou plusieurs de vos campagnes sont limitées.",
  "logtype-123": "Aucune données de facturation",
  "account_payment_missing-desc": "Sans moyen de paiement sur le canal concerné, vos campagnes ne <b>fonctionneront pas</b>. Renseignez un moyen de paiement pour pouvoir diffuser vos annonces!",
  "logtype-124": "Aucune campagne active",
  "no_active_campaigns-desc": "Vous n'avez aucune campagne active sur cette boutique.",
  "logtype-122": "Perte de données",
  "feed_import_drop-desc": "Nous avons détectés une différence de volume dans vos produits. Précédemment : {previous} produits. Actuellement : {current} produits.",
  "logtype-120": "Flux synchronisé",
  "logtype-129": "Aucun produit !",
  "logtype-130": "Erreur de flux",
  "logtype-131": "Merchant Center",
  "logtype-3": "Problème d'accès au compte publicitaire",
  "logtype-121": "Import des produits",
  "logtype-132": "Campagne - Aucune conversion",
  "logtype-132-desc": "Cette campagne ne présente aucune donnée de conversion.",
  "logtype-133": "Campagne - ROI faible",
  "logtype-133-desc": "Cette campagne a un ROI faible.",
  "logtype-136": "Aucun tracking",
  "logtype-136-desc": "Aucun pixel de conversion détecté sur votre compte.",
  "logtype-138": "Optimisation par IA",
  "logtype-138-desc": "Les produits que vous avez sélectionnés ont été optimisés par IA !",
}

const ai = {
  "ai-generation": "Génération IA",
  "one-credit-used": "Un crédit utilisé",
  "sub-ai": "Feedcast IA",
  "included-in-your-plan": "Inclus dans votre plan : {nb} crédits",
  "unlimited-ai": "IA illimitée",
  "unlimited": "ILLIMITÉ",
  "title-generation": "Génération de titre",
  "gen-new-version": "Générer une nouvelle version",
  "credits-nb": "Crédits IA : {nb}",
  "credits": "Crédits IA : ",
  "ai-credits": "Crédits IA",
  "monthly-recurring": "par mois",
  "your-plan": "Votre abonnement",
  "upgrade-to-unlimited": "Mettre à niveau vers l'IA illimitée",
  "ai-optimization": "Optimisation IA",
  "ai-optimization-title": "Optimisation IA du Catalogue",
  "ai-optimization-description": "Optimisez l'ensemble de votre catalogue grâce à notre IA spécialisée basée sur les directives shopping. Notre IA analysera et améliorera vos données produits pour assurer une meilleure visibilité et conversion.",
  "start-ai-optimization": "Démarrer l'Optimisation IA",
  "ai-optimization-dialog-title": "Confirmer l'Optimisation IA",
  "ai-optimization-dialog-products": "Nous avons détecté {count} produits qui nécessitent une optimisation dans votre catalogue.",
  "ai-optimization-description-dialog": "Notre IA analysera et améliorera vos données produits selon les meilleures pratiques du marché et les directives shopping.",
  "available-credits": "Crédits disponibles",
  "required-credits": "Crédits nécessaires",
  "remaining-credits": "Crédits restants",
  "not-enough-credits-warning": "Vous n'avez pas assez de crédits pour optimiser l'ensemble de votre catalogue. Veuillez mettre à niveau votre abonnement pour continuer.",
  "cancel": "Annuler",
  "confirm-optimization": "Démarrer l'Optimisation",
  "go-to-subscription": "Obtenir Plus de Crédits"
}

const products = {
  "data-source": "Données produit",
  "channel-offline": "Canal inactif",
  "title-and-desc": "Titre et Description",
  "attributes": "Attributs",
  "ids-and-data": "Identifiants et Données",
  "product-information": "Informations",
  "product-detail": "Détails du produit",
  "product-weight": "Poids du produit",
  "product-link": "Lien page produit",
  "channel-statuses": "Statuts Canaux",
  "original-image-url": "Image originale",
  "image-url": "URL Image",
  "quick-edit": "Édition rapide",
  "product-edit": "Fiche produit",
  "see-product": "Lien produit",
  "title-or-ref": "Titre ou référence produit",
  "hide-products-not-found": "Cacher les produits qui ne sont plus dans mon catalogue actuel",
  "see-full-product": "Fiche produit",
  "preview-product": "Prévisualiser",
  "product-online": "Produit validé",
  "pending-new-validation": "Modifications en attente",
  "batch-pending-validation-desc": "Ce produit a été modifié depuis sa dernière synchronisation auprès du canal publicitaire. ",
  "feature-in-dev": "Fonctionnalité en développement",
  "error-detected": "Produit bloqué",
  "channel-disabled": "Canal désactivé",
  "warning-issues": "En ligne avec avertissement(s)",
  "product-pending-changes": "Modifications en cours",
  "product-pending-changes-desc": "Vous avez effectué des modifications sur votre fiche produit, mais elles ne sont pas enregistrées. Que souhaitez-vous faire ?",
  "cancel-changes": "Annuler mes modifications",
  "confirm-changes": "Confirmer mes modifications",
  "no-original-image": "Aucune image présente dans le flux pour ce produit",
  "new-image-url": "Nouvelle image",
  "leave-blank-for-original": "Laissez ce champ vide si vous souhaitez utiliser l'image présente dans votre flux produit.",
  "ai-enhanced": "Optimisé par IA",
  "ai-batch": "Optimiser par IA",
  "ai-batching": "Optimisation par IA",

  "ai-batch-selection-count": "Dans votre sélection, {count} produits peuvent être optimisés par notre IA.",
  "ai-batch-cost": "Cela vous coûtera {cost} crédits.",
  "ai-batch-insufficient-credits": "Vous n'avez pas assez de crédits. Il vous manque {credits} crédits. Passez à l'option illimitée !",
  "ai-batch-in-progress": "L'optimisation par IA est en cours. Vous recevrez un email et une notification lorsque cela sera terminé!",
}

const dashboard = {
  "feedcast-ai": "Feedcast IA",
  "ai-hero-desc": "L'IA prend une place encore plus importante dans Feedcast ! Testez dès aujourd'hui l'amélioration de vos fiches produits grâce à notre intelligence artificielle spécialisée pour optimiser les titres et vos descriptions de vos fiches produits.",
  "ai-cta": "Découvrir comment optimiser mes fiches produits",
  "enable-feedscore": "Pour activer le FeedScore, vous devez importer votre flux produit !",
}

const campaigns = {
  "create-or-edit-a-label": "Créer ou éditer un label",
  "all-locations": "Tous les pays",
  "all-locations-desc": "Diffusez sur tous les pays à la fois. Pas de jaloux !",
  "specific-locations": "Sélection de pays",
  "specific-locations-desc": "Choisir une zone géographique précise.",
  "perf-max-photos": "Photos",
  "perf-max-photos-desc": "Choisissez quelles images vous souhaitez utiliser pour votre campagne.",
  "perf-max-texts": "Textes de campagne",
  "perf-max-texts-desc": "Renseignez les différents textes qui seront diffusés.",
  "perf-max-videos": "Vidéos",
  "perf-max-videos-desc": "Vous pouvez ajouter jusqu'à 5 vidéos pour booster la diffusion de votre campagne !",
  "how-to-add-perf-max-video": "Comment ajouter des vidéos?",
  "how-to-add-perf-max-video-desc": "Nous avons besoin de <b>l'identifiant</b> de votre vidéo. L'identifiant de votre vidéo est situé à cet emplacement dans l'URL : youtube.com/watch?v=<b>VIDEO_ID</b> {br} Vous pouvez <b>copier coller l'URL entière</b> directement dans le champ, Feedcast s'occupera de supprimer le superflu !",
  "roas-desc": "Le ROAS, ou retour sur dépenses publicitaires dans une campagne Performance Max, mesure la rentabilité de la campagne en comparant les revenus générés aux dépenses publicitaires. {br} C'est un indicateur clé pour évaluer l'efficacité des dépenses publicitaires dans ces campagnes automatisées et orientées vers la performance.",
  "perf-max-data": "Données Performance Max",
  "roas-long-txt": "Retour sur dépenses publicitaires",
  "pictures-perf-max-recommendations": "Recommendation : {nb} images de {min} x {max} px",
  "logos": "Logo",
  "marketing_images_square": "Carrées",
  "marketing_images": "Paysage",
  "marketing_images_portrait": "Portrait (facultatif)",
  "perf-max-texts-best-practices": "Comment rédiger des textes pertinents ?",
  "perf-max-texts-best-practices-desc": "Pour vous aider à avoir les meilleures campagnes possibles, on vous a préparé un super guide sur la meilleure manière d'écrire ses textes de campagne !",
  "microsoft-shopping": "Microsoft Smart Shopping",
  "microsoft-shopping-desc": "Maximisez votre rendement en utilisant les enchères automatiques et le ciblage précis sur le Réseau de recherche Microsoft. Les campagnes Shopping intelligentes ont la priorité sur les campagnes Shopping standard pour les mêmes produits ciblés",
  "campaign-desc": "Renseignez les informations de votre campagne",
  "locked-product-partition": "Sélection produit verrouillée",
  "locked-product-partition-desc": "La segmentation des campagnes Microsoft ne peut pas être modifiée une fois créée. Une correction sera bien effectuée par Microsoft pour vous permettre de modifier votre sélection.",
  "delete-campaign": "Suppression campagne",
  "delete-campaign-confirm": "Cette action entraînera la suppression définitive de cette campagne. Si vous souhaitez poursuivre, veuillez écrire <b>campaign</b> dans le champ ci-dessous.",
  "yes-delete-campaign": "Oui, supprimer définitivement",
  "success-delete-campaign": "Votre campagne a été supprimée.",
  "delete-feed": "Supprimer boutique",
  "bidding-strategy": "Stratégie d'enchères",
  "saving-campaign": "Votre campagne est en cours de sauvegarde...",
  "bidding-strategy-desc": "La stratégie d'enchères permet de déterminer combien vous êtes prêt à payer chaque fois que quelqu'un clique sur vos annonces. Voici les trois options stratégiques d'enchères : <ul>" +
    "<li><b>Enchères au CPC manuelles</b> : Vous contrôlez directement le montant maximum que vous êtes prêt à payer pour chaque clic sur vos annonces. </li>" +
    "<li><b>Maximiser les clics</b> : Google ajuste automatiquement vos enchères pour obtenir le maximum de clics possibles avec votre budget défini. </li>" +
    "<li> <b>ROAS cible</b> : Vous définissez un retour sur dépense publicitaire cible, et Google ajuste vos enchères pour maximiser votre valeur de conversion tout en atteignant le ROAS que vous avez spécifié. (Désactivé pour les nouvelles campagnes)</li></ul>",
  "ad-group-name": "Nom du groupe d'annonces",
  "ad-group-name-desc": "Le groupe d'annonce regroupe vos produits dans une seule catégorie pour simplifier la gestion et le suivi des performances.",
  "global-parameters": "Campagne",
  "global-parameters-desc": "Configurez les éléments essentiels de votre campagne.",
  "targeting-parameters": "Quels éléments créatifs de votre marque allons-nous utiliser ?",
  "targeting-parameters-desc": "Facultatif",
  "add-countries": "Ajouter un pays",
  "meta-adv-benef": "Le Bénéficiaire de l'annonce désigne la partie (généralement une entreprise ou une marque) qui bénéficie de la campagne publicitaire, c'est-à-dire celle pour qui la publicité est faite.",
  "meta-adv-payor": "Le Payeur de l'annonce est la personne ou l'entité qui finance la campagne, payant pour les coûts de publicité. Ces deux rôles peuvent être assumés par la même entité ou par des entités différentes, selon la situation.",
  "meta-adset": "Le nom du groupe d'adset fait référence à l'ensemble d'annonces partageant les mêmes paramètres de ciblage, de budget, de calendrier, de placement et d'optimisation",
  "meta-classic-platforms": "Canaux de diffusion",
  "all-that-have-not": "Tous ceux qui n'ont pas",
  "all-that-have": "Tous ceux qui ont",
  "look-for-list-type": "Chercher {listType}",
  "saving-campaign-data": "Sauvegarde de la campagne en cours...",
}

const configuration = {
  "google_ads-desc": "Compte publicitaire",
  "m_center-desc": "Catalogue produit, frais de livraisons",
  "meta_account-desc": "Compte publicitaire",
  "meta_pixel-desc": "Catalogue produit, Pixel Meta",
  "microsoft-desc": "Compte publicitaire",
  "microsoft-center-desc": "Catalogue Produit",
  "pixels-detected": "Pixels de conversion détectés",
  "free_listing-desc": "Diffusion gratuite de votre catalogue produit sur le réseau Google Free Listing",
  "disabled": "Désactivé",
  "configured": "Configuré",
  "service-to-configure": "Service à configurer",
  "configuration": "Configuration",
  "begin-channel-config": "Commencez la configuration de votre canal publicitaire !",
  "no-tracking": "Aucun tracking",
  "no-payment": "Aucun moyen de paiement",
  "no-tracking-data": "Aucun tracking détecté",
  "no-tracking-data-desc": "Vous devez ajouter un pixel de suivi des conversions pour pouvoir remonter les ventes générées via vos campagnes Google. Sans cela, vous risquez d'être limité au niveau de la diffusion et des performances de vos campagnes Google.",
  "no-channel-payment": "Aucun moyen de paiement détecté",
  "no-channel-payment-desc": "Vous n'avez pas ajouté votre moyen de paiement sur votre compte. Veuillez ajouter vos informations de paiement pour commencer la diffusion de vos annonces.",
  "channel-active": "Canal actif",
  "channel-disabled": "Canal désactivé",
  "conversions-detected": "Conversion(s) détectée(s)",
  "no-conversions-detected": "Aucune conversion détectée",
  "pixel-list": "Liste Pixels",
  "tracking-issue": "Aucun tracking",
  "concerned-channels": "Canaux concernés : {channels}",
  "payment-issue": "Aucun moyen de paiement",
  "important-notifications": "Vous avez des notifications urgentes à consulter",
}

const fr = {
  ...all,
  ...notifications,
  ...projects,
  ...pageInfo,
  ...login,
  ...logtypes,
  ...ai,
  ...products,
  ...dashboard,
  ...campaigns,
  ...configuration,
}


export default fr;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../store";
import campaignApi from "../services/secured/campaign";
import { GoogleAttribute, GoogleCode } from "../types/products";
import { CustomDynamicLabelKey, DynamicLabel } from "../types/feed";
import { AdsProvider, PerfMaxPictureNode, AvailableCompany, Company } from "../types/campaign";
import { useHandleSubscriptionProps } from "../../components/Global/Settings/Subscription/hooks/useHandleSubscription";
import { ResetChannelParams } from "../services/secured/channels";
import { ImageFormat } from "../utils";

export enum ModalTypes {
  Onboarding,
  AssetsManager,
  CustomLabel,
  Project,
  MultipleProductEdit,
  LinkAccount,
  MerchantCenter,
  CatalogPixel,
  CreateAccount,
  Subscription,
  ProductCorrection,
  InitAccount,
  MicrosoftCenter,
  DeleteCampaign,
  EnableChannel,
  ForceDisableChannel,
  RevokeChannel,
  SyncFeed,
  ResetChannel,
  CropImage,
  UpsellChannel,
  ProductAI,
  FeedcastAI,
  ProductChanges,
  DrawerTracking,
  AiBatch,
}

const initialState: Array<ModalData> = [];

const modal = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<ModalData>) => {
      state.push(action.payload);
    },
    closeModal: (state) => {
      state.pop();
    },
    addModalData: (state, action: PayloadAction<any>) => {
      if (state.length === 1) {
        state[0].data = action.payload;
      }
    }
  },
  extraReducers: builder => {
    builder
      .addMatcher(campaignApi.endpoints.setMerchantCenter.matchFulfilled, (state, action) => {
        if (state.length > 0) {
          state.pop();
        }
      })
  }
});

export const modalReducer = modal.reducer;
export const { openModal, closeModal, addModalData } = modal.actions;

/**
 * Shortcuts
 */
export const isAnyModalOpened = (state: RootState): boolean => state.modal.length > 0;
export const getModalData = (state: RootState): any | undefined => state.modal.length > 0
  ? state.modal[state.modal.length - 1].data
  : null

export const isModalOpened = (type: ModalTypes) => (state: RootState): boolean => state.modal.length > 0
  ? type === state.modal[state.modal.length - 1].type
  : false

export const modalShowOnboarding = (): AppThunk => dispatch => {
  dispatch(openModal({ type: ModalTypes.Onboarding }));
}

export type PictureType = 'square' | 'landscape' | 'portrait' | "all";

export type AssetsManagerProps = {
  canSelect: boolean,
  type: 'square' | 'landscape' | 'portrait' | "all",
}

export type AssetsProps = {
  ratio: 'square' | 'landscape' | 'portrait' | "all",
  node: PerfMaxPictureNode,
}

export const modalShowAssetsManager = (options?: AssetsProps): AppThunk =>
  dispatch => dispatch(openModal({ type: ModalTypes.AssetsManager, data: options }));


export type ModalLabelProps = {
  label?: DynamicLabel,
  labelGroup?: CustomDynamicLabelKey,
  canDelete?: boolean,
  canChangeGroup?: boolean,
  isNewLabel?: boolean,
}
export const modalShowLabel = (options: ModalLabelProps): AppThunk => dispatch => dispatch(openModal({
  type: ModalTypes.CustomLabel,
  data: options
}));

export const modalShowProject = (data?: { project: number, name: string }): AppThunk => dispatch => dispatch(openModal({
  type: ModalTypes.Project,
  data
}));

export const modalShowProductMultipleEdit = (): AppThunk => dispatch => dispatch(openModal({ type: ModalTypes.MultipleProductEdit }));

export const modalShowLinkAccount = (data: { company: Company }): AppThunk => dispatch => dispatch(openModal({
  type: ModalTypes.LinkAccount, data
}));

export const modalShowMerchantCenter = (): AppThunk => dispatch => dispatch(openModal({ type: ModalTypes.MerchantCenter }));
export const modalShowCatalogPixel = (): AppThunk => dispatch => dispatch(openModal({ type: ModalTypes.CatalogPixel }));
export const modalShowCreateAccount = (): AppThunk => dispatch => dispatch(openModal({ type: ModalTypes.CreateAccount }));
export const modalShowSubscription = (): AppThunk => dispatch => dispatch(openModal({ type: ModalTypes.Subscription }));
export const modalShowProductCorrection = (data: GoogleErrorModalData): AppThunk => dispatch => dispatch(openModal({
  type: ModalTypes.ProductCorrection, data
}));
export const modalShowInitAccount = (): AppThunk => dispatch => dispatch(openModal({ type: ModalTypes.InitAccount }));
export const modalShowMicrosoftCenter = (): AppThunk => dispatch => dispatch(openModal({ type: ModalTypes.MicrosoftCenter }));
export const modalShowDeleteCampaign = (data: DeleteCampaignProps): AppThunk => dispatch => dispatch(openModal({
  type: ModalTypes.DeleteCampaign, data
}));

export const modalShowEnableChannel = (data: EnableChannelProps): AppThunk => dispatch => dispatch(openModal({
  type: ModalTypes.EnableChannel, data
}))

export const modalShowForceDisableChannel = (data: useHandleSubscriptionProps): AppThunk => dispatch => dispatch(openModal({
  type: ModalTypes.ForceDisableChannel, data
}));

export const modalShowRevokeChannel = (data: RevokeChannelProps): AppThunk => dispatch => dispatch(openModal({
  type: ModalTypes.RevokeChannel, data
}));

export const modalShowSyncFeed = (data: { company: AvailableCompany }): AppThunk => dispatch => dispatch(openModal({
  type: ModalTypes.SyncFeed, data,
}));

export const modalShowResetChannel = (data: Pick<ResetChannelParams, "provider">): AppThunk => dispatch => dispatch(openModal({
  type: ModalTypes.ResetChannel, data
}));

export const modalShowCropImage = (data: ModalCropImageProps): AppThunk => dispatch => dispatch(openModal({
  type: ModalTypes.CropImage, data
}));

export const modalShowUpsell = (): AppThunk => dispatch => dispatch(openModal({ type: ModalTypes.UpsellChannel }));

export const modalShowProductAi = (data: ProductAiModalData): AppThunk => dispatch => dispatch(openModal({
  type: ModalTypes.ProductAI, data
}))

export const modalShowFeedcastAi = (): AppThunk => dispatch => dispatch(openModal({
  type: ModalTypes.FeedcastAI,
}))

export const modalShowProductChanges = (): AppThunk => dispatch => dispatch(openModal({
  type: ModalTypes.ProductChanges
}))

export const modalShowAiBatch = (): AppThunk => dispatch => dispatch(openModal({
  type: ModalTypes.AiBatch
}))

//====================
// Types
//====================

export type RevokeChannelProps = {
  id: number,
}

type ModalData = {
  type: ModalTypes,
  data?: any,
}

export type GoogleErrorModalData = {
  errorId: number,
  field: GoogleAttribute | null,
  header: GoogleCode | string,
  desc: GoogleCode | string,
  company: Company,
}

export type DeleteCampaignProps = {
  provider: AdsProvider,
  campaign: string,
}

export type EnableChannelProps = {
  company: Company,
  provider: AdsProvider,
  toBeEnabled: boolean,
  source?: "switch" | "service",
}

export type ModalCropImageProps = {
  url: string,
  format: ImageFormat,
}

export interface ProductAiModalData {
  field: "title" | "description",
  original: string,
}

import React, { useEffect } from 'react';
import { Box, Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { AutoFixHigh, Psychology } from '@mui/icons-material';
import productsApi from '../../../app/services/secured/products';
import { GetCurrentFeedId } from '../../../app/utils';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { getAiCredits } from '../../../app/slices/app';
import Urls from '../../../assets/js/Urls';

const BatchAIOptimisation = () => {
   const [isDialogOpen, setIsDialogOpen] = React.useState(false);
   const [isSuccessDialogOpen, setIsSuccessDialogOpen] = React.useState(false);
   const navigate = useNavigate();
   const feed = GetCurrentFeedId();
   const { data } = productsApi.useGetNonOptimizedProductsQuery({ feed });
   const credits = useAppSelector(getAiCredits);

   const nonOptimizedCount = data?.nb || 0;
   const hasEnoughCredits = credits === null || credits >= nonOptimizedCount;

   const [batchAi, batchAiRes] = productsApi.useBatchAiMutation();
   const submit = () => batchAi({ feed, products: [] });

   const handleOptimize = () => {
      setIsDialogOpen(true);
   };

   const handleConfirmOptimize = () => {
      setIsDialogOpen(false);
      submit();
   };

   const handleGoToSubscription = () => {
      navigate(Urls.settings.subEdit(feed));
   };

   useEffect(() => {
      if (batchAiRes.isSuccess) {
         setIsSuccessDialogOpen(true);
      }
   }, [batchAiRes.isSuccess]);

   return (
      <Box sx={{
         display: 'flex',
         flexDirection: 'column',
         alignItems: 'center',
         gap: 4,
         py: 4
      }}>
         <Box sx={{ maxWidth: 600, textAlign: 'center' }}>
            <Typography variant="h5" gutterBottom>
               <FormattedMessage id="ai-optimization-title" defaultMessage="AI Catalog Optimization" />
            </Typography>
            <Typography color="text.secondary">
               <FormattedMessage
                  id="ai-optimization-description"
                  defaultMessage="Optimize your entire catalog using our specialized AI powered by shopping guidelines. Our AI will analyze and enhance your product data to ensure better visibility and conversion."
               />
            </Typography>
         </Box>

         <Button
            variant="contained"
            size="large"
            startIcon={<AutoFixHigh />}
            onClick={handleOptimize}
            sx={{
               minWidth: 250,
               height: 48
            }}
         >
            <FormattedMessage id="start-ai-optimization" defaultMessage="Start AI Optimization" />
         </Button>

         <Dialog
            open={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            maxWidth="sm"
            fullWidth
            PaperProps={{
               sx: {
                  borderRadius: 2,
                  px: 1,
               }
            }}
         >
            <DialogTitle sx={{
               pb: 1,
               display: 'flex',
               alignItems: 'center',
               gap: 1
            }}>
               <AutoFixHigh sx={{ color: 'primary.main' }} />
               <FormattedMessage id="ai-optimization-dialog-title" defaultMessage="Confirm AI Optimization" />
            </DialogTitle>

            <DialogContent sx={{ py: 3 }}>
               <Box sx={{ mb: 3 }}>
                  <Typography variant="h6" gutterBottom sx={{ fontSize: '1.1rem', fontWeight: 500 }}>
                     <FormattedMessage
                        id="ai-optimization-dialog-products"
                        defaultMessage="We detected {count} products that need optimization in your catalog."
                        values={{ count: nonOptimizedCount }}
                     />
                  </Typography>
                  <Typography color="text.secondary" variant="body2">
                     <FormattedMessage
                        id="ai-optimization-description-dialog"
                        defaultMessage="Our AI will analyze and enhance your product data based on marketplace best practices and shopping guidelines."
                     />
                  </Typography>
               </Box>

               <Box sx={{
                  p: 2.5,
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'divider',
                  maxWidth: 280,
                  mx: 'auto',
                  my: 4
               }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                     <Typography variant="body1" color="text.secondary">
                        <FormattedMessage id="available-credits" defaultMessage="Available credits" />
                     </Typography>
                     <Typography variant="h5" fontWeight={500}>
                        {credits === null ? '∞' : credits}
                     </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                     <Typography variant="body1" color="text.secondary">
                        <FormattedMessage id="required-credits" defaultMessage="Required credits" />
                     </Typography>
                     <Typography variant="h5" fontWeight={500} color="error.main">
                        -{nonOptimizedCount}
                     </Typography>
                  </Box>
                  <Box sx={{
                     display: 'flex',
                     justifyContent: 'space-between',
                     pt: 1,
                     borderTop: '1px solid',
                     borderColor: 'divider'
                  }}>
                     <Typography variant="body1" color="text.secondary">
                        <FormattedMessage id="remaining-credits" defaultMessage="Remaining credits" />
                     </Typography>
                     <Typography
                        variant="h5"
                        fontWeight={600}
                        color={hasEnoughCredits ? 'success.main' : 'error.main'}
                     >
                        {credits === null ? '∞' : credits - nonOptimizedCount}
                     </Typography>
                  </Box>
               </Box>

               {!hasEnoughCredits && (
                  <Typography
                     color="error.main"
                     variant="body2"
                     sx={{
                        textAlign: 'center',
                        mt: 2,
                        fontWeight: 500
                     }}
                  >
                     <FormattedMessage
                        id="not-enough-credits-warning"
                        defaultMessage="You don't have enough credits to optimize your entire catalog. Please upgrade your subscription to continue."
                     />
                  </Typography>
               )}
            </DialogContent>

            <DialogActions sx={{ px: 3, pb: 3 }}>
               {hasEnoughCredits ? (
                  <>
                     <Button
                        onClick={() => setIsDialogOpen(false)}
                        sx={{ color: 'text.secondary' }}
                     >
                        <FormattedMessage id="cancel" defaultMessage="Cancel" />
                     </Button>
                     <Button
                        variant="contained"
                        onClick={handleConfirmOptimize}
                        startIcon={<AutoFixHigh />}
                     >
                        <FormattedMessage id="confirm-optimization" defaultMessage="Start Optimization" />
                     </Button>
                  </>
               ) : (
                  <>
                     <Button
                        onClick={() => setIsDialogOpen(false)}
                        sx={{ color: 'text.secondary' }}
                     >
                        <FormattedMessage id="cancel" defaultMessage="Cancel" />
                     </Button>
                     <Button
                        variant="contained"
                        onClick={handleGoToSubscription}
                        startIcon={<AutoFixHigh />}
                     >
                        <FormattedMessage id="go-to-subscription" defaultMessage="Get More Credits" />
                     </Button>
                  </>
               )}
            </DialogActions>
         </Dialog>

         <Dialog
            open={isSuccessDialogOpen}
            onClose={() => setIsSuccessDialogOpen(false)}
            maxWidth="sm"
            fullWidth
            PaperProps={{
               sx: {
                  borderRadius: 2,
                  px: 1,
               }
            }}
         >
            <DialogTitle sx={{
               pb: 1,
               display: 'flex',
               alignItems: 'center',
               gap: 1
            }}>
               <AutoFixHigh sx={{ color: 'success.main' }} />
               <FormattedMessage id="ai-optimization-started-title" defaultMessage="AI Optimization Started" />
            </DialogTitle>

            <DialogContent sx={{ py: 3 }}>
               <Typography variant="body1" gutterBottom>
                  <FormattedMessage
                     id="ai-optimization-started-message"
                     defaultMessage="Your catalog optimization has started successfully! We'll notify you both in the app and via email once the optimization is complete."
                  />
               </Typography>
            </DialogContent>

            <DialogActions sx={{ px: 3, pb: 3 }}>
               <Button
                  variant="contained"
                  onClick={() => setIsSuccessDialogOpen(false)}
               >
                  <FormattedMessage id="got-it" defaultMessage="Got it" />
               </Button>
            </DialogActions>
         </Dialog>
      </Box>
   );
};



export default BatchAIOptimisation;
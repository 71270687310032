import { debounce } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import productsApi from '../../../../../../app/services/secured/products';
import {
  addProductVersion,
  getProductCustomData,
  getProductData,
  setCustomData,
  setProductOriginal,
} from '../../../../../../app/slices/product';
import { GetCurrentFeedId } from '../../../../../../app/utils';

export const useProductAi = () => {
  const productId = useAppSelector((state) => state.product.product?.id) ?? 0;
  const productData = useAppSelector(getProductData);
  const customData = useAppSelector(getProductCustomData);

  const dispatch = useAppDispatch();
  const feed = GetCurrentFeedId();

  const [texts, setTexts] = useState({
    title: "",
    description: "",
  });

  const [productAi, productAiRes] = productsApi.useProductAiMutation();

  // Main function to fetch API
  const generateAiText = () => {
    if (productId) {
      productAi({
        feed,
        feedProduct: productId,
      });
    }
  };

  // Initialize values with data from API
  useEffect(() => {
    if (productData) {
      setTexts({
        title: productData.title ?? "",
        description: productData.description ?? "",
      });

      dispatch(
        setProductOriginal({
          field: "title",
          original: productData.title ?? "",
        })
      );
      dispatch(
        setProductOriginal({
          field: "description",
          original: productData.description ?? "",
        })
      );
    }
  }, [productData]);

  // On each new generation, add to the slice
  useEffect(() => {
    if (productAiRes.isSuccess) {

      const { title, description } = productAiRes.data;

      dispatch(
        addProductVersion({
          field: "title",
          version: title,
        })
      );

      dispatch(
        addProductVersion({
          field: "description",
          version: description,
        })
      );

      setTexts({
        title: title,
        description: description,
      });

      dispatch(
        setCustomData({
          ...customData,
          title,
          description,
          title_ai: title,
          description_ai: description,
        }));
    }
  }, [productAiRes]);

  // Function for manual (typed) edit of either title or description
  const manualEdit = (value: string, type: "title" | "description") => {
    setTexts((t) => ({ ...t, [type]: value }));
    debounceCustomData(type, value);
  };

  const debounceCustomData = debounce(
    (type: "title" | "description", value: string) => {
      dispatch(setCustomData({ ...customData, [type]: value }));
    },
    0
  );

  return {
    texts,
    manualEdit,
    productAiRes,
    generateAiText,
  };
};
import feedApi from "../services/secured/feed";
import { GetCurrentFeedId } from "../utils";
import React, { useEffect } from "react";
import { Edit, LabelImportant } from "@mui/icons-material";
import { Badge, IconButton, List, ListItem, ListItemText, Tooltip } from "@mui/material";
import { DynamicLabel } from "../types/feed";
import { modalShowLabel } from "../slices/modal";
import { useAppDispatch } from "../hooks";

const useLabels = () => {

  const feed = GetCurrentFeedId();
  const dispatch = useAppDispatch();

  const [labelsQuery, labelsRes] = feedApi.useLazyGetDynamicLabelsQuery();
  const [aggregationsQuery, aggs] = feedApi.useLazyGetFeedLabelsQuery();

  const labels = labelsRes.data ?? [];
  const aggregations = aggs.data;

  useEffect(() => {
    if (feed > 0) {
      labelsQuery({ feed }, true);
      aggregationsQuery({ feed }, true);
    }
  }, [feed]);

  const findProductLabels = (identifier: string) => {
    const productLabels = (labels.map(d => {
      let hasLabel = false;
      d.conditions.forEach(c => {
        if (c.field === "id_k") {
          const val = c.value.split("\n").map(v => v.toUpperCase());
          if (val.includes(identifier.toUpperCase())) {
            return hasLabel = true;
          }
        } else if (c.field === "brand_k") {
          // TODO
        }
      });
      if (hasLabel) return d;
    }).filter(v => v) ?? []) as DynamicLabel[];

    return {
      productLabels: productLabels,
      component: (
        productLabels.length
          ? (
            <Tooltip arrow placement={"left"} title={<List disablePadding>
              {productLabels.map((l: DynamicLabel) => (
                <ListItem
                  key={`l-${l.uuid}`}
                  secondaryAction={
                    <IconButton
                      onClick={() => dispatch(modalShowLabel({ label: l }))}
                      edge={"end"}
                    >
                      <Edit fontSize={"small"} sx={{ color: "primary.contrastText" }} />
                    </IconButton>}
                >
                  <ListItemText>{l.label_value}</ListItemText>
                </ListItem>
              ))}
            </List>}>
              <Badge badgeContent={productLabels.length} color={"info"}>
                <LabelImportant color={"action"} />
              </Badge>
            </Tooltip>
          ) : <React.Fragment />
      ),
      callback: (l: DynamicLabel) => dispatch(modalShowLabel({ label: l }))
    }
  }

  return {
    labels,
    aggregations,
    findProductLabels,
    loading: labelsRes.isLoading || labelsRes.isFetching,
  };
}

export default useLabels;

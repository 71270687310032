import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';

interface ParamValues {
   email: string | null;
   domain: string | null;
   feed_url: string | null;
   country: string | null;
   language: string | null;
}

export const useUrlOrCookieParams = (): ParamValues => {
   const [searchParams] = useSearchParams();
   const [paramValues, setParamValues] = useState<ParamValues>({
      email: null,
      domain: null,
      feed_url: null,
      country: null,
      language: null,
   });

   console.log('f', paramValues.feed_url);

   useEffect(() => {
      const getParamValue = (key: keyof ParamValues) => {
         const urlValue = searchParams.get(key);
         const cookieValue = Cookies.get(key);

         // If URL param exists, save it to cookie
         if (urlValue) {
            Cookies.set(key, urlValue);
            return urlValue;
         }

         return cookieValue || null;
      };

      setParamValues({
         email: getParamValue('email'),
         domain: getParamValue('domain'),
         feed_url: getParamValue('feed_url'),
         country: getParamValue('country'),
         language: getParamValue('language'),
      });
   }, [searchParams]);

   return paramValues;
};